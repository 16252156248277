import { Greetings, SignOut } from 'aws-amplify-react';
import React from 'react';
import {
	Button,
	Collapse,
	DropdownMenu,
	DropdownToggle,
	Nav,
	Navbar,
	UncontrolledDropdown,
} from 'reactstrap';
import NavbarBrand from 'reactstrap/lib/NavbarBrand';
import logo from '../../../assets/icons/logo@3x.png';
import styles from './CustomGreetings.module.css';
import { UserContext } from '../../common/Context/UserContext';
import { ReactComponent as DownArrow } from '../../../assets/icons/down-arrow.svg';
import { ReactComponent as CloseBtn } from '../../../assets/icons/close.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu-icon.svg';
import QuickNavigateLocations from './QuickNavigateLocations';

const theme = {
	navItem: {
		display: 'inline-block',
		padding: '10px 5px',
		lineHeight: '20px',
	},
	navButton: {
		display: 'inline-block',
		padding: '6px 12px',
		marginTop: '8px',
		marginBottom: '8px',
		fontSize: '14px',
		fontWeight: 400,
		lineHeight: '1.42857143',
		textAlign: 'center',
		whiteSpace: 'nowrap',
		verticalAlign: 'middle',
		touchAction: 'manipulation',
		cursor: 'pointer',
		userSelect: 'none',
		backgroundImage: 'none',
		border: '1px solid transparent',
		borderRadius: '4px',
		color: '#333',
		backgroundColor: '#fff',
	},
};

class CustomGreetings extends Greetings<any, {}> {
	_validAuthStates = ['signedIn'];
	state = {
		isOpen: false,
	};

	onMouseEnter = () => {
		this.setState({ isOpen: true });
	};

	onMouseLeave = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const user = this.context.userDetails.attributes;
		const username = user.given_name + ' ' + user.family_name;
		return (
			<Navbar className={`${styles['custom-navbar']} w-100`} expand='sm'>
				<Button className={styles['custom-button']} onClick={this.props.toggleSideNav}>
					{this.props.isOpen ? <CloseBtn /> : <MenuIcon />}
				</Button>
				<NavbarBrand href='/' className={'mr-auto'}>
					<img data-cy='navbar-icon' src={logo} className={styles['logo']} alt='logo' />
				</NavbarBrand>
				<QuickNavigateLocations />

				<Collapse navbar>
					<Nav navbar className='ml-auto'>
						<UncontrolledDropdown nav inNavbar isOpen={this.state.isOpen}>
							<DropdownToggle
								nav
								className={styles['username']}
								onMouseOver={this.onMouseEnter}
								onMouseLeave={this.onMouseLeave}
							>
								{username.toUpperCase()}
								<span className={styles['down-arrow']}>
									<DownArrow className={'mb-1'} />
								</span>
							</DropdownToggle>
							<DropdownMenu
								data-cy='sign-out-button'
								onMouseOver={this.onMouseEnter}
								onMouseLeave={this.onMouseLeave}
								className={styles['custom-dropdown']}
								right
							>
								<SignOut theme={theme} />
							</DropdownMenu>
						</UncontrolledDropdown>
					</Nav>
				</Collapse>
			</Navbar>
		);
	}
}
CustomGreetings.contextType = UserContext;

export default CustomGreetings;
