import Amplify from 'aws-amplify';
import { Greetings, Authenticator } from 'aws-amplify-react/dist';
import React, { Component } from 'react';
import './App.css';
import environment from './environments';
import MainPage from './components/app/MainPage/MainPage.component';
import { BrowserRouter as Router } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { UserProvider } from './components/common/Context/UserContext';
import SideNav from './components/app/SideNav/SideNav.component';
import { Container, Row } from 'reactstrap';
import './App.css';
import CustomGreetings from './components/app/Navbar/CustomGreetings.comnponent';

Amplify.configure(environment);

interface State {
	isOpen: boolean;
	authState: string;
}

class App extends Component<any, State> {
	state: State = {
		isOpen: true,
		authState: '',
	};

	toggleSideNav = () => {
		this.setState(() => ({ isOpen: !this.state.isOpen }));
	};

	setAuthState = authState => {
		this.setState({ authState });
	};

	public render() {
		return (
			<Authenticator
				hide={[Greetings]}
				onStateChange={authState => this.setAuthState(authState)}
				authData={'username'}
				authState={'signIn'}
			>
				{this.state.authState === 'signedIn' && (
					<UserProvider>
						<Router>
							<Container fluid className='d-flex flex-column' style={{ minHeight: '100%' }}>
								<div className='default-padding'>
									{/*
									// @ts-ignore */}
									<CustomGreetings
										getAuthState={this.setAuthState}
										toggleSideNav={this.toggleSideNav}
										isOpen={this.state.isOpen}
									/>
								</div>
								<Row className='main-row flex-grow-1'>
									<div className='sidebar-layout'>
										<SideNav isOpen={this.state.isOpen} />
									</div>
									{/* <MainNav t={this.props.t} i18n={this.props.i18n} /> */}
									<div className='main-page-layout'>
										<MainPage />
									</div>
								</Row>
							</Container>
						</Router>
					</UserProvider>
				)}
			</Authenticator>
		);
	}
}

export default withTranslation('translations')(App);
// ));
