import React from 'react';
import styles from './ListViewWrapper.module.css';
import Skeleton from 'react-loading-skeleton';
import { Table } from 'reactstrap';

const TableLoadingSkeleton = () => {
	return (
		<Table data-cy='loading-table' responsive className={`${styles['custom-table']}`}>
			<thead>
				<tr>
					<th>
						<Skeleton />
					</th>
				</tr>
			</thead>
			<tbody>
				{Array(5)
					.fill(0)
					.map((v, i) => {
						return (
							<tr key={i}>
								<td>
									<Skeleton />
								</td>
							</tr>
						);
					})}
			</tbody>
		</Table>
	);
};

export default TableLoadingSkeleton;
