import React, { Component } from 'react';
import { Button, Form, FormGroup, Label, Alert } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { Link, Redirect } from 'react-router-dom';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import ReactTimeAgo from 'react-time-ago';
import 'react-datepicker/dist/react-datepicker.css';
import ReactSelect from 'react-select';

import {
	filterRelevantFirmware,
	checkIfAllowSelectionOfNodes,
	filterNodesBasedOnGatewaySelection,
} from './FirmwareUpdateSchedule.data';

import styles from './FirmwareUpdateSchedule.module.css';
import {
	FIRMWARE_DISPLAY_TYPES,
	OTA_LEAD_TIME_MS,
	FIRMWARE_UPDATE_SCOPE,
	SCOPE_DESCRIPTIONS,
	OTA_STAGGER_TIME_MS,
} from '../../../../../enums/otaConstants';

// initialise locale, ReactTimeAgo uses this
JavascriptTimeAgo.locale(en);

interface Props {
	onSubmit: (formInput: {
		firmware_id: string;
		gateway_list: string[];
		location_id: string;
		node_list: string[];
		scheduled_time: number;
		scope: FIRMWARE_UPDATE_SCOPE;
		type: string;
		version: string;
	}) => any;
	location: {
		app_id?: string;
		location_name?: string;
		location_id?: string;
	};
	setLocation: any;
	locations: any[];
	firmware: any[];
	gateways: {
		gatewaysAll: any[];
		gatewaysOnline: any[];
		gatewaysOffline: any[];
		gatewaysByMac: {};
	};
	nodesDataForLocation: {};
	nodesLoading: boolean;
	loading: boolean;
	gwLoading: boolean;
	error: boolean;
	errorMessages: string[];
	success: boolean;
	scheduling: boolean;
}

interface State {
	firmware_id: string;
	selectedGateways: any[]; // for holding current state of select, will process this in the submit handler
	selectedNodes: any[];
	location_id: string;
	scheduled_time: number;
	scope: FIRMWARE_UPDATE_SCOPE;
	type: string;
	validTime: boolean;
	version: string;
}

// TODO: refactor to a function-based component
class FirmwareUpdateScheduleForm extends Component<Props, State> {
	state = {
		firmware_id: '',
		selectedGateways: [
			{
				label: 'All Gateways',
				value: 'ALL',
			},
		],
		selectedNodes: [],
		location_id: '',
		scheduled_time: Date.now() + OTA_LEAD_TIME_MS,
		scope: FIRMWARE_UPDATE_SCOPE.LOCATION_SINGLE,
		type: '',
		validTime: true,
		version: '0',
	};

	handleSelectChange = (value, field: keyof State) => {
		this.setState({
			[field]: value,
		} as Pick<State, keyof State>);
	};

	handleLocationChange = value => {
		// transmit updated location to parent to fetch gateways
		this.props.setLocation(this.props.locations.find(location => location.location_id === value));
		this.setState({
			location_id: value,
			scope: FIRMWARE_UPDATE_SCOPE.LOCATION_SINGLE,
			selectedGateways: [
				{
					label: 'All Gateways',
					value: 'ALL',
				},
			], // clear out any gateway selection TODO: set to "all gateways"
		});
		this.clearSelectedNodes();
	};

	handleGatewaySelect = selection => {
		const { scope } = this.state;

		// handle scenarios
		switch (true) {
			// if nothing is selected, default it to all gateways
			case !selection:
				this.setState({
					selectedGateways: [
						{
							label: 'All Gateways',
							value: 'ALL',
						},
					],
					// set to single if one gateway, else multiple
					scope: FIRMWARE_UPDATE_SCOPE.LOCATION_SINGLE,
				});
				break;

			// Case where "all gateways" is the currently selected option, and user selects a gateway. We want to remove "all gateways" option from the selection, then push the selected options into state
			case scope === FIRMWARE_UPDATE_SCOPE.LOCATION_SINGLE:
				// remove "all gateways" option from selection
				const selectionWithoutAllGateways = selection.filter(option => option.value !== 'ALL');
				this.setState({
					selectedGateways: selectionWithoutAllGateways,
					// set to single if one gateway, else multiple
					scope: FIRMWARE_UPDATE_SCOPE.GATEWAY_SINGLE,
				});
				break;

			// Case where one or more gateways are currently selected, but user now selects "all gateways". We want to just set it to "all gateways", remove all other gateway selections
			case selection.some(option => option.value === 'ALL'):
				this.setState({
					selectedGateways: [
						{
							label: 'All Gateways',
							value: 'ALL',
						},
					],
					// set to single if one gateway, else multiple
					scope: FIRMWARE_UPDATE_SCOPE.LOCATION_SINGLE,
				});
				return;

			// Case where selected options are just gateways, we just push the whole selection into state
			default:
				this.setState({
					selectedGateways: selection,
					// set to single if one gateway, else multiple
					scope:
						selection.length === 1
							? FIRMWARE_UPDATE_SCOPE.GATEWAY_SINGLE
							: FIRMWARE_UPDATE_SCOPE.GATEWAY_MULTIPLE,
				});
		}
		this.clearSelectedNodes();
	};

	handleNodeSelect = selection => {
		if (!selection) {
			this.setState({
				selectedNodes: [],
				// set to single gateway if no nodes selected
				scope: FIRMWARE_UPDATE_SCOPE.GATEWAY_SINGLE,
			});
			return;
		}
		this.setState({
			selectedNodes: selection,
			scope:
				selection.length === 1
					? FIRMWARE_UPDATE_SCOPE.NODE_SINGLE
					: FIRMWARE_UPDATE_SCOPE.NODE_MULTIPLE,
		});
	};

	changeFirmwareFile = e => {
		let type = '';

		if (e.target.value) {
			const firmware = this.props.firmware.find(element => {
				return element.firmware_id === e.target.value;
			});
			type = firmware.type;
		}
		this.setState({
			firmware_id: e.target.value,
			type,
		});
	};

	changeScheduledTime = (e: Date) => {
		this.setState({
			scheduled_time: e.getTime(),
			validTime: e.getTime() - new Date().getTime() >= OTA_LEAD_TIME_MS,
		});
	};

	clearSelectedNodes = () => {
		this.setState({
			selectedNodes: [],
		});
	};

	// process data before submitting to parent
	submit = e => {
		e.preventDefault();

		const {
			firmware_id,
			location_id,
			scheduled_time,
			scope,
			selectedGateways,
			selectedNodes,
			type,
			version,
		} = this.state;

		const gateway_list = selectedGateways.map((option: any) => option.value);
		const node_list = selectedNodes.map((option: any) => option.value);

		this.props.onSubmit({
			firmware_id,
			gateway_list,
			location_id,
			node_list,
			scheduled_time,
			scope,
			type,
			version,
		});
	};

	//used in the date-time picker to set maximum time in the drop down menu
	setMaxHours(d: Date) {
		d.setHours(23, 59);
		return d;
	}

	render() {
		const {
			error,
			errorMessages,
			firmware,
			gateways,
			gwLoading,
			loading,
			locations,
			nodesDataForLocation,
			success,
			scheduling,
		} = this.props;
		const { type, selectedGateways } = this.state;
		const { gatewaysAll, gatewaysOffline } = gateways;

		// transformations for items
		const locationsOptions = locations.map(l => {
			// destructure this?
			return {
				label: l.location_name,
				value: l.location_id,
			};
		});

		const gatewayOptions = gatewaysAll.map(g => {
			// destructure this?
			return {
				label: g.name,
				value: g.gateway_id,
				online: g.online,
			};
		});

		// add "all gateways" as first option
		const gatewayOptionsWithAll = [
			{
				label: 'All Gateways',
				value: 'ALL',
			},
			...gatewayOptions,
		];

		// node options
		let nodeOptions = [];
		const allowSelectionOfNodes = checkIfAllowSelectionOfNodes(
			nodesDataForLocation,
			selectedGateways,
			type
		);
		if (allowSelectionOfNodes) {
			nodeOptions = filterNodesBasedOnGatewaySelection(
				nodesDataForLocation,
				selectedGateways,
				type
			);
		}

		const firmwareOptions = filterRelevantFirmware(firmware, type);

		let firmwareTypeLabel = '';
		if (type && FIRMWARE_DISPLAY_TYPES.length > 0) {
			const selectedTypeObject = FIRMWARE_DISPLAY_TYPES.find(
				type => type.value === this.state.type
			);
			if (selectedTypeObject && selectedTypeObject.label) {
				firmwareTypeLabel = selectedTypeObject.label;
			}
		}

		// variables for summary
		const scheduledTimeDateObject = new Date(this.state.scheduled_time);
		const numberOfGatewaysToUpdate =
			this.state.scope === FIRMWARE_UPDATE_SCOPE.LOCATION_SINGLE
				? this.props.gateways.gatewaysAll.length
				: this.state.selectedGateways.length;
		const estimatedCompletionTimeDateObject = new Date(
			this.state.scheduled_time + numberOfGatewaysToUpdate * OTA_STAGGER_TIME_MS
		);
		const firmwareTypeDisplayLabel = FIRMWARE_DISPLAY_TYPES.find(
			type => type.value === this.state.type
		)?.label;

		return (
			<div className={styles.formComponent}>
				{error && (
					<Alert color='danger'>
						<strong>An error occured</strong>
						<br />
						{errorMessages.map(error => error)}
					</Alert>
				)}
				{success && !error && (
					<Redirect
						to={{
							pathname: '/firmware-updates',
							state: {
								showSuccessToast: true,
								successToastMessage: `Scheduled firmware update for ${firmwareTypeDisplayLabel} in ${
									this.props.location.location_name
								} at ${new Date(this.state.scheduled_time).toLocaleString()}.`,
							},
						}}
					/>
				)}
				<Form>
					<FormGroup>
						<Label>Node Type</Label>
						<ReactSelect
							options={FIRMWARE_DISPLAY_TYPES}
							onChange={selection =>
								this.handleSelectChange(selection ? selection.value : '', 'type')
							}
							placeholder='Select node type to update'
						/>
					</FormGroup>
					<FormGroup>
						<Label>Location</Label>
						<ReactSelect
							options={locationsOptions}
							onChange={selection => this.handleLocationChange(selection.value)}
							placeholder='Select location'
							label='Location'
						/>
						{gatewaysAll.length > 0 && (
							<p className={styles.supplementaryText}>
								{gatewaysAll.length} gateways, {gatewaysOffline.length} offline
							</p>
						)}
					</FormGroup>
					<FormGroup>
						<Label>Gateway(s)</Label>
						<ReactSelect
							options={gatewayOptionsWithAll}
							defaultValue={gatewayOptionsWithAll[0]}
							onChange={selection => this.handleGatewaySelect(selection)}
							placeholder='Select gateway'
							isMulti
							isClearable={false}
							isDisabled={!this.state.location_id || gatewayOptionsWithAll.length === 1}
							isLoading={gwLoading}
							value={this.state.selectedGateways}
						/>
					</FormGroup>
					{this.state.location_id && gatewaysAll.length === 0 && !gwLoading && (
						<Alert color='danger'>
							This location has no gateways, you can continue to schedule a firmware update but
							nothing will happen.
						</Alert>
					)}
					{this.state.location_id && gatewaysOffline.length > 0 && !gwLoading && (
						<Alert color='danger'>
							The following {gatewaysOffline.length} gateways are offline and will not receive the
							firmware update message. Either bring them online before scheduling the firmware
							update, or schedule another firmware update subsequently.
							<ul>
								{gatewaysOffline.map((gateway, i) => (
									<li key={i}>{gateway.name}</li>
								))}
							</ul>
						</Alert>
					)}
					<FormGroup>
						<Label>Node(s) (Node type and one gateway must be selected)</Label>
						<ReactSelect
							options={nodeOptions}
							onChange={selection => this.handleNodeSelect(selection)}
							placeholder='Select a single gateway and node type first'
							isMulti
							isClearable={false}
							// todo: consider refactoring this condition into a simpler variable
							isDisabled={!allowSelectionOfNodes || nodeOptions.length === 0}
							isLoading={gwLoading}
							value={this.state.selectedNodes}
						/>
						{nodeOptions.length > 0 && (
							<p className={styles.supplementaryText}>
								{nodeOptions.length} {this.state.type} nodes found in location
							</p>
						)}
					</FormGroup>
					{/* todo: consider refactoring this condition into a simpler variable */}
					{allowSelectionOfNodes && nodeOptions.length > 0 && (
						<Alert color='danger'>
							At the moment we are not able to read the exact type of aircon node, so Daikins,
							2PFCs, ACIRs and BTUs will all say "type: aircon", the same as in the nodes list.
							<br />
							<br />
							In the future when we store the exact node type in the database we will be able to
							identify them.
						</Alert>
					)}
					{allowSelectionOfNodes && nodeOptions.length === 0 && (
						<Alert color='danger'>
							No {this.state.type} nodes connected to Gateway:{' '}
							<strong>{this.state.selectedGateways[0].label}</strong> in{' '}
							<strong>{this.props.location.location_name}</strong>.
						</Alert>
					)}
					<FormGroup>
						<label>
							{`${firmwareTypeLabel} ${this.state.type ? 'f' : 'F'}irmware file to use for update`}
						</label>
						<ReactSelect
							options={firmwareOptions}
							onChange={selection =>
								this.handleSelectChange(selection ? selection.value : '', 'firmware_id')
							}
							placeholder='Select firmware file'
							isDisabled={!this.state.type || firmwareOptions.length === 0}
							menuPlacement='top'
						/>
					</FormGroup>
					{this.state.location_id && this.state.type && firmwareOptions.length === 0 && (
						<Alert color='danger'>
							No {firmwareTypeLabel} firmware files found. Maybe{' '}
							<Link to={'/firmware-updates/firmware-files'}> upload firmware</Link> first?
						</Alert>
					)}
					<FormGroup className={styles.datePickerContainer}>
						<Label>Scheduled Date/Time</Label>
						<DatePicker
							className={'form-control'}
							id='scheduleTimeInput'
							Date={this.state.scheduled_time}
							timeFormat='HH:mm'
							dateFormat='MMMM d, yyyy HH:mm'
							onChange={this.changeScheduledTime}
							selected={new Date(this.state.scheduled_time)}
							timeIntervals={15}
							minDate={new Date()}
							showTimeSelect
						/>
						<p className={styles.supplementaryText}>
							<ReactTimeAgo date={this.state.scheduled_time} />
						</p>
						{!this.state.validTime && (
							<Alert color='danger'>
								<span>{`Date/Time selected must be at least ${OTA_LEAD_TIME_MS /
									1000 /
									60} minutes in advance from present time. `}</span>
							</Alert>
						)}
					</FormGroup>
					{this.state.type && this.state.location_id && selectedGateways.length > 0 && (
						<Alert color='info' className={styles.summary}>
							<span>
								Scheduling a firmware update for{' '}
								<strong>{SCOPE_DESCRIPTIONS[this.state.scope]}</strong> for node type{' '}
								<strong>{firmwareTypeDisplayLabel}</strong> in{' '}
								<strong>{this.props.location.location_name}</strong> in {numberOfGatewaysToUpdate}{' '}
								gateway(s) starting at <strong>{scheduledTimeDateObject.toLocaleString()}</strong>{' '}
								with firmware file{' '}
								<strong>
									{this.state.firmware_id &&
										this.props.firmware.find(
											firmware => firmware.firmware_id === this.state.firmware_id
										).name}
								</strong>
								<br />
								<br />
								Estimated approximate completion time is{' '}
								<strong>{estimatedCompletionTimeDateObject.toLocaleString()}</strong> (
								<ReactTimeAgo date={estimatedCompletionTimeDateObject} />)
							</span>
						</Alert>
					)}
					{/* {this.state.type && this.state.location_id && gatewaysOnline.length === 0 && (
						<Alert color='secondary' className={styles.summary}>
							<span className={styles.iconContainer}>
								<i className={styles.fakeIcon}>i</i>
							</span>
							<span>
								Updating {gatewaysOnline.length} gateways with a total of 130 ACIR nodes connected,
								approximate time is {gatewaysOnline.length} hours and estimated to complete at{' '}
								{new Date(this.state.scheduled_time).toLocaleDateString()}
							</span>
						</Alert>
					)} */}
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<Link to='/firmware-updates'>
							<Button color='link' style={{ border: '1px solid currentColor' }}>
								&lt; Back
							</Button>
						</Link>
						<Button
							color='secondary'
							onClick={this.submit}
							disabled={
								loading ||
								(!(
									this.state.location_id &&
									this.state.firmware_id &&
									this.state.scheduled_time
								) as boolean) ||
								!this.state.validTime
							}
						>
							{scheduling ? 'Scheduling...' : 'Schedule Firmware Update'}
						</Button>
					</div>
				</Form>
			</div>
		);
	}
}

export default FirmwareUpdateScheduleForm;
