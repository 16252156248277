import React, { useEffect, useState, useContext } from 'react';
import ListViewWrapper from '../../../common/ListViewWrapper/ListViewWrapper.component';
import CustomTd from '../../../common/ListViewWrapper/CustomTd/CustomTd.component';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { ReactComponent as SettingSymbol } from '../../../../assets/icons/dots-options-button.svg';
import styles from '../User/User.module.css';
import { Link } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { deleteACModel, listACModels } from './ACModelQueries';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal.component';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../common/Context/UserContext';
import PermissionUtils from '../../../../utils/permission';
import Utils from '../../../../utils';

interface ACModels {
	ac_model_id: number;
	ac_brand: string;
	ac_model: string;
	created_at: string;
	updated_at: string;
}

const ListACModel = () => {
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [ACModels, setACModels] = useState<ACModels[]>([]);
	const [modalState, setModalState] = useState<boolean>(false);
	const [ACModelId, setACModelId] = useState();
	const { t } = useTranslation();

	const tableHeader = [
		{ headerName: 'ID', key: 'ac_model_id' },
		{ headerName: 'BRAND NAME', key: 'ac_brand' },
		{ headerName: 'MODEL NAME', key: 'ac_model' },
		{ headerName: 'UPDATED AT', key: 'updated_at' },
		{ headerName: 'CREATED AT', key: 'created_at' },
	];

	const fetchData = async () => {
		setLoading(true);
		try {
			const result = await API.graphql(graphqlOperation(listACModels));
			// @ts-ignore
			setACModels(result.data.listACModels.ac_models);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			setError(true);
			console.error(e);
		}
	};

	const toggleModal = (id?: any) => {
		setModalState(!modalState);
		setACModelId(id);
	};

	const toggleRefresh = () => {
		return fetchData();
	};

	const confirmDeleteUser = async () => {
		try {
			await API.graphql(
				graphqlOperation(deleteACModel, {
					input: {
						ac_model_id: ACModelId,
					},
				})
			);
			const newACModel = ACModels.filter(ACModel => {
				return ACModel.ac_model_id !== ACModelId;
			});

			setACModels(newACModel);
			setLoading(false);
			setError(false);
			toggleModal();
		} catch (e) {
			setLoading(false);
			setError(true);
			toggleModal();
			console.error(e);
		}
	};

	const cancelDeleteUser = () => {
		toggleModal();
	};

	useEffect(() => {
		fetchData();
	}, []);

	const { cognitoGroups } = useContext(UserContext);
	const isAdminOrSupport = PermissionUtils.isAdminOrSupport(cognitoGroups);

	return (
		<>
			<ListViewWrapper
				pageName={'AC Models'}
				title={true}
				tableHeader={tableHeader}
				addButton={true}
				addButtonLink={'/ac-model/new'}
				listData={ACModels}
				error={error}
				loading={loading}
				refreshData={toggleRefresh}
				manageColumns={true}
				isAdminOrSupport={isAdminOrSupport}
				dataRow={(filteredData, columnIdxs) =>
					filteredData.map(acModel => {
						acModel.updated_at = Utils.getLocaleString(acModel.updated_at);
						acModel.created_at = Utils.getLocaleString(acModel.created_at);
						return (
							<tr data-cy='ac-model-id' key={acModel.ac_model_id}>
								{Object.entries(acModel).map(([k, v], idx) => {
									if (columnIdxs[idx]) {
										return (
											<CustomTd cypress={columnIdxs[idx]} key={k} index={k}>
												{acModel[columnIdxs[idx]]}
											</CustomTd>
										);
									}
									return null;
								})}
								<CustomTd index={5}>
									<UncontrolledDropdown>
										<DropdownToggle color=''>
											<SettingSymbol />
										</DropdownToggle>
										<DropdownMenu className={styles['dropdownmenu']}>
											<Link
												className={styles['dropdown-link']}
												to={{
													pathname: `/ac-model/${acModel.ac_model_id}`,
													state: {
														id: acModel.ac_model_id,
														brandName: acModel.ac_brand,
														modelName: acModel.ac_model,
														type: 'UPDATE',
													},
												}}
											>
												<DropdownItem className={styles['dropdownmenu-item']}>
													{t('EDIT')}
												</DropdownItem>
											</Link>
											<DropdownItem
												className={styles['dropdownmenu-item']}
												onClick={() => toggleModal(acModel.ac_model_id)}
											>
												{t('DELETE')}
											</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</CustomTd>
							</tr>
						);
					})
				}
			/>
			<ConfirmationModal
				modal={modalState}
				openModal={toggleModal}
				onConfirm={confirmDeleteUser}
				onCancel={cancelDeleteUser}
				title={t('DELETE AC MODEL')}
				message={t('This action cannot be undone. Are you sure you want to delete this AC model?')}
			/>
		</>
	);
};

export default ListACModel;
