import ReactAppEnvValues from './IReactAppEnv';

const prod: ReactAppEnvValues = {
	aws_project_region: 'ap-southeast-1',
	aws_cognito_identity_pool_id: 'ap-southeast-1:b9c51e5e-5d22-4ecb-8723-10dd8e041d48',
	aws_cognito_region: 'ap-southeast-1',
	aws_user_pools_id: 'ap-southeast-1_zSeyv1D9u',
	aws_user_pools_web_client_id: '4g5rd0qn8akbbtcv657iuse4vn',
	aws_appsync_graphqlEndpoint:
		'https://hviavua5mzgpfd4bqlhhsfthve.appsync-api.ap-southeast-1.amazonaws.com/graphql',
	aws_appsync_region: 'ap-southeast-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	APIaddress: 'https://production-api.sensorflow.org',
	APIstage: 'prod',
};

export default prod;
