export const listFirmwareUpdatesExpanded = `query ListFirmwareUpdatesExtended(
    $filter: TableFirmwareUpdatesFilterInput
    $limit: Int
    $nextToken: String
) {
    listFirmwareUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
            create_time
            created_by
            firmware_id
            gateway_id
            last_update_by
            location_id
            node_list
            ota_result_log
            schedule_id
            scheduled_time
            scope
            status
            type
            update_id
            version
        }
        nextToken
    }
    listLocations{
        items{
            location_id
            location_name
            app_id
        }
    }
    listFirmwares{
        items{
            firmware_id
            version
            type
            name
            status
        }
    }
}
`;

export const listLocationsAndFirmwares = `query listLocationsAndFirmwares {
    listLocations {
        items{
            location_id
            location_name
            app_id
        }
    }
    listFirmwares {
        items{
            firmware_id
            version
            type
            name
            status
        }
    }
}
`;

export const listGatewaysByAppIdQuery = `query listGatewaysByAppIdQuery(
	$filter: TableGatewaysFilterInput
  ) {
	listGateways(filter: $filter) {
	  items {
      gateway_id
      app_id
      last_seen
      deleted
      data
	  }
	}
  }`;

export const listNodesByLocationIdQuery = `query listNodesByLocationIdQuery($location_id: String!) {
    listNodesByLocation(location_id: $location_id) {
        nodes {
            nodeData {
                details
                update_time
            }
            room {
                room_id
                room_name
            }
            node_id
            type
        }
        count
    }
}
`;

export type CreateFirmwareUpdatesInputV2 = {
	firmware_id: String;
	gateway_id: String;
	location_id: String;
	node_list: string[];
	schedule_id: String;
	scheduled_time: number;
	scope: String;
	status: String;
	type: String;
	update_id: String;
	version: String;
};

export const createFirmwareUpdates = `mutation CreateFirmwareUpdatesV2($input: CreateFirmwareUpdatesInputV2!) {
  createFirmwareUpdatesV2(input: $input) {
    create_time
    firmware_id
    gateway_id
    location_id
    node_list
    schedule_id
    scheduled_time
    scope
    status
    type
    update_id
    version
  }
}`;

export type UpdateFirmwareUpdatesInputV2 = {
	ota_result_log?: String;
	status: String;
	update_id: String;
};

// note that all the fields are needed in the return, in order for the appsync subscription that the gateway uses to work properly
export const updateFirmwareUpdatesV2 = `mutation updateFirmwareUpdatesV2($input: UpdateFirmwareUpdatesInputV2!) {
    updateFirmwareUpdatesV2(input: $input) {
        create_time
        created_by
        firmware_id
        gateway_id
        last_update_by
        last_update_time
        location_id
        node_list
        ota_result_log
        schedule_id
        scheduled_time
        scope
        status
        type
        update_id
        version
    }
}`;

export const onCreateAndUpdate_FirmwareUpdatesV2 = `subscription OnCreateAndUpdate_FirmwareUpdatesV2{
    onCreateAndUpdate_FirmwareUpdatesV2{
        create_time
        created_by
        firmware_id
        gateway_id
        last_update_by
        location_id
        node_list
        ota_result_log
        schedule_id
        scheduled_time
        scope
        status
        type
        update_id
        version
    }
  }
  `;
