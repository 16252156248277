import React from 'react';
import { RouteComponentProps } from '../../../../interface/Route';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { getAutomationSetsByRoomId } from '../../../../graphql/queries';
import RoomDevice from './RoomDevice.component';
import LoadingIndicator from '../../../common/LoadingIndicator/LoadingIndicator.component';

interface State {
	roomId: string;
}

class Room extends React.Component<RouteComponentProps, State> {
	state: State = {
		roomId: this.props.match.params.room_id ? this.props.match.params.room_id : '',
	};

	render() {
		return (
			<Connect
				query={graphqlOperation(getAutomationSetsByRoomId, {
					room_id: this.state.roomId,
				})}
			>
				{({ data: { getAutomationSetsByRoomId }, loading, error }) => {
					if (error) return <h3>Error</h3>;
					if (loading) return <LoadingIndicator />;
					if (!getAutomationSetsByRoomId) {
						getAutomationSetsByRoomId = [];
					}

					return (
						<RoomDevice
							data={getAutomationSetsByRoomId}
							locationId={this.props.match.params.location_id}
						/>
					);
				}}
			</Connect>
		);
	}
}

export default Room;
