import React from 'react';
import { graphqlOperation } from 'aws-amplify';
import { Connect } from 'aws-amplify-react';
import { listFirmwares } from '../../../../../graphql/queries';
import LoadingIndicator from '../../../../common/LoadingIndicator/LoadingIndicator.component';
import { Container, Table, Button } from 'reactstrap';
import styles from './FirmwareDocuments.module.css';
import axios from 'axios';
import environment from '../../../../../environments';
import { Auth } from 'aws-amplify';
import { FILE_STATUS } from '../../../../../enums/otaConstants';

const onCreateUpdateDeleteFirmware = `subscription onCreateUpdateDeleteFirmware{
	onCreateUpdateDeleteFirmware{
	firmware_id,
	name,
	type,
	uploaded_by,
	version,
	status}
}`;

interface State {
	token: string;
}
class FirmwareDocuments extends React.Component<{}, State> {
	state = {
		token: '',
	};

	async componentDidMount() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			this.setState({ token: user.signInUserSession.idToken.jwtToken });
		} catch (error) {
			console.error('Error fetching token');
		}
	}

	deleteFirmware = e => {
		const firmware_id = JSON.parse(e.target.id).firmware_id;
		const filename = JSON.parse(e.target.id).filename;

		const reqparams = {
			method: 'POST',
			url: environment.APIaddress + `/firmware-documents/${environment.APIstage}/delete`,
			headers: {
				Authorization: this.state.token,
			},
			data: {
				firmware_id,
				filename,
			},
		};

		axios(reqparams)
			.then(data => {
				console.log(data);
				alert('Firmware successfully deleted');
			})
			.catch(err => {
				console.log(err);
				alert('Someone error deleting firmware');
			});
	};

	render() {
		return (
			<div className={styles.wrapper}>
				<Connect
					query={graphqlOperation(listFirmwares)}
					subscription={graphqlOperation(onCreateUpdateDeleteFirmware)}
					onSubscriptionMsg={(prev, { onCreateUpdateDeleteFirmware: newUpdate }) => {
						console.log('New FirmwareUpdate ', JSON.stringify(newUpdate, null, 2));
						const index = prev.listFirmwares.items.findIndex(
							update => update.firmware_id === newUpdate.firmware_id
						);
						if (index === -1) {
							console.log('index not found. A new item');
							prev.listFirmwares.items = [...prev.listFirmwares.items, newUpdate];
						} else {
							console.log('index found. update of existing item');

							prev.listFirmwares.items[index] = newUpdate;
						}
						return prev;
					}}
				>
					{({ data: { listFirmwares }, loading, errors }: { data: any; loading; errors }) => {
						if (errors.length) {
							console.error(errors);
							return <h3>Some Error Occured</h3>;
						}
						if (loading || !listFirmwares) {
							return <LoadingIndicator />;
						}

						const firmwareDocuments = listFirmwares.items;
						return (
							<Container data-cy='tableContainer'>
								<Table>
									<thead>
										<tr className={styles['table-head']}>
											<th>Firmware id</th>
											<th>File Name</th>
											<th>Version</th>
											<th>Type</th>
											<th>Create Time</th>
										</tr>
									</thead>
									{firmwareDocuments.map((file: any, i) => {
										if (
											file.status === FILE_STATUS.AVAILABLE ||
											file.status === FILE_STATUS.REPLACED
										) {
											return (
												<React.Fragment key={i}>
													<tbody>
														<tr>
															<td>{file.firmware_id.toString().substring(0, 5)}</td>
															<td>
																<a href={file.s3_link}>{file.name}</a>
															</td>
															<td>{file.version}</td>
															<td>{file.type}</td>
															<td>{new Date(file.create_time).toLocaleString()}</td>
															<td>
																{' '}
																<Button
																	id={JSON.stringify({
																		filename: file.name,
																		firmware_id: file.firmware_id,
																	})}
																	color='secondary'
																	onClick={this.deleteFirmware}
																>
																	DELETE
																</Button>{' '}
															</td>
														</tr>
													</tbody>
												</React.Fragment>
											);
										}
										return null;
									})}
								</Table>
							</Container>
						);
					}}
				</Connect>
			</div>
		);
	}
}

export default FirmwareDocuments;
