import React from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator.component';
import { getAuthUser } from '../../../graphql/queries';
import Utils from '../../../utils';
import { IUserDetails } from '../../../interface/User';

interface UserContextProps {
	userDetails: any;
	cognitoGroups: any[];
	getLocation: any;
}
export const UserContext = React.createContext<UserContextProps>({
	userDetails: null,
	cognitoGroups: [],
	getLocation: null,
});

interface State {
	userDetails: IUserDetails | null;
	cognitoGroups: string[];
	getLocation: string | null;
}

export class UserProvider extends React.Component<{}, State> {
	state = {
		userDetails: null,
		cognitoGroups: [],
		getLocation: null,
	};

	async componentDidMount() {
		const userState = await this.getLoggedInUserState();
		this.setState(userState);
	}

	private async getLoggedInUserState(): Promise<State> {
		/*
		Information for mocking:
		userDetails is a server call: Auth.currentUserInfo() calls Auth.userAttributes() which in turn calls CognitUser.getUserAttributes() which calls to server.
		currentSession is a local call: as long as there is a valid idToken and valid accessToken in local storage - Auth.currentSession() calls Auth.currentUserPoolUser() which inspects local storage first
		userRecord is a GraphQL call to retrieve users locations
		*/
		try {
			const userDetails = await Auth.currentUserInfo();
			const currentSession = await Auth.currentSession();

			const cognito_id = userDetails.attributes.sub;
			const userRecord: any = await API.graphql(graphqlOperation(getAuthUser, { cognito_id }));
			const getLocation =
				Utils.getSafe(() => userRecord['data']['getAuthUser']['locations']) || null;
			const cognitoGroups = currentSession['accessToken']['payload']['cognito:groups'];
			return { userDetails, cognitoGroups, getLocation };
		} catch (error) {
			console.error(error);
			throw new Error(`Error resolving user state`);
		}
	}

	render() {
		return this.state.userDetails ? (
			<UserContext.Provider value={{ ...this.state }}>{this.props.children}</UserContext.Provider>
		) : (
			<LoadingIndicator />
		);
	}
}
