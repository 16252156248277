import React from 'react';
import { Connect } from 'aws-amplify-react';
import CreateGateway from './CreateGateway.component';
import CreateGatewayDisplay from './CreateGatewayDisplay.component';
import { graphqlOperation } from 'aws-amplify';
import { createAuthRawGateways } from '../../../../graphql/mutations';
import Utils from '../../../../utils';
import { CreateRawGatewaysInput } from '../../../../API';
import CreateGatewayLoadingScreen from './CreateGatewayLoadingScreen.component';
import CreateGatewayComplete from './CreateGatewayComplete.component';
import ErrorIndicator from '../../../common/ErrorIndicator/ErrorIndicator.component';

interface State {
	clicked: boolean;
	device_uuid: string;
	gateway_data: any;
}

class GatewayParent extends React.Component<any, State> {
	state = {
		clicked: false,
		device_uuid: '',
		gateway_data: {} as any,
	};
	baseState;

	constructor(props) {
		super(props);
		this.baseState = this.state;
	}

	switchOffLoadIndicator = () => {
		this.setState({ clicked: false });
	};

	submit = mutation => {
		return async (location: { location_id: string; description: string }) => {
			const device_uuid = Utils.generateString(62);
			this.setState({ device_uuid });

			if (location.description && location.description.search(/\s/g) > -1) {
				alert('Invalid gateway name');
				console.error('Invalid gateway name');
				return;
			}

			const input: CreateRawGatewaysInput = {
				device_uuid,
				location_id: location.location_id,
				status: 'PENDING',
				description: location.description,
			};
			try {
				// TODO: remove clicked flag  check if used
				this.setState({ clicked: true, gateway_data: { status: 'IN_PROGRESS' } });
				const response = await mutation({ input });
				console.log({ response });
			} catch (error) {
				console.error(`Error`, error);
				this.setState({ gateway_data: { status: '' } });
			}
		};
	};

	getRawGatewayData = (data: any) => {
		this.setState({
			gateway_data: data,
		});
	};
	resetForm = () => {
		this.setState(this.baseState);
	};

	selector(status: string) {
		switch (status) {
			case 'IN_PROGRESS':
				return <CreateGatewayLoadingScreen />;
			case 'COMPLETE':
				return (
					<CreateGatewayComplete
						history={this.props.history}
						gatewayData={this.state.gateway_data}
						onResetForm={this.resetForm}
					/>
				);
			case 'ERROR':
				// return <CreateGatewayError />
				return <ErrorIndicator errorMsg={`An error occured: ${this.state.gateway_data.error}`} />;

			default:
				return (
					<Connect mutation={graphqlOperation(createAuthRawGateways)}>
						{({ mutation }) => {
							return (
								<CreateGateway
									onSubmit={this.submit(mutation)}
									clicked={this.state.clicked}
									history={this.props.history}
								/>
							);
						}}
					</Connect>
				);
		}
	}

	render() {
		return (
			<div>
				{this.selector(this.state.gateway_data['status'])}
				<CreateGatewayDisplay
					clicked={this.state.clicked}
					switchOffLoadIndicator={this.switchOffLoadIndicator}
					device_uuid={this.state.device_uuid}
					onStatusChange={this.getRawGatewayData}
				/>
			</div>
		);
	}
}

export default GatewayParent;
