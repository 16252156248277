import React from 'react';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { createAudit } from '../../../../graphql/mutations';
import { CreateAuditInput } from '../../../../API';
import { UserContext } from '../../../common/Context/UserContext';
import { AutomationSet } from '../../../../interface/AutomationSet';
import { Settings } from '../../../../interface/Settings';

export interface UpdateAirconDesiredState {
	stream_type: string;
	temperature_set: number;
	mode: number; // 0 to 3
	fan_speed: number;
	powered: boolean;
	stream_id: string;
	type: string;
	update_source: string;
}

export interface Location {
	location_id: string;
	app_id?: string;
	create_time?: number;
	created_by?: string;
	customer_name?: string;
	last_updated_by?: string;
	last_update_ip_address?: string;
	last_update_time?: number;
	location_name?: string;
	model?: string;
	settings?: Settings;
}

class TestingUpdates extends React.Component {
	updateAutosetSettings = mutation => {
		const cognitoId = this.context.userDetails.attributes.sub;

		return async () => {
			// mock lots of stuff
			const action = 'UPDATE';
			const locationId = 'de0c3c69-8f87-4a31-a1e1-75841a297613';
			const autosetId = 'ef123ghk-8f87-4a31-a1e1-75841a297613';
			const roomId = 'mockRoomId';
			const timestamp = Date.now();
			const sourceIpAddress = 'Some source mock';

			const record: AutomationSet = {
				automation_set_id: autosetId,
				last_update_ip_address: sourceIpAddress,
				last_update_time: timestamp,
				last_updated_by: cognitoId,
				room_id: roomId,
				settings: {
					automation_mode: 'SMARTSAVE',
					max_temp: 99,
					min_temp: 9,
					unoccupied_temp: 999,
				},
				automation_stream: [],
				aircon_stream: [],
			};
			// call the actual method
			const input: CreateAuditInput = {
				action: action,
				cognito_id: cognitoId,
				source_ip: sourceIpAddress,
				id: `${autosetId}_AutomationSetsSettings`,
				location_id: locationId,
				record: JSON.stringify(record),
				timestamp: timestamp,
			};

			try {
				const response = await mutation({ input });
				console.log('Response ');
				console.log(response);
			} catch (error) {
				console.error(`Error`, error);
			}
		};
	};

	updateLocationSettings = mutation => {
		const cognitoId = this.context.userDetails.attributes.sub;

		return async () => {
			// mock lots of stuff
			const action = 'UPDATE';
			const locationId = 'de0c3c69-8f87-4a31-a1e1-75841a297613';
			const timestamp = Date.now();
			const sourceIpAddress = 'Some source mock';

			const record: Location = {
				last_updated_by: cognitoId,
				last_update_ip_address: sourceIpAddress,
				last_update_time: timestamp,
				location_id: locationId,
				settings: {
					ac_night_end: '09:00',
					ac_night_start: '21:00',
					automation_mode: 'SUPERSAVE',
					door_sensor_timeout_mins: 5,
					emon_granular_interval_secs: 60,
					emon_send_fine_grained: true,
					emon_totals_interval_mins: 15,
					max_temp: 29,
					min_temp: 19,
					occ_night_end: '09:00',
					occ_night_start: '21:00',
					occ_timeout_day_mins: 25,
					occ_timeout_night_mins: 60,
					unoccupied_temp: 26,
				},
			};
			// call the actual method
			const input: CreateAuditInput = {
				action: action,
				cognito_id: cognitoId,
				source_ip: sourceIpAddress,
				id: `${locationId}_LocationsSettings`,
				location_id: locationId,
				record: JSON.stringify(record),
				timestamp: timestamp,
			};

			try {
				const response = await mutation({ input });
				console.log('Response ');
				console.log(response);
			} catch (error) {
				console.error(`Error`, error);
			}
		};
	};

	updateAircon = mutation => {
		const cognitoId = this.context.userDetails.attributes.sub;

		return async () => {
			// mock lots of stuff
			const nodeId = 'AAAA12345678901A';
			const locationId = 'de0c3c69-8f87-4a31-a1e1-75841a297613';
			const action = 'UPDATE';
			const timestamp = Date.now();
			const sourceIpAddress = 'Some source mock';

			const record: UpdateAirconDesiredState = {
				stream_type: 'ACController',
				temperature_set: 19,
				mode: 1,
				fan_speed: 0,
				powered: true,
				stream_id: `stream-${nodeId}-0`,
				type: 'aircon',
				update_source: 'dashboard',
			};
			// call the actual method
			const input: CreateAuditInput = {
				action: action,
				cognito_id: cognitoId,
				source_ip: sourceIpAddress,
				id: `${nodeId}_AcControls`,
				location_id: locationId,
				record: JSON.stringify(record),
				timestamp: timestamp,
			};

			try {
				const response = await mutation({ input });
				console.log('Response ');
				console.log(response);
			} catch (error) {
				console.error(`Error`, error);
			}
		};
	};

	render() {
		return (
			<Connect mutation={graphqlOperation(createAudit)}>
				{({ mutation }) => {
					return (
						<div>
							<h2>Testing Page for Sending Updates to G/W 2.0 </h2>
							<button className='btn btn-lg btn-success' onClick={this.updateAircon(mutation)}>
								Click me to update hardcoded A/C
							</button>
							<br />
							<br />
							<button
								className='btn btn-lg btn-info'
								onClick={this.updateLocationSettings(mutation)}
							>
								{' '}
								Click me to update hardcoded location settings{' '}
							</button>
							<br />
							<br />
							<button
								className='btn btn-lg btn-warning'
								onClick={this.updateAutosetSettings(mutation)}
							>
								{' '}
								Click me to update hardcoded autoset settings{' '}
							</button>
						</div>
					);
				}}
			</Connect>
		);
	}
}

export default TestingUpdates;
TestingUpdates.contextType = UserContext;
