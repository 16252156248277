import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
const translationEN = require('./locales/en/translation.json');
const translationDE = require('./locales/de/translation.json');
const translationMS = require('./locales/ms/translation.json');

i18n.use(LanguageDetector).init({
	// we init with resources
	resources: {
		en: {
			translations: translationEN,
		},
		de: {
			translations: translationDE,
		},
		ms: {
			translations: translationMS,
		},
	},
	fallbackLng: 'en',
	debug: false,

	// have a common namespace used around the full app
	ns: ['translations'],
	defaultNS: 'translations',

	keySeparator: false, // we use content as keys

	interpolation: {
		escapeValue: false, // not needed for react!!
		formatSeparator: ',',
		format: (value, format, lng) => {
			if (format === 'uppercase') return value.toUpperCase();
			return value;
		},
	},

	react: {
		wait: true,
	},
});

export default i18n;
