import React from 'react';
import { Container, Row, Input, Col, Form, Button, Alert } from 'reactstrap';
import { API, graphqlOperation } from 'aws-amplify';
import { createLocations, updateLocations } from '../../../../graphql/mutations';
import styles from './Location.module.css';
import Utils from '../../../../utils';
import { Link } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from '../../../../interface/Route';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal.component';
import { ModalActions } from '../../../../enums/confirmationModal';

const manageLocationPageQuery = `query manageLocationPage($location_id: String!) {
  getLocations(location_id: $location_id) {
    app_id
    create_time
    created_by
    customer_name
    last_update_by
    location_id
    location_name
    model
    ac_model_id
    settings
		bridge_adaptor_setting
		time_zone
	}
}
`;

const acModelsQuery = `query listACModels {
	listACModels{
	 	count
			ac_models {
			ac_model_id,
			ac_brand,
			ac_model,
			ac_details
			updated_at
			created_at
			}
	}
}`;

enum Timezone {
	'Asia/Singapore' = 'Asia/Singapore',
	'Asia/Bangkok' = 'Asia/Bangkok',
	'Asia/Hong_Kong' = 'Asia/Hong_Kong',
}

enum BridgeAdaptorSetting {
	'Unmodulated Valve' = 'Unmodulated Valve',
	'Modulated Valve, 0-10V output' = 'Modulated Valve, 0-10V output',
	'Modulated Valve, 2-10V output' = 'Modulated Valve, 2-10V output',
}

interface State {
	locationId?: string;
	locationName: string;
	companyName: string;
	acModel: string;
	acModelID: number;
	timeZone: Timezone;
	description?: string;
	alertVisible?: boolean;
	success?: boolean;
	bridgeAdaptorSetting: BridgeAdaptorSetting;
	ACModelsList?: any[];
	modalStatus: boolean;
	isDirty: boolean;
	modalActions: number | null;
}

interface ILocation {
	location_id?: string;
	customer_name: string;
	location_name: string;
	model: string;
	ac_model_id: number;
	description?: string;
	time_zone: Timezone;
	bridge_adaptor_setting: BridgeAdaptorSetting;
}

interface Props extends RouteComponentProps, WithTranslation {}

class ManageLocation extends React.Component<Props, State> {
	state: State = {
		locationId:
			this.props.match.params.location_id === 'new' ? '' : this.props.match.params.location_id,
		locationName: '',
		companyName: '',
		acModel: '',
		acModelID: -1,
		description: '',
		timeZone: Timezone['Asia/Singapore'], // since we want to default it to null
		alertVisible: false,
		success: false,
		bridgeAdaptorSetting: ('' as unknown) as BridgeAdaptorSetting,
		ACModelsList: [],
		modalStatus: false,
		isDirty: false,
		modalActions: null,
	};

	async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<State>) {
		if (this.props.match.params.location_id !== prevProps.match.params.location_id) {
			this.setState({
				locationId:
					this.props.match.params.location_id === 'new' ? '' : this.props.match.params.location_id,
				locationName: '',
				companyName: '',
				acModel: '',
				description: '',
				acModelID: -1,
				timeZone: Timezone['Asia/Singapore'], // since we want to default it to null
				alertVisible: false,
				success: false,
				ACModelsList: [],
			});
		}

		const checkField =
			this.state.locationName !== prevState.locationName ||
			this.state.companyName !== prevState.locationName ||
			this.state.acModel !== prevState.acModel ||
			this.state.acModelID !== prevState.acModelID ||
			this.state.timeZone !== prevState.timeZone ||
			this.state.description !== prevState.description;

		const isEmptyField =
			!this.state.locationName.length &&
			!this.state.companyName.length &&
			!this.state.acModel.length &&
			!this.state.description &&
			this.state.timeZone === Timezone['Asia/Singapore'] &&
			this.state.acModelID === -1;
		if (checkField && !this.state.isDirty && !isEmptyField) {
			this.setState({ isDirty: true });
		}

		if (checkField && isEmptyField) {
			this.setState({ isDirty: false });
		}
	}

	async componentDidMount() {
		const result = await API.graphql(graphqlOperation(acModelsQuery));
		const ACModelsList = result['data']['listACModels']['ac_models'];
		this.setState({ ACModelsList });

		if (this.state.locationId) {
			// Update location
			const query = await API.graphql(
				graphqlOperation(manageLocationPageQuery, { location_id: this.state.locationId })
			);
			const ddbLocation = query['data']['getLocations']; // TODO: better way to read this
			const locationObject = deserializeDdbLocation(ddbLocation);
			this.setState({
				...locationObject,
			});
		}
	}

	backToIndex = () => {
		if (this.state.isDirty) {
			this.toggleModal(ModalActions.Cancel);
		} else {
			this.props.history.push('/locations');
		}
	};

	onSubmit = async event => {
		event.preventDefault();
		this.setState({
			alertVisible: false,
		});
		this.toggleModal(ModalActions.Submit); // write operation is triggered by the confirmClick action on the modal
	};

	navigateToCreateGateway = e => {
		e.preventDefault();
		this.props.history.push('/create-gateway');
	};

	onDismiss = () => {
		this.setState({
			alertVisible: false,
		});
	};

	onStringInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const property = event.target.id;
		const stringValue = event.target.value as State[keyof State]; // assert that the string input value conforms to a permitted property value on State

		this.setState({
			[property]: stringValue,
			alertVisible: false,
		} as Pick<State, keyof State>);
	};

	onACModelChange = e => {
		this.setState({
			acModelID: parseInt(e.target.value),
			acModel: e.target[e.target.selectedIndex].dataset.model,
		});
	};

	toggleModal = (action: ModalActions) => {
		this.setState(prev => ({
			modalActions: action,
			modalStatus: !prev.modalStatus,
		}));
	};

	confirmClick = async () => {
		if (this.state.modalActions === ModalActions.Submit) {
			const input = serializeLocationInState(this.state);
			try {
				const operation = this.state.locationId ? updateLocations : createLocations;
				await API.graphql(graphqlOperation(operation, { input }));
				this.setState({ success: true });
			} catch (error) {
				console.error(`Error creating location: ${JSON.stringify(error, null, 2)}`);
				this.setState({ success: false });
			} finally {
				this.setState(prev => ({
					alertVisible: true,
					modalStatus: !prev.modalStatus,
				}));
			}
		}

		if (this.state.modalActions === ModalActions.Cancel) {
			this.props.history.push('/locations');
		}
	};

	cancelClick = () => {
		this.setState(prev => ({
			modalStatus: !prev.modalStatus,
		}));
	};

	alertMessage = formAction => {
		return this.state.success ? (
			<>
				{this.state.locationName} has {Utils.pastTense(formAction)} successfully.{' '}
				{this.state.locationId && this.state.locationId.length ? (
					<Link to={'/locations'} className='alert-link'>
						{this.props.t('View All Locations')}
					</Link>
				) : (
					<Link to='/create-gateway' className='alert-link'>
						{this.props.t('Add a Gateway')}
					</Link>
				)}
				.
			</>
		) : (
			<>
				{this.props.t(
					'A technical error has occurred. Please refresh and try again or report it to'
				)}
				<a href='mailto:support@sensorflow.com'>support@sensorflow.org.</a>
			</>
		);
	};

	render() {
		const formAction = this.state.locationId ? this.props.t('update') : this.props.t('create');
		const isFormValid =
			this.state.companyName &&
			this.state.locationName &&
			this.state.acModel &&
			this.state.timeZone &&
			this.state.timeZone in Timezone;
		// simple validation now

		return (
			<>
				<Alert
					className={this.state.success ? 'success-popup popup-text' : 'failure-popup popup-text'}
					isOpen={this.state.alertVisible}
					toggle={this.onDismiss}
				>
					{this.alertMessage(formAction)}
				</Alert>

				<Container>
					<h1 data-cy='create-form-title' className='create-form-title'>{`${Utils.capitalize(
						formAction
					)} ${this.props.t('location')}`}</h1>

					<Row>
						<Col xs='1' sm='1' md='1' lg='3' />
						<Col xs='10' sm='10' md='10' lg='6'>
							<Form data-cy='location-form' onSubmit={this.onSubmit}>
								<Row className='create-form-card'>
									<ul className='list-group'>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='locationName'>
													{this.props.t('LOCATION NAME')}
												</label>
												<br />
												<Input
													type='text'
													className='custom-input'
													name='locationName'
													id='locationName'
													data-cy='location-name'
													placeholder='E.g. Citadines Singapore'
													value={this.state.locationName}
													onChange={this.onStringInputChange}
												/>
											</div>
										</li>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='companyName'>
													{this.props.t('ORGANISATION NAME')}
												</label>
												<br />
												<Input
													type='text'
													name='companyName'
													id='companyName'
													data-cy='company-name'
													className='custom-input'
													placeholder='E.g. The Ascott Limited'
													value={this.state.companyName}
													onChange={this.onStringInputChange}
												/>
											</div>
										</li>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='acModel'>
													{this.props.t('BASE AIRCON MODEL')}
												</label>
												<br />
												{this.state.ACModelsList &&
												(Number.isInteger(this.state.acModelID) ||
													this.state.locationId === 'new') ? (
													<select
														className='form-control custom-input'
														required
														id='acModel'
														value={this.state.acModelID}
														onChange={this.onACModelChange}
														data-cy='ac-model'
													>
														<option value='-1' disabled>
															{this.props.t('Select one')}
														</option>
														{this.state.ACModelsList.map(acModel => (
															<option
																key={acModel.ac_model_id}
																value={acModel.ac_model_id}
																data-model={acModel.ac_model}
															>
																{`${acModel.ac_model_id}: ${acModel.ac_model} ${acModel.ac_brand}`}
															</option>
														))}
													</select>
												) : (
													<Input
														className={'form-control'}
														value={this.state.acModel}
														disabled={true}
													/>
												)}
											</div>
										</li>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='bridgeAdaptorSetting'>
													BRIDGE ADAPTOR SETTING
												</label>
												<br />
												<select
													className={`form-control ${styles['select-dropdown']} custom-input`}
													id='bridgeAdaptorSetting'
													value={this.state.bridgeAdaptorSetting}
													onChange={this.onStringInputChange}
													data-cy='location-bridgeAdaptorSetting'
												>
													<option value='' disabled>
														Optional
													</option>
													{Object.keys(BridgeAdaptorSetting)
														.sort()
														.map(setting => (
															<option key={setting} value={setting}>
																{setting}
															</option>
														))}
												</select>
											</div>
										</li>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='timeZone'>
													{this.props.t('TIME ZONE')}
												</label>
												<br />
												<select
													className={`form-control ${styles['select-dropdown']} custom-input`}
													id='timeZone'
													value={this.state.timeZone}
													onChange={this.onStringInputChange}
													data-cy='timezone'
												>
													{Object.values(Timezone)
														.sort()
														.map((timezone: Timezone) => (
															<option key={timezone} value={timezone}>
																{timezone.replace('_', ' ')}
																{/* format display, remove _ which we see in composite names e.g. Hong_Kong */}
															</option>
														))}
												</select>
											</div>
										</li>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='description'>
													{this.props.t('OTHER DESCRIPTION')}
												</label>
												<br />
												<Input
													name='description'
													data-cy='location-description'
													className='custom-input'
													id='description'
													placeholder='Optional'
													value={this.state.description}
													onChange={this.onStringInputChange}
												/>
											</div>
										</li>
									</ul>
								</Row>

								<br />
								<br />
								<Row>
									<Button
										type='submit'
										className='btn-secondary create-form-button'
										disabled={!isFormValid}
									>
										{this.props.t('SUBMIT')}
									</Button>
								</Row>
								<Row>
									<div className='create-form-cancel' onClick={this.backToIndex}>
										{this.props.t('CANCEL')}
									</div>
								</Row>
							</Form>
						</Col>
						<Col xs='1' sm='1' md='1' lg='3' />
					</Row>
				</Container>
				<ConfirmationModal
					openModal={this.toggleModal}
					title={this.props.t(
						this.state.modalActions === ModalActions.Submit
							? this.state.locationId && this.state.locationId.length
								? 'UPDATE LOCATION'
								: 'CREATE NEW LOCATION'
							: 'CONFIRM CANCELLATION'
					)}
					message={this.props.t(
						this.state.modalActions === ModalActions.Submit
							? 'Are you sure you want to submit?'
							: 'All unsaved changes would be lost. Are you sure you want to cancel?'
					)}
					modal={this.state.modalStatus}
					onConfirm={this.confirmClick}
					onCancel={this.cancelClick}
				/>
			</>
		);
	}
}

export default withTranslation()(ManageLocation);

// helpers

const deserializeDdbLocation = (location: ILocation) => ({
	locationId: location.location_id, // location from DDB always comes with id
	locationName: location.location_name,
	companyName: location.customer_name,
	acModel: location.model,
	acModelID: location.ac_model_id,
	modalStatus: false,
	isDirty: false,
	modalActions: null,
	// fields below have been optional, so DDB may not have them, leave default if not found
	timeZone: location.time_zone || Timezone['Asia/Singapore'],
	description: location.description || '',
	bridgeAdaptorSetting:
		location.bridge_adaptor_setting || (('' as unknown) as BridgeAdaptorSetting),
});

const serializeLocationInState = (locationInState: State): ILocation => ({
	customer_name: locationInState.companyName.trim(),
	location_name: locationInState.locationName.trim(),
	model: locationInState.acModel.trim(),
	time_zone: locationInState.timeZone,
	ac_model_id: locationInState.acModelID,

	// fields below may not present in State, don't serialize if not found
	...(locationInState.locationId && { location_id: locationInState.locationId }),
	...(locationInState.description && { description: locationInState.description }),
	...(locationInState.bridgeAdaptorSetting && {
		bridge_adaptor_setting: locationInState.bridgeAdaptorSetting,
	}),
});
