const colorCode = {
	green: '#51b38c',
	orange: '#DABF36',
	red: '#cf7272',
	black: '#555555',
};

export default class CellUtils {
	static getBatteryCellColor(level: number | string): string {
		let color = 'green';

		if (level < 3.1) {
			color = 'red';
		} else if (level < 3.5) {
			color = 'orange';
		} else if (level === 'not found') {
			color = 'black';
		}

		return colorCode[color];
	}

	static getWifiSignalStrength(value: number | string ): string {
		let color = 'green';
		if (value <= -60) {
			color = 'red';
		} else if (value <= -50 ) {
			color = 'orange';
		} else if (value === 'N/A') {
			color = 'black';
		}
		return colorCode[color]
	}

	//SNR: green: > 0,   0 >= yellow: > = -3, -3 > red
	//RSSI: green: > -95,   -95 >= yellow: >= -105, -105> red

	static getSnrCellColor(value: number | string): string {
		let color = 'green';

		if (value < -3) {
			color = 'red';
		} else if (value <= 0) {
			color = 'orange';
		} else if (value === 'not found') {
			color = 'black';
		}

		return colorCode[color];
	}
	static getRssiCellColor(value: number | string): string {
		let color = 'green';

		if (value < -105) {
			color = 'red';
		} else if (value <= -95) {
			color = 'orange';
		} else if (value === 'not found') {
			color = 'black';
		}

		return colorCode[color];
	}
}
