import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import Utils from '../../../utils';

interface Props {
	data: any[];
	headers: object;
	fileName?: string;
	isAdminOrSupport?: boolean;
}

const ExportCSVButton: React.FunctionComponent<Props> = React.memo(props => {
	const { data, isAdminOrSupport } = props;

	const [headers, setNewData] = useState(props.headers);
	useEffect(() => {
		setNewData(props.headers);
	}, [props.headers]);

	const flattenAndCheckForNull = (obj, prefix = '', res = {}) => {
		if (obj === null || obj === undefined) {
			return null;
		}

		return Object.entries(obj).reduce((r, [key, val]) => {
			const k = `${prefix}${key}`;
			if (typeof val === 'object') {
				flattenAndCheckForNull(val, `${k}.`, r);
			} else {
				res[k] = val;
			}
			return r;
		}, res);
	};

	const timestampToDateTime = items => {
		return items.map(item => {
			if (item['nodeData.update_time']) {
				item['nodeData.update_time'] = Utils.getLocaleString(item['nodeData.update_time']);
			} else if (item['last_seen']) {
				item['last_seen'] = Utils.getLocaleString(item['last_seen']);
			}
			return item;
		});
	};

	const checkForNodeStatus = items => {
		return items.map(item => {
			if (
				item['node_status.0'] != null &&
				item['node_status.1'] != null &&
				item['node_status.2'] != null
			) {
				item['node_status'] =
					item['node_status.0'].toString() +
					'/' +
					item['node_status.1'].toString() +
					'/' +
					item['node_status.2'].toString();
			}
			return item;
		});
	};

	let arr = data.map((d, i) => {
		return flattenAndCheckForNull(d);
	});

	// work around to change timestamp for selected fields to dates
	arr = timestampToDateTime(arr);
	// work around for node_status not appearing on the csv file for ListLocation.component.ts
	arr = checkForNodeStatus(arr);
	const fileName = props.fileName == null ? 'data.csv' : props.fileName;

	// CSV Link loads data and headers on render and does not update dynamically when headers changes.
	//This line forces CSVLink to rerender everytime headers changes.
	if (headers !== props.headers || !isAdminOrSupport || data.length === 0) {
		return null;
	}

	return (
		<CSVLink data={arr} headers={headers} target='_blank' filename={fileName}>
			<button style={{ minHeight: '35px', padding: '4px 8px' }}>Export CSV</button>
		</CSVLink>
	);
});

export default ExportCSVButton;
