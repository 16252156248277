import React from 'react';
import SearchBar from './SearchBar/SearchBar.component';
import { Button, Table } from 'reactstrap';
import ManageColumnsToggle from './ManageCoulmnsToggle/ManageColumnsToggle.component';
import { Link } from 'react-router-dom';
import { ReactComponent as DownArrow } from '../../../assets/icons/down-arrow.svg';
import { ReactComponent as SortIcon } from '../../../assets/icons/filter-icon.svg';
import { ReactComponent as DownSortIcon } from '../../../assets/icons/down.svg';
import { ReactComponent as AddIcon } from '../../../assets/icons/add-button.svg';
import ReactPaginate from 'react-paginate';
import styles from './ListViewWrapper.module.css';
import Utils from '../../../utils';
import TableSettings from '../../../enums/table-settings';
import RefreshButton from './RefreshButton/RefreshButton.component';
import ErrorIndicator from '../ErrorIndicator/ErrorIndicator.component';
import Fuse from 'fuse.js';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import SearchActions from '../../../enums/search-actions';
import TableLoadingSkeleton from './TableLoadingSkeleton.component';
import { WithTranslation, withTranslation } from 'react-i18next';
import ExportCSVButton from '../ExportCSV/ExportCSV.component';

interface Props extends WithTranslation {
	addButton?: boolean;
	addButtonLink?: string;
	addButtonText?: string;
	children?: any;
	customButton?: React.ReactNode;
	dataRow: any;
	isAdminOrSupport?: boolean;
	error: boolean;
	listData: Array<any>;
	loading: boolean;
	manageColumns?: boolean;
	nodeStatus?: React.ReactNode;
	pageName: string;
	refreshData?: any;
	tableHeader: any[];
	title?: boolean;
	booleanFilterLabel?: string;
	booleanFilterToggle?: () => void;
	booleanFilterValue?: boolean;
}

interface State {
	currentPage: number;
	columnHeader: any[];
	filteredColumn: any[];
	itemsPerPage: number;
	paginatedData: any[];
	matches: string[];
	data: any[];
	searchedKeyword: { word: string; columnName: string; key: string };
	listOfSearchedKeywords: Array<{ key?: string; columnName?: string; word: string }>;
	sort: { column: boolean | null; direction: string };
	dragOver: any;
	columnIdxs: any[];
}

export const ColumnContext = React.createContext([[], () => {}]);

const ColumnProvider = props => {
	return <ColumnContext.Provider value={props.column}>{props.children}</ColumnContext.Provider>;
};

const options = {
	shouldSort: true,
	includeMatches: true,
	threshold: 0.1,
	location: 0,
	distance: 100,
	maxPatternLength: 32,
	minMatchCharLength: 1,
	keys: [''],
};

class ListViewWrapper extends React.Component<Props, State> {
	state: State = {
		currentPage: 0,
		columnHeader: this.props.tableHeader,
		filteredColumn: [],
		paginatedData: [],
		itemsPerPage: 25,
		matches: [],
		data: [],
		searchedKeyword: {
			word: '',
			columnName: '',
			key: '',
		},
		listOfSearchedKeywords: [],
		sort: {
			column: null,
			direction: '',
		},
		dragOver: '',
		columnIdxs: [],
	};

	static defaultProps: { addButton: boolean; manageColumns: boolean } = {
		addButton: true,
		manageColumns: true,
	};

	componentDidMount(): void {
		/*
		 * On mount it will check whether the user has a table settings (user preferred settings) in the local storage
		 * If there is a table settings, it will set the state of the column with the table settings
		 * If not found, it will set the state of the column from the props that were passed from a specific component (e.g gateway)
		 * FilteredOutIndex is the column index that get filtered out. We need the index to pass to context
		 * */

		/**
		 * 	@documentation https://stackoverflow.com/questions/46915002/argument-of-type-string-null-is-not-assignable-to-parameter-of-type-string
		 */
		const tableSettings: any = JSON.parse(
			localStorage.getItem(TableSettings.tableSettings) || '{}'
		);
		const headers: any = this.props.tableHeader;

		const filteredIndexes: any = [];
		const filteredOutIndex: any = [];
		if (tableSettings && tableSettings[this.props.pageName]) {
			headers.forEach((item, index) => {
				const settings = tableSettings[this.props.pageName][index];
				if (settings && settings['checked']) {
					filteredIndexes.push({ ...item, checked: settings['checked'] });
				}

				if (settings && settings['checked'] === false) {
					filteredOutIndex.push(index);
				}
			});
		}

		this.setState({
			columnHeader: filteredIndexes.length ? filteredIndexes : this.props.tableHeader,
			data: this.props.listData,
			paginatedData: this.getPageItems(this.props.listData),
			filteredColumn: filteredOutIndex.length ? filteredOutIndex : [],
			columnIdxs: filteredIndexes.length
				? filteredIndexes.map((v, index) => v.key)
				: this.props.tableHeader.map((v, index) => v.key),
		});
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (prevProps.listData !== this.props.listData) {
			// Check whether data has arrived from backend
			if (this.state.listOfSearchedKeywords.length && !this.state.searchedKeyword.word) {
				this.filterStateByKeyword(this.props.listData);
			} else if (this.state.searchedKeyword.word) {
				this.filterStateByKeywordRealTime(this.props.listData);
			} else {
				this.setState({
					data: this.props.listData,
					paginatedData: this.getPageItems(this.props.listData),
				});
			}
		}
	}

	// ================================ SEARCH FUNCTIONALITY ========================================

	autoSuggest = (optionsKey: { headerName: string; key: string }) => {
		if (optionsKey) {
			this.getFilteredSearchKeyword(
				this.state.searchedKeyword.word,
				false,
				optionsKey.key,
				optionsKey.headerName
			);
		}
	};

	removeKeywordFromMultiFilter = (i, keyword) => {
		this.setState(
			{
				listOfSearchedKeywords: this.state.listOfSearchedKeywords.filter(
					(word, index) => index !== i
				),
				searchedKeyword:
					keyword.word === this.state.searchedKeyword.word
						? { word: '', columnName: '', key: '' }
						: this.state.searchedKeyword,
			},
			() => {
				// @ts-ignore
				const [data, paginatedData] = this.onMultiFilterSearch(SearchActions.DeleteKeywordSearch);
				this.setState({
					data,
					paginatedData,
					matches: [],
				});
			}
		);
	};

	getFilteredSearchKeyword = (
		keyword: string,
		onSubmit: boolean = false,
		optionsKey: string,
		columnName: string
	) => {
		if (onSubmit) {
			// after submitting/clicking enter on the search bar
			// const newSearch = { keyword: this.state.filteredColumnForKeyword.word, options: this.state.filteredColumnForKeyword, columnName };
			this.setState({
				listOfSearchedKeywords: this.state.listOfSearchedKeywords.concat(
					this.state.searchedKeyword
				),
				matches: [],
			});
		} else if (this.state.listOfSearchedKeywords.length && !onSubmit) {
			// @ts-ignore
			const [data, paginatedData] = this.onMultiFilterSearch(
				SearchActions.NextKeywordSearch,
				keyword,
				optionsKey
			);
			this.setState({
				data,
				paginatedData,
				searchedKeyword: { key: optionsKey, word: keyword, columnName },
			});
		} else {
			// @ts-ignore
			const [data, paginatedData] = this.onMultiFilterSearch(
				SearchActions.FirstKeywordSearch,
				keyword,
				optionsKey
			);
			this.setState(() => ({
				searchedKeyword: { key: optionsKey, word: keyword, columnName },
				data,
				paginatedData,
			}));
		}
	};

	getFuse = (data, optionsKeyFromSearchedList?: string) => {
		if (!optionsKeyFromSearchedList) {
			options.keys = this.props.tableHeader.map(header => header.key);
		} else {
			options.keys = [optionsKeyFromSearchedList];
		}
		return new Fuse(data, options);
	};

	getDataFromFuse = result => {
		const matches = result.map(data => data.matches);
		const matchesKey = [].concat(...matches).map((match: any) => match.key);
		this.setState({
			matches: this.props.tableHeader.filter(header =>
				[...new Set(matchesKey)].includes(header.key)
			),
		});
		const data = result.map(res => res.item);
		return [data, this.getPageItems(data)];
	};

	onMultiFilterSearch = (action: string, keyword: string | null = null, optionsKey: string) => {
		/*
		 * this.props.listData' is the source of truth when searching for a keyword.
		 * Fuse will then process the search criteria and return a list of data which will set to
		 * 'this.state.data'. For sorting and pagination, the source of truth will be 'this.state.data'
		 *
		 * */
		let result;

		switch (action) {
			case SearchActions.FirstKeywordSearch:
				if (!keyword) {
					// default state/if the search bar is empty
					this.setState({ matches: [] });
					return [this.props.listData, this.getPageItems(this.props.listData)];
				} else {
					result = this.getFuse(this.props.listData, optionsKey).search(keyword);
				}
				break;
			case SearchActions.NextKeywordSearch:
				if (keyword === null) {
					return;
				}
				if (keyword.length === 0) {
					// if the search bar is empty
					this.state.listOfSearchedKeywords.forEach(data => {
						result = this.getFuse(this.props.listData, data.key).search(data.word);
					});
					this.setState(() => ({ matches: [] }));
					this.setState(() => ({
						searchedKeyword: { columnName: '', key: '', word: keyword },
					}));
				} else {
					// if we are typing the keyword on the search bar
					let localResult;
					this.state.listOfSearchedKeywords.forEach(data => {
						localResult = this.getFuse(this.state.data, data.key).search(data.word);
					});
					const [getDataFilteredFromSearchedKeyword] = this.getDataFromFuse(localResult);
					result = this.getFuse(getDataFilteredFromSearchedKeyword, optionsKey).search(keyword);
				}
				break;
			case SearchActions.DeleteKeywordSearch:
				if (this.state.listOfSearchedKeywords.length) {
					this.state.listOfSearchedKeywords.forEach(data => {
						result = this.getFuse(this.props.listData, data.key).search(data.word);
					});
				} else {
					return [this.props.listData, this.getPageItems(this.props.listData)];
				}
				break;
		}

		const [data, paginatedData] = this.getDataFromFuse(result);
		return [data, paginatedData];
	};

	// ==================================== PAGINATION ==============================================

	handlePageClick = event => {
		this.setState(() => ({
			currentPage: event.selected,
			paginatedData: this.getPageItems(this.state.data, event.selected),
		}));
	};

	getPageItems = (updatedData, currentPage = null, itemsPerPage = null) => {
		const items = itemsPerPage !== null ? itemsPerPage : this.state.itemsPerPage;
		const current = currentPage !== null ? currentPage : this.state.currentPage;
		// @ts-ignore
		const startingIndex: number | null = current * items;
		// @ts-ignore
		const lastIndex: number = startingIndex + items;
		// @ts-ignore
		if (updatedData.length < items) {
			return updatedData;
		} else if (updatedData.length < startingIndex) {
			// if the starting index is greater than the data, slice the first 25 items (default items per page)
			return updatedData.slice(0, 25);
		} else {
			return updatedData.slice(startingIndex, lastIndex);
		}
	};

	// ================================== MANAGE COLUMNS ============================================

	getColumnHeader = header => {
		/*
		 * will get the data column index (data column and header column is rendered separately, both does not know each other)
		 *
		 * */
		const getFilteredOutColumnIndex = header.reduce(
			(out, bool, index) => (bool.checked ? out : out.concat(index)),
			[]
		);

		/*
		 * tableSettings is an object that will be saved in the local storage
		 * contains all the settings (user preferred settings) for the table
		 * for now it only save the preferred columns the user wants
		 *
		 **/

		const tableSettings = {};
		tableSettings[this.props.pageName] = {};
		header.forEach(hdr => {
			if (tableSettings && tableSettings[this.props.pageName]) {
				tableSettings[this.props.pageName][hdr.id] = {
					checked: hdr.checked,
					key: this.props.tableHeader[hdr.id].key,
				};
			}
		});

		this.setState(
			{
				columnHeader: header.filter(head => head.checked),
			},
			() => {
				this.setState({
					columnIdxs: this.state.columnHeader.map((v, index) => v.key),
				});
			}
		);
		this.setState({ filteredColumn: getFilteredOutColumnIndex });

		localStorage.setItem(TableSettings.tableSettings, JSON.stringify(tableSettings));
	};

	// ================================= SORT FUNCTIONALITY =========================================

	onSort = (column, data) => {
		const direction = this.state.sort.column
			? this.state.sort.direction === TableSettings.asc
				? TableSettings.desc
				: TableSettings.asc
			: TableSettings.desc;
		const sortedItems = data.sort((a, b) => {
			let getValueA = Utils.getNestedProp(a, column);
			let getValueB = Utils.getNestedProp(b, column);

			// check for nested component
			if (typeof getValueA === 'object' && typeof getValueB === 'object') {
				if (getValueA && getValueA.props) {
					getValueA = getValueA['props'][column];
					getValueB = getValueB['props'][column];
				}
			}

			if (typeof getValueA === 'string' && typeof getValueB === 'string') {
				return getValueA.localeCompare(getValueB, undefined, { numeric: true });
			}

			return getValueA - getValueB;
		});

		if (direction === TableSettings.desc) {
			data.reverse();
		}

		this.setState({
			// slice to get the items displayed per page
			paginatedData: sortedItems.slice(0, this.state.itemsPerPage),
			sort: {
				column,
				direction,
			},
		});
	};

	getSVGSortIcon = column => {
		if (this.state.sort.direction === TableSettings.desc && this.state.sort.column === column) {
			return <DownSortIcon className={'svg-spacing'} />;
		}

		if (this.state.sort.direction === TableSettings.asc && this.state.sort.column === column) {
			return <DownSortIcon style={{ transform: 'rotate(0.5turn)' }} className={'svg-spacing'} />;
		}
		return <SortIcon className={'svg-spacing'} />;
	};

	// ================================= REFRESH DATA =========================================

	toggleRefreshButton = async () => {
		await this.props.refreshData();
	};

	filterStateByKeyword = fetchedData => {
		let result;
		this.state.listOfSearchedKeywords.forEach((keyword, i) => {
			if (i === 0) {
				// will filter/search the first keyword with the default data ('this.props.listData')
				result = this.getFuse(fetchedData, keyword.key).search(keyword.word);
			} else {
				// will filter/search the subsequent keyword with the result that is being returned from the first search operation
				const localResult = result.map(res => res.item);
				result = this.getFuse(localResult, keyword.key).search(keyword.word);
			}
		});
		const [data, paginatedData] = this.getDataFromFuse(result);
		this.setState({ data, paginatedData, matches: [] });
	};

	filterStateByKeywordRealTime = fetchedData => {
		let result;
		if (this.state.listOfSearchedKeywords.length) {
			this.state.listOfSearchedKeywords.forEach((keyword, i) => {
				if (i === 0) {
					// will filter/search the first keyword with the default data ('this.props.listData')
					result = this.getFuse(fetchedData, keyword.key).search(keyword.word);
				} else {
					// will filter/search the subsequent keyword with the result that is being returned from the first search operation
					const localResult = result.map(res => res.item);
					result = this.getFuse(localResult, keyword.key).search(keyword.word);
				}
			});
			// will filter based on the word in search bar after running all the multi-filter keyword
			result = this.getFuse(
				result.map(res => res.item),
				this.state.searchedKeyword.key
			).search(this.state.searchedKeyword.word);
		} else {
			result = this.getFuse(fetchedData, this.state.searchedKeyword.key).search(
				this.state.searchedKeyword.word
			);
		}
		const [data, paginatedData] = this.getDataFromFuse(result);
		this.setState({ data, paginatedData, matches: [] });
	};

	handleDragStart = e => {
		if (this.state.columnHeader.length > 0) {
			const { id } = e.target;
			const idx = this.state.columnHeader.findIndex(header => header.key === id);
			e.dataTransfer.setData('colIdx', idx);
		}
	};

	handleDragOver = e => e.preventDefault();

	handleDragEnter = e => {
		const { id } = e.target;
		this.setState({
			dragOver: id,
		});
	};

	handleOnDrop = e => {
		const { id } = e.target;
		if (this.state.columnHeader.length > 0) {
			const droppedColIdx = this.state.columnHeader.findIndex(header => header.key === id);
			const draggedColIdx = e.dataTransfer.getData('colIdx');
			const tempCols = [...this.state.columnHeader];
			tempCols[draggedColIdx] = this.state.columnHeader[droppedColIdx];
			tempCols[droppedColIdx] = this.state.columnHeader[draggedColIdx];

			this.setState({
				columnHeader: [...tempCols],
				filteredColumn: [...tempCols],
				dragOver: '',
				columnIdxs: tempCols.map((v, index) => v.key),
			});
		}
	};

	render() {
		const { t } = this.props;
		const csvHeaders = this.state.columnHeader.map(head => ({
			label: head.headerName,
			key: head.key,
		}));
		return (
			<>
				{this.props.children}
				<div className={'d-flex flex-row justify-content-end align-items-top pb-3 pt-4'}>
					{this.props.title ? (
						<p data-cy='dashboard-list-header' className={'mr-auto'}>
							{this.props.pageName}
						</p>
					) : null}
					{this.props.nodeStatus}
					<SearchBar
						suggestion={this.state.matches}
						onClickSuggestion={this.autoSuggest}
						getFilteredSearchKeyword={this.getFilteredSearchKeyword}
					/>
					<RefreshButton refreshData={this.toggleRefreshButton} />
					{this.props.addButton ? (
						<Link to={this.props.addButtonLink || '/'}>
							<Button
								data-cy='add-new-location'
								color='secondary'
								className={`${styles['add-button']}`}
							>
								<AddIcon className={'mb-1'} style={{ marginRight: '0.571em' }} />
								{this.props.addButtonText || 'Add New'}
							</Button>
						</Link>
					) : null}
				</div>
				<div className={'d-flex justify-content-end align-items-center mb-2'}>
					<div className={'mr-auto d-flex'}>
						<p className={`${styles['filtered-text']} d-flex align-items-center`}>Filtered By: </p>
						{!!this.state.listOfSearchedKeywords.length && (
							<ul className={'d-flex m-0 align-items-center pl-1'}>
								{this.state.listOfSearchedKeywords.map((keyword, i) => {
									const key = i + 2;
									return (
										<li key={key} className={styles['filtered-list']}>
											{keyword.columnName ? `${keyword.columnName}: ${keyword.word}` : keyword.word}
											<span onClick={() => this.removeKeywordFromMultiFilter(i, keyword)}>
												<CloseIcon className={`${styles['cancel-filtered-keyword']} svg-spacing`} />
											</span>
										</li>
									);
								})}
							</ul>
						)}
					</div>
					{this.props.booleanFilterToggle && (
						<div className={styles.booleanFilter}>
							<input
								type='checkbox'
								id='booleanFilter'
								data-cy='boolean-filter'
								checked={this.props.booleanFilterValue}
								onChange={this.props.booleanFilterToggle}
							/>
							<label htmlFor='booleanFilter'>{this.props.booleanFilterLabel}</label>
						</div>
					)}
					{this.props.manageColumns && (
						<ManageColumnsToggle
							title={this.props.pageName}
							tableHeader={this.props.tableHeader}
							getColumnHeader={this.getColumnHeader}
						/>
					)}
					<ExportCSVButton
						data={this.state.data}
						headers={csvHeaders}
						isAdminOrSupport={this.props.isAdminOrSupport}
					/>
				</div>
				<div className='d-flex flex-column'>
					{!this.props.loading && !this.props.error && !!this.state.paginatedData.length && (
						<>
							<Table data-cy='table' responsive className={`${styles['custom-table']}`}>
								<thead>
									<tr>
										{this.state.columnHeader.map((header, i) => {
											return (
												<th
													data-cy='table-header'
													className={`${styles['table-header']} ${
														this.state.dragOver && this.state.dragOver === header.key
															? styles['drag-over']
															: ''
													}`}
													key={i}
													id={header.key}
													draggable={true}
													onDragStart={this.handleDragStart}
													onDragOver={this.handleDragOver}
													onDrop={this.handleOnDrop}
													onDragEnter={this.handleDragEnter}
													onClick={() => this.onSort(header.key, this.state.data)}
												>
													{t(`${header.headerName}`)}
													{this.getSVGSortIcon(header.key)}
												</th>
											);
										})}
									</tr>
								</thead>
								<tbody>
									<ColumnProvider column={this.state.filteredColumn}>
										{this.props.dataRow(this.state.paginatedData, this.state.columnIdxs)}
									</ColumnProvider>
								</tbody>
							</Table>
							<ReactPaginate
								previousLabel={<DownArrow className={`mb-1 ${styles['rotate-left']}`} />}
								nextLabel={<DownArrow className={`mb-1 ${styles['rotate-right']}`} />}
								breakLabel={'...'}
								breakClassName={`${styles['pagination-custom']}`}
								breakLinkClassName={`${styles['link']} d-inline-block`}
								pageCount={this.state.data.length / this.state.itemsPerPage}
								marginPagesDisplayed={1}
								pageRangeDisplayed={2}
								pageClassName={styles['pagination-custom']}
								onPageChange={this.handlePageClick}
								previousClassName={styles['pagination-custom']}
								nextClassName={styles['pagination-custom']}
								containerClassName={`${styles['custom-list']} pagination mx-auto d-flex justify-content-center text-center mb-5`}
								subContainerClassName={`pages pagination`}
								pageLinkClassName={`${styles['link']} d-inline-block`}
								previousLinkClassName={`${styles['link']} d-inline-block`}
								nextLinkClassName={`${styles['link']} d-inline-block`}
								activeClassName={`active ${styles['active-custom']}`}
							/>
						</>
					)}
					{this.props.loading && !this.props.error && <TableLoadingSkeleton />}
					{!this.state.paginatedData.length && !this.props.loading && (
						<h2 data-cy='dashboard-no-data'>No {this.props.pageName} found</h2>
					)}
				</div>
				{!this.props.loading && this.props.error && (
					<ErrorIndicator errorMsg={`Error fetching ${this.props.pageName}`} />
				)}
			</>
		);
	}
}

export default withTranslation()(ListViewWrapper);
