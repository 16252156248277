export const listFirmwares = `query ListFirmwares(
    $filter: TableFirmwareFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFirmwares(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        create_time
        firmware_id
        name
        s3_link
        type
        uploaded_by
        version
        status
      }
      nextToken
    }
  }
  `;
