import ReactAppEnvValues from './IReactAppEnv';

const dev1: ReactAppEnvValues = {
	aws_project_region: 'ap-southeast-1',
	aws_cognito_identity_pool_id: 'ap-southeast-1:5867e2f9-f41d-443d-bb17-f531cf9ecc17',
	aws_cognito_region: 'ap-southeast-1',
	aws_user_pools_id: 'ap-southeast-1_szWfUQnGS',
	aws_user_pools_web_client_id: '37atbm86p3pdaqp6tng26i6ufa',
	aws_appsync_graphqlEndpoint:
		'https://ttpvcaaqgjaf3g2nypmdx5lpky.appsync-api.ap-southeast-1.amazonaws.com/graphql',
	aws_appsync_region: 'ap-southeast-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	APIaddress: 'https://ycy-api.sensorflow.org',
	APIstage: 'dev1',
};

export default dev1;
