export enum UserRolesCognitoGroups {
	Admin = 'Admin',
	User = 'Users',
	Lambda = 'Lambda',
	Installer = 'Installer',
	ClientAdmin = 'Client_Admin',
	GatewayEC2 = 'Gateway_EC2',
	Support = 'Support',
}

export default UserRolesCognitoGroups;
