import React from 'react';
import { UncontrolledDropdown } from 'reactstrap';
import styles from '../SideNav.module.css';

interface State {
	dropdownOpen: boolean;
}

class DropDown extends React.Component<{}, State> {
	state = {
		dropdownOpen: false,
	};

	toggle = () => {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen,
		}));
	};

	onMouseEnter = () => {
		this.setState({ dropdownOpen: true });
	};

	onMouseLeave = () => {
		this.setState({ dropdownOpen: false });
	};

	setClassName = () => {
		return this.state.dropdownOpen
			? styles['custom-nav-item']
			: [styles['custom-nav-item'], styles['custom-nav-item:hover']].join(' ');
	};

	render() {
		return (
			<UncontrolledDropdown
				onMouseOver={() => this.onMouseEnter()}
				onMouseLeave={() => this.onMouseLeave()}
				isOpen={this.state.dropdownOpen}
				toggle={this.toggle}
				cssModule={{ 'nav-item': styles['custom-nav-item'] }}
				className={this.setClassName()}
				nav
				inNavbar
			>
				{this.props.children}
			</UncontrolledDropdown>
		);
	}
}

export default DropDown;
