export const listACModels = `query listACModels {
  listACModels{
    count
    ac_models {
      ac_model_id,
      ac_brand,
      ac_model,
      ac_details
      updated_at
      created_at
    }
  }
}
`;

export const createACModel = `mutation CreateACModel($input: CreateACModelsInput!) {
    createACModel(input: $input) {
        success
    }
 }
`;

export const updateACModel = `mutation UpdateACModel($input: UpdateACModelsInput!) {
    updateACModel(input: $input) {
        success
    }
 }
`;

export const deleteACModel = `mutation DeleteACModel($input: DeleteACModelsInput!) {
    deleteACModel(input: $input) {
        success
    }
 }
`;
