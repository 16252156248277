import React, { useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import styles from './Node.module.css';
import Skeleton from 'react-loading-skeleton';
import useWindowDimensions from '../../../../common/Hooks/Dimension.component';

interface Props {
	Nodes: any[];
	loading: boolean;
}

interface State {
	sum: {
		online: number;
		offline: number;
		neverSeen: number;
	};
}

const NodeStatus = props => {
	const [status, setStatus] = useState<State>({ sum: { online: 0, offline: 0, neverSeen: 0 } });
	const { width } = useWindowDimensions();

	const countNodeStatuses = (Nodes: any[]) => {
		//TODO: refactor
		let online = 0;
		let offline = 0;
		let neverSeen = 0;

		Nodes.forEach(node => {
			switch (node.status) {
				case 'online':
					online++;
					break;
				case 'offline':
					offline++;
					break;
				default:
					neverSeen++;
			}
		});

		return { sum: { online, offline, neverSeen } };
	};

	useEffect(() => {
		setStatus(countNodeStatuses(props.Nodes));
	}, [props]);

	if (!props.loading && !props.Nodes.length) {
		return null;
	}

	const { Nodes, loading, selectedStatus } = props;

	return (
		<div className={`d-flex flex-row mr-auto ${width < 1156 && styles['container-width']}`}>
			<Col
				xs='1'
				sm='1'
				md='2'
				lg='2'
				xl='2'
				id='Offline'
				className={`${styles.Rectangle} ${
					selectedStatus === 'Offline' ? styles.selectedNodeStatus : ''
				}`}
				onClick={props.onStatusHandler}
			>
				{width > 992 ? (
					<span className={`${styles['Offline-Copy-2']} ${styles['status-label']} `}>
						{Nodes.length && !loading ? 'OFFLINE' : <Skeleton />}
					</span>
				) : null}
				<span
					data-cy='offline-nodes'
					className={`${width < 992 && styles['offline-number']} ${styles['-copy']}`}
				>
					{Nodes.length && !loading ? status.sum.offline : <Skeleton />}
				</span>
			</Col>
			<Col
				xs='1'
				sm='1'
				md='2'
				lg='2'
				xl='2'
				id='Online'
				className={`${styles.Rectangle} ${
					selectedStatus === 'Online' ? styles.selectedNodeStatus : ''
				}`}
				onClick={props.onStatusHandler}
			>
				{width > 992 ? (
					<span className={`${styles['Online']} ${styles['status-label']}`}>
						{Nodes.length && !loading ? 'ONLINE' : <Skeleton />}
					</span>
				) : null}
				<span
					data-cy='online-nodes'
					className={`${width < 992 && styles['online-number']} ${styles['-copy']}`}
				>
					{Nodes.length && !loading ? status.sum.online : <Skeleton />}
				</span>
			</Col>
			<Col
				xs='1'
				sm='1'
				md='2'
				lg='2'
				xl='2'
				id='Never Seen'
				className={`${styles.Rectangle} ${
					selectedStatus === 'Never Seen' ? styles.selectedNodeStatus : ''
				}`}
				onClick={props.onStatusHandler}
			>
				{width > 992 ? (
					<span className={`${styles['Never-Seen']} ${styles['status-label']}`}>
						{Nodes.length && !props.loading ? 'NEVER SEEN' : <Skeleton />}
					</span>
				) : null}
				<span
					data-cy='never-seen-nodes'
					className={`${width < 992 && styles['never-seen-number']} ${styles['-copy']} `}
				>
					{Nodes.length && !loading ? status.sum.neverSeen : <Skeleton />}
				</span>
			</Col>
			<Col
				xs='1'
				sm='1'
				md='2'
				lg='2'
				xl='2'
				id='All'
				className={`${styles.Rectangle} ${
					selectedStatus === 'All' ? styles.selectedNodeStatus : ''
				}`}
				onClick={props.onStatusHandler}
			>
				{width > 992 ? (
					<span className={`${styles['Never-Seen']} ${styles['status-label']}`}>
						{Nodes.length && !loading ? 'ALL' : <Skeleton />}
					</span>
				) : null}
				<span
					data-cy='all-nodes'
					className={`${width < 992 && styles['never-seen-number']} ${styles['-copy']} `}
				>
					{Nodes.length && !loading ? Nodes.length : <Skeleton />}
				</span>
			</Col>
		</div>
	);
};

export default NodeStatus;
