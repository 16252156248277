import React from 'react';
import { Col } from 'reactstrap';
import RoomStyles from '../../../Room.module.css';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { Temp } from '../../../RoomDevice.component';

const DeviceStream = ({ stream, deviceData, getConnection }): JSX.Element => {
	const onCreateDeviceStreamStatus = `subscription OnCreateDeviceStreamStatus ($stream_id: String) {
 		onCreateDeviceStreamStatus(stream_id: $stream_id) {
			stream_id,
			data
  	}
	}`;

	const deviceType = {
		humidity_ambient: {
			title: 'AMBIENT HUMIDITY',
			unit(data) {
				return data + '%Rh';
			},
		},
		pressure_ambient: {
			title: 'AMBIENT PRESSURE',
			unit(data) {
				return data + 'hPa';
			},
		},
		temperature_ambient: {
			title: 'AMBIENT TEMPERATURE',
			unit(data) {
				return <Temp>{data}</Temp>;
			},
		},
		dew_point: {
			title: 'DEW POINT',
			unit(data) {
				return <Temp>{data}</Temp>;
			},
		},
	}[stream.device_type];

	return (
		<Connect
			key={stream.stream_id}
			subscription={graphqlOperation(onCreateDeviceStreamStatus, {
				stream_id: stream.stream_id,
			})}
			onSubscriptionMsg={(prevData, { onCreateDeviceStreamStatus: newStatus }) => {
				return newStatus;
			}}
		>
			{({ data, loading, error }) => {
				if (error) return <h3>Error</h3>;
				if (loading) return <h3>Loading...</h3>;
				const hasNewData = data.data && data.timestamp > deviceData.timestamp;
				const getLatestData = hasNewData ? JSON.parse(data.data) : null;
				const getUnitValue = hasNewData ? getLatestData : deviceData;

				return (
					<>
						{getConnection ? (
							<Col key={stream.stream_id} md='12' className={RoomStyles.nodeLayout}>
								<p>{deviceType.title}</p>
								<p>{deviceType.unit(getUnitValue[stream.device_type])}</p>
							</Col>
						) : null}
					</>
				);
			}}
		</Connect>
	);
};

export default DeviceStream;
