import * as React from 'react';
import ReactSelect from 'react-select';
import { Button, Col, Form, Input, Row, Container } from 'reactstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal.component';
import { ModalActions } from '../../../../enums/confirmationModal';

interface State {
	location_id: string;
	description: string;
	modalActions: number | null;
	modalState: boolean;
	isDirty: boolean;
}

interface Props extends WithTranslation {
	onSubmit: (location: { location_id: string; description: string }) => any;
	locations: any[];
	clicked: boolean;
	history: any;
}

class CreateGatewayForm extends React.Component<Props, State> {
	state: State = {
		location_id: '',
		description: '',
		modalActions: null,
		modalState: false,
		isDirty: false,
	};

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const checkField =
			this.state.location_id !== prevState.location_id ||
			this.state.description !== prevState.description;

		const isEmptyField = !this.state.location_id.length && !this.state.description.length;
		if (checkField && !this.state.isDirty && !isEmptyField) {
			this.setState({ isDirty: true });
		}

		if (checkField && isEmptyField) {
			this.setState({ isDirty: false });
		}
	}

	submit = e => {
		e.preventDefault();
		this.toggleModal(ModalActions.Submit);
	};

	changeLocationName = selectedValue => {
		this.setState({
			location_id: selectedValue ? selectedValue.value : '',
		});
	};

	changeDescription = e => {
		this.setState({ description: e.target.value });
	};

	backToIndex = () => {
		if (this.state.isDirty) {
			this.toggleModal(ModalActions.Cancel);
		} else {
			this.props.history.push('/locations');
		}
	};

	toggleModal = (action: ModalActions) => {
		this.setState(prev => ({
			modalActions: action,
			modalState: !prev.modalState,
		}));
	};

	confirmClick = async () => {
		if (this.state.modalActions === ModalActions.Submit) {
			this.props.onSubmit({
				location_id: this.state.location_id,
				description: this.state.description,
			});
			this.setState(prev => ({
				modalState: !prev.modalState,
			}));
		}

		if (this.state.modalActions === ModalActions.Cancel) {
			this.props.history.push('/locations');
		}
	};

	cancelClick = () => {
		this.setState(prev => ({
			modalState: !prev.modalState,
		}));
	};

	render() {
		const header = 'Set Up a Gateway';
		const { locations } = this.props;
		const locationOptions =
			locations &&
			locations.length > 0 &&
			locations
				.map(location => ({
					value: location.location_id,
					label: location.location_name,
				}))
				.sort((a, b) => a.label.localeCompare(b.label));
		return (
			<>
				<Container>
					<h1 className='create-form-title'>{header}</h1>
					<Row>
						<Col xs='1' sm='1' md='1' lg='3' />
						<Col xs='10' sm='10' md='10' lg='6'>
							<Form onSubmit={this.submit}>
								<Row className='create-form-card'>
									<ul className='list-group'>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='name'>
													{this.props.t('LOCATION')}
												</label>
												<br />
												<ReactSelect
													isClearable={true}
													isSearchable={true}
													options={locationOptions}
													onChange={this.changeLocationName}
												/>
											</div>
										</li>
										<li className='list-group-item'>
											<div className='create-form-group'>
												<label className='create-label' htmlFor='description'>
													{this.props.t('GATEWAY NAME')}
												</label>
												<br />
												<Input
													type='text'
													className='custom-input'
													name='description'
													id='description'
													placeholder='E.g. Level 1 lift'
													onChange={this.changeDescription}
													required
												/>
											</div>
										</li>
									</ul>
								</Row>

								<br />
								<br />
								<Row>
									<Button
										type='submit'
										className='btn-secondary create-form-button'
										disabled={
											!this.state.description ||
											this.props.clicked ||
											!this.state.location_id.length
										}
									>
										{this.props.t('SUBMIT')}
									</Button>
								</Row>
								<Row>
									<div className='create-form-cancel' onClick={this.backToIndex}>
										{this.props.t('CANCEL')}
									</div>
								</Row>
							</Form>
						</Col>
						<Col xs='1' sm='1' md='1' lg='3' />
					</Row>
				</Container>
				<ConfirmationModal
					openModal={this.toggleModal}
					title={this.props.t(
						this.state.modalActions === ModalActions.Submit
							? 'CREATE NEW GATEWAY'
							: 'CONFIRM CANCELLATION'
					)}
					message={this.props.t(
						this.state.modalActions === ModalActions.Submit
							? 'Are you sure you want to submit?'
							: 'All unsaved changes would be lost. Are you sure you want to cancel?'
					)}
					modal={this.state.modalState}
					onConfirm={this.confirmClick}
					onCancel={this.cancelClick}
				/>
			</>
		);
	}
}

export default withTranslation()(CreateGatewayForm);
