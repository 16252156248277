import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { UserContext } from '../../../common/Context/UserContext';
import style from './CreateGateway.module.css';

interface Props {
	gatewayData: any;
	history: any;
	onResetForm: any;
}

class CreateGatewayComplete extends React.Component<Props, {}> {
	constructor(props) {
		super(props);
		this.navigateToProvisionedGateways = this.navigateToProvisionedGateways.bind(this);
		this.downloadRef = React.createRef();
	}

	downloadRef: any;

	navigateToProvisionedGateways = () => {
		this.props.history.push(`/locations/${this.props.gatewayData['location_id']}`);
	};
	resetForm = () => {
		this.props.onResetForm();
	};

	/**
	 * CY: to remove this method in future,
	 * should get list of locations from all locations (source of truth) instead of users locations in Context- will require refactoring structure of the parent component
	 */
	getLocationNameFromId = locationId => {
		const locations = JSON.parse(this.context.getLocation);
		console.log(locations);
		const location = locations.find(loc => {
			return loc.location_id === locationId;
		});
		return location && location.location_name;
	};

	download = () => {
		this.downloadRef.current.click();
	};

	render() {
		const header = 'Gateway Created Successfully';
		const locationName = this.getLocationNameFromId(this.props.gatewayData['location_id']);

		return (
			<Container className={style['load-page-container']}>
				<h1 className='create-form-title'>{header}</h1>

				<span className='create-form-subheader' style={{ textAlign: 'center' }}>
					Download the activation file below.
					<br />
					Also, don’t forget to label the gateway with its Balena ID.{' '}
				</span>

				<div className={style['gateway-success-rectangle']}>
					{/* checking that location name is properly resolved before displaying */}
					{locationName ? (
						<Row>
							<Col className={style['gateway-success-label']}>Location: </Col>
							<Col className={style['gateway-success-vars']}>{locationName}</Col>
						</Row>
					) : null}
					<Row>
						<Col className={style['gateway-success-label']}>Description</Col>
						<Col className={style['gateway-success-vars']}>
							{this.props.gatewayData['description']}
						</Col>
					</Row>
					<Row>
						{/* pseudo download button */}
						<Col className={style['download-button']} onClick={this.download}>
							DOWNLOAD
						</Col>
					</Row>
				</div>
				<div style={{ margin: '56px', textAlign: 'center' }}>
					<span className='clickable-element' onClick={this.resetForm}>
						CREATE ANOTHER GATEWAY
					</span>
					<span style={{ margin: '0 31px' }}>|</span>
					<span className='clickable-element' onClick={this.navigateToProvisionedGateways}>
						VIEW PROVISIONED GATEWAYS
					</span>
				</div>

				<a
					hidden={true}
					title='Download Link'
					href={this.props.gatewayData.download_link}
					ref={this.downloadRef}
				>
					Download Gateway File
				</a>
			</Container>
		);
	}
}

export default CreateGatewayComplete;
CreateGatewayComplete.contextType = UserContext;
