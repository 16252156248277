import React from 'react';
import { Alert } from 'reactstrap';

interface Props {
	errorMsg: string;
}

class ErrorIndicator extends React.Component<Props, {}> {
	render() {
		console.error(this.props.errorMsg);
		return (
			<Alert data-cy='error-message' color='danger-alert'>
				{this.props.errorMsg ? this.props.errorMsg : 'An Error has Occurred'}
			</Alert>
		);
	}
}

export default ErrorIndicator;
