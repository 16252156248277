import React from 'react';
import {
	Collapse,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LocationLogo } from '../../../assets/icons/locations.svg';
import { ReactComponent as UserLogo } from '../../../assets/icons/users.svg';
import { ReactComponent as GatewayLogo } from '../../../assets/icons/gateway.svg';
import styles from './SideNav.module.css';
import DropDownNavbar from './Dropdown/Dropdown.component';
import RouteAuthorizer from '../../common/authorization/RouteAuthorizer.component';
import { RouteComponentProps } from '../../../interface/Route';

interface Props extends RouteComponentProps {
	isOpen: boolean;
}

const SideNav: React.FC<Props> = (props): JSX.Element => {
	const { t } = useTranslation();

	// const changeLanguage = lng => {
	// 	return i18n.changeLanguage(lng);
	// };

	return (
		<Collapse style={{ height: '100%' }} isOpen={props.isOpen}>
			<Nav style={{ height: '100%' }} vertical fill className={`${styles['custom-navbar']}`}>
				{/* <NavItem cssModule={{ 'nav-item': styles['custom-nav-item'] }}>
					<NavLink className={styles['custom-navlink']} tag={Link} to='/landing' disabled={true}>
						<span>
							<HomeLogo />
						</span>
					</NavLink>
				</NavItem> */}
				<DropDownNavbar>
					<DropdownToggle className={styles['custom-navlink']} nav tag={Link} to='/locations'>
						<LocationLogo />
					</DropdownToggle>
					<DropdownMenu className={styles['custom-dropdown']}>
						<DropdownItem className={`${styles['custom-dropdown-header']}`} header>
							{t('LOCATION')}
						</DropdownItem>
						<RouteAuthorizer>
							<NavLink className={styles['custom-dropdown-link']} tag={Link} to='/locations'>
								<span>{t('View All Locations')}</span>
							</NavLink>
							<NavLink
								className={styles['custom-dropdown-link']}
								tag={Link}
								to='/manage-location/new'
							>
								<span>{t('Create New Location')}</span>
							</NavLink>
							<NavLink className={styles['custom-dropdown-link']} tag={Link} to='/ac-model'>
								<span>{t('View All AC Models')}</span>
							</NavLink>
							<NavLink className={styles['custom-dropdown-link']} tag={Link} to='/firmware-updates'>
								<span>{t('Firmware Updates')}</span>
							</NavLink>
							<NavLink
								className={styles['custom-dropdown-link']}
								tag={Link}
								to='/firmware-updates/schedule'
							>
								<span>{t('Schedule Firmware Update')}</span>
							</NavLink>
							<NavLink
								className={styles['custom-dropdown-link']}
								tag={Link}
								to='/firmware-updates/firmware-files'
							>
								<span>{t('Upload Firmware')}</span>
							</NavLink>
						</RouteAuthorizer>
					</DropdownMenu>
				</DropDownNavbar>
				<DropDownNavbar>
					<DropdownToggle className={styles['custom-navlink']} nav tag={Link} to='/create-gateway'>
						<GatewayLogo />
					</DropdownToggle>
					<DropdownMenu className={styles['custom-dropdown']}>
						<DropdownItem className={`${styles['custom-dropdown-header']}`} header>
							{t('GATEWAY')}
						</DropdownItem>
						<RouteAuthorizer>
							{/*<NavLink className={styles['custom-dropdown-link']} tag={Link} to='/gateway'>*/}
							{/*	<span>*/}
							{/*		{t('View All Gateway')}*/}
							{/*	</span>*/}
							{/*</NavLink>*/}
							<NavLink className={styles['custom-dropdown-link']} tag={Link} to='/create-gateway'>
								<span>{t('Create New Gateway')}</span>
							</NavLink>
						</RouteAuthorizer>
					</DropdownMenu>
				</DropDownNavbar>
				{/*<DropDownNavbar>*/}
				{/*	<DropdownToggle className={styles['custom-navlink']} nav>*/}
				{/*		<NodesLogo />*/}
				{/*	</DropdownToggle>*/}
				{/*	<DropdownMenu className={styles['custom-dropdown']}>*/}
				{/*		<DropdownItem className={`${styles['custom-dropdown-header']}`} header>*/}
				{/*			NODES*/}
				{/*		</DropdownItem>*/}
				{/*		<RouteAuthorizer>*/}
				{/*			<NavLink className={styles['custom-dropdown-link']} tag={Link} to='/nodes'>*/}
				{/*				<span>*/}
				{/*					{t('View All Nodes')}*/}
				{/*				</span>*/}
				{/*			</NavLink>*/}
				{/*		</RouteAuthorizer>*/}
				{/*	</DropdownMenu>*/}
				{/*</DropDownNavbar>*/}
				<DropDownNavbar>
					<DropdownToggle className={styles['custom-navlink']} nav tag={Link} to='/users'>
						<UserLogo />
					</DropdownToggle>
					<DropdownMenu className={styles['custom-dropdown']}>
						<DropdownItem className={`${styles['custom-dropdown-header']}`} header>
							{t('USERS')}
						</DropdownItem>
						<RouteAuthorizer>
							<NavLink className={styles['custom-dropdown-link']} tag={Link} to='/users'>
								<span>{t('View All Users')}</span>
							</NavLink>
						</RouteAuthorizer>
					</DropdownMenu>
				</DropDownNavbar>
				{/* <NavItem cssModule={{ 'nav-item': styles['custom-nav-item'] }}>
					<NavLink className={styles['custom-navlink']} tag={Link} to='/'>
						<span>
							<DashboardLogo />
						</span>
					</NavLink>
				</NavItem>
				<DropDownNavbar>
					<DropdownToggle className={styles['custom-navlink']} nav>
						<SupportLogo />
					</DropdownToggle>
				</DropDownNavbar> */}
				{/*<DropDownNavbar>*/}
				{/*	<DropdownToggle className={styles['custom-navlink']} nav>*/}
				{/*		<SupportLogo />*/}
				{/*	</DropdownToggle>*/}
				{/*	<DropdownMenu className={styles['custom-dropdown']}>*/}
				{/*		<DropdownItem className={`${styles['custom-dropdown-header']}`} header>*/}
				{/*			{t('Language')}*/}
				{/*		</DropdownItem>*/}
				{/*		<NavLink className={styles['custom-dropdown-link']} tag={Link} to={props.history.location.pathname}>*/}
				{/*			<span onClick={() => changeLanguage('en')}>En</span>*/}
				{/*		</NavLink>*/}
				{/*		<NavLink className={styles['custom-dropdown-link']} tag={Link} to={props.history.location.pathname}>*/}
				{/*			<span onClick={() => changeLanguage('de')}>De</span>*/}
				{/*		</NavLink>*/}
				{/*		<NavLink className={styles['custom-dropdown-link']} tag={Link} to={props.history.location.pathname}>*/}
				{/*			<span onClick={() => changeLanguage('ms')}>Ms</span>*/}
				{/*		</NavLink>*/}
				{/*	</DropdownMenu>*/}
				{/*</DropDownNavbar>*/}
				<NavItem style={{ flex: '1' }} />
			</Nav>
		</Collapse>
	);
};

// @ts-ignore
export default withRouter(SideNav);
