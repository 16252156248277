import React from 'react';
import { Container } from 'reactstrap';
import { ReactComponent as Logo } from '../../../../assets/icons/sensorflow-8.svg';
import style from './CreateGateway.module.css';
class CreateGatewayLoadingScreen extends React.Component<{}> {
	render() {
		const header = 'Set Up a Gateway';
		return (
			<Container style={{ textAlign: 'center' }}>
				<h1 className='create-form-title'>{header}</h1>

				<Logo className='sensor-8' style={{ marginTop: '3em', marginBottom: '1.5em' }} />

				<p className={style['load-page-text']}>
					I'm working on it... Come back in a couple of minutes?
				</p>
			</Container>
		);
	}
}
export default CreateGatewayLoadingScreen;
