import React, { Component } from 'react';
import Utils from '../../../../../utils';
import { API, graphqlOperation } from 'aws-amplify';
import ListViewWrapper from '../../../../common/ListViewWrapper/ListViewWrapper.component';
import StatusIndicator from '../../../../common/StatusIndicator/StatusIndicator.component';
import { nodesPageQuery } from './NodesQuery';
import NodeStatus from './NodeStatusInfo.component';
import CustomTd from '../../../../common/ListViewWrapper/CustomTd/CustomTd.component';
import CodeSnippet from '../../../../common/CodeSnippet/CodeSnippet.component';
import { ReactComponent as MoveNodesIcon } from '../../../../../../src/assets/icons/move-nodes.svg';
import styles from './ListNodes.module.css';
import axios, { AxiosError } from 'axios';
import environment from '../../../../../environments';
import { UserContext } from '../../../../common/Context/UserContext';
import { Auth } from 'aws-amplify';
import NodeTransferModal from './NodeTransferModal.component';
import CellUtils from '../../../../../utils/cell-colours';
import Fuse from 'fuse.js';
import PermissionUtils from '../../../../../utils/permission';

interface State {
	modal: boolean;
	nodeId: string;
	token: string;
	loading: boolean;
	nodes: any[];
	filteredNodes: any[];
	rooms: any[];
	selectedNodeStatus: string;
	gateways: any[];
	gatewaysForLocation: any[];
	error: boolean;
	nodeJoinStatistic: NodeJoinStatistics;
	nodeJoinStatisticTotal: number;
	nodeJoinLoading: boolean;
	selectedNodeForTransfer: any;
	errorModal: boolean;
	errorMessage: string;
}

interface NodeJoinStatistics {
	[key: string]: number;
}

interface Props {
	locationId: string;
}

const nodeJoinStatistic = `query nodeJoin($location_id: String!) {
	listNodeJoinStatistic(location_id: $location_id) {
		items {
			node_id
			count
		}
		total_rejoins
	}
 }`;

const PrimaryNodeIndicator = ({ cypress }) => {
	return (
		<small data-cy={cypress} className={styles['primary-node']}>
			P
		</small>
	);
};

class ListNodes extends Component<Props, State> {
	state: State = {
		modal: false,
		nodeId: '',
		token: '',
		loading: false,
		error: false,
		nodes: [],
		filteredNodes: [],
		rooms: [],
		selectedNodeStatus: 'All',
		gateways: [],
		gatewaysForLocation: [],
		nodeJoinStatistic: {},
		nodeJoinStatisticTotal: 45,
		selectedNodeForTransfer: null,
		nodeJoinLoading: false,
		errorModal: false,
		errorMessage: '',
	};

	fetchNodeStatisticData = async () => {
		this.setState({ nodeJoinLoading: true });
		try {
			const response = await API.graphql(
				graphqlOperation(nodeJoinStatistic, {
					location_id: this.props.locationId,
				})
			);
			const nodeJoinStatsMap = {};

			// @ts-ignore - TODO: check why this interface sucks
			for (const data of response.data.listNodeJoinStatistic.items) {
				const nodeId = data.node_id;
				nodeJoinStatsMap[nodeId] = data.count;
			}
			this.setState({
				nodeJoinStatistic: nodeJoinStatsMap,
				// @ts-ignore - TODO: check why this interface sucks
				nodeJoinStatisticTotal: response.data.listNodeJoinStatistic.total_rejoins,
			});
		} catch (e) {
			console.error(`Error fetching node join statistics`);
			console.error(e);
		} finally {
			const options = {
				shouldSort: true,
				threshold: 0.2,
				location: 0,
				distance: 100,
				maxPatternLength: 32,
				minMatchCharLength: 1,
				keys: ['last_seen_status', 'status'],
			};
			const transformedNodes =
				this.state.selectedNodeStatus === 'All'
					? this.transformNodeData(this.state.nodes, this.state.gateways, this.state.rooms)
					: new Fuse(this.transformNodeData(this.state.nodes, this.state.gateways, this.state.rooms), options).search(
							this.state.selectedNodeStatus
					  );

			this.setState({
				nodes: this.transformNodeData(this.state.nodes, this.state.gateways, this.state.rooms),
				filteredNodes: [...transformedNodes],
				nodeJoinLoading: false,
			});
		}
	};


	private processData = (response) => {
		const nodes = Utils.deepCopy(response.data.listNodesByLocation.nodes);
		const createdGateways = Utils.deepCopy(response.data.listRawGateways.items);
		const seenGateways = Utils.deepCopy(response.data.getLocations.gateways);
		const filteredGateways = seenGateways.filter(gateway => !gateway.deleted);
		const gateways = this.geCustomTdecoratedGatewaysWithDesc(createdGateways, filteredGateways);
		const rooms = response.data.listRoomsByLocationId;
		const gatewaysForLocation: any[] = this.getGatewaysForLocation(
			this.props.locationId,
			response.data.getLocations.app_id,
			nodes,
			gateways
		);
		this.setState({
			nodes,
			rooms,
			filteredNodes: [...nodes],
			gateways,
			gatewaysForLocation,
		});
		const options = {
			shouldSort: true,
			threshold: 0.2,
			location: 0,
			distance: 100,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ['last_seen_status', 'status'],
		};
		const transformedNodes =
			this.state.selectedNodeStatus === 'All'
				? this.transformNodeData(this.state.nodes, this.state.gateways, this.state.rooms)
				: new Fuse(this.transformNodeData(this.state.nodes, this.state.gateways, this.state.rooms), options).search(
						this.state.selectedNodeStatus
					);

		this.setState({
			nodes: this.transformNodeData(this.state.nodes, this.state.gateways, this.state.rooms),
			filteredNodes: [...transformedNodes],
			loading: false,
		});
	}

	fetchData = async () => {
		this.setState({ loading: true });
		try {
			const response: any = await API.graphql(
				graphqlOperation(nodesPageQuery, {
					location_id: this.props.locationId,
				})
			);
			this.processData(response);
		} catch (e) {
			this.setState({ error: true, loading: false });
			console.error(e);
		}
	};

	async componentDidMount() {
		try {
			const user = await Auth.currentAuthenticatedUser();
			this.setState({ token: user.signInUserSession.idToken.jwtToken });
			this.fetchNodeStatisticData();
			return this.fetchData();
		} catch (error) {
			console.error('Error fetching token');
		}
	}

	componentDidUpdate(prevProps) {
		// set to component state and refetch if location_id has changed
		if (this.props.locationId !== prevProps.locationId) {
			this.fetchData();
			this.fetchNodeStatisticData();
		}
	}

	toggleRefreshData = () => {
		this.fetchData();
		this.fetchNodeStatisticData();
	};

	private geCustomTdecoratedGatewaysWithDesc(createdGateways: any[], seenGateways: any[]) {
		const gatewayDescriptionMap = {};
		for (const gateway of createdGateways) {
			gatewayDescriptionMap[gateway.gateway_id] = gateway.description;
		}
		const decoratedGws = seenGateways.map(gateway => ({
			...gateway,
			description:
				gatewayDescriptionMap[gateway.gateway_id] || 'Balena-' + gateway.gateway_id.slice(0, 6),
			data: JSON.parse(gateway.data),
		}));
		return decoratedGws;
	}

	private getAssociatedGatewayDesc(gatewayMac: number, gateways, gatewaysCache) {
		let gateway = gatewaysCache[gatewayMac];

		if (!gateway) {
			gateway = gateways.find(g => g.data.gatewayMacAddress === gatewayMac);
			gatewaysCache[gatewayMac] = gateway;
		}
		return gateway
			? gateway.data.balenaDeviceNameAtInit
				? gateway.data.balenaDeviceNameAtInit
				: `${gateway.description} (V1 gateway)`
			: 'Gateway of unknown mac';
	}

	// TODO: relook at the logic for this method
	private getGatewaysForLocation(location_id: string, location_app_id: string, nodes: any[], gateways: any[]) {
		const gatewaysForLocation = gateways.filter(gateway => {
			return gateway.app_id === location_app_id;
		});
		return gatewaysForLocation;
	}

	private getNodeJoinStatForNode = (node, nodeJoinStatistics: NodeJoinStatistics): number => {
		const count = nodeJoinStatistics[node.node_id];
		if (count == null) {
			return 0;
		}
		return count;
	};

	private transformNodeData = (Nodes: any[], Gateways: any, Rooms: any[]) => {
		// this method is sequential so that it doesn't depend on the order in which fetched data returns
		// must be called after every data update
		const gatewaysCache = {};
		// check whether perform step 1 - have nodes returned
		if (this.state.nodes.length === 0) {
			return Nodes;
		}

		// step 1 - append rooms, gateways, autosets data
		let transformedNodes = Nodes.map(node => {
			if (node.nodeData) {
				node.nodeData.parsedDetails = Utils.getSafe(() => node.nodeData.details)
					? JSON.parse(node.nodeData.details)
					: null;
			}
			node.status = Utils.getSafe(() => Utils.getStatus(node.nodeData.update_time * 1000));

			let roomNodeIsIn;
			if (Rooms.length > 0) {
				roomNodeIsIn = Rooms.find(room => room.room_id === node.room_id);
			}
			node.room_name = roomNodeIsIn ? roomNodeIsIn.room_name : 'not found';

			node.automation_set_name = Utils.getSafe(() => node.automation_set.automation_set_name)
				? node.automation_set.automation_set_name
				: 'not found';

			node.associated_gateway = Utils.getSafe(() => node.nodeData)
				? this.getAssociatedGatewayDesc(
						node.nodeData.parsedDetails.associated_gateway,
						Gateways,
						gatewaysCache
				  )
				: 'Unknown Gateway';

			const lastSeen = Utils.getSafe(() => node.nodeData.update_time);
			let lastSeenString;
			switch (lastSeen) {
				case undefined:
					lastSeenString = 'Never Seen';
					break;
				// handle case where there is a bug on gw, leading to the data being 0. For now we are considering this 'never seen'
				case 0:
					lastSeenString = 'Never Seen (Zero bug)';
					break;
				default:
					lastSeenString = Utils.getTimeSinceLastSeen(lastSeen);
			}
			node.last_seen_status = lastSeenString;

			node.battery_level = Utils.getSafe(() => node.nodeData.parsedDetails.battery_level)
				? `${Math.round(node.nodeData.parsedDetails.battery_level * 100) / 100}`
				: 'not found';
			// check if SNR and RSSI are NOT NaN, without this, values of 0 get coerced to 'false' which then outputs 'not found', but we actually want to display even when it's 0
			node.last_snr = !isNaN(Utils.getSafe(() => node.nodeData.parsedDetails.last_snr))
				? node.nodeData.parsedDetails.last_snr
				: 'not found';
			node.last_rssi = !isNaN(Utils.getSafe(() => node.nodeData.parsedDetails.last_rssi))
				? node.nodeData.parsedDetails.last_rssi
				: 'not found';
			node.created_time_timestamp = Utils.getSafe(() => node.create_time)
				? node.create_time
				: 'not found';
			node.created_time = Utils.getSafe(() => node.create_time)
				? Utils.getLocaleString(node.create_time)
				: 'not found';

			const bootTime = Utils.getSafe(() => node.nodeData.parsedDetails.boot_time);
			let bootTimeString;
			switch (bootTime) {
				// not found
				case undefined:
					bootTimeString = 'not found';
					break;
				case 0:
					bootTimeString = 'not found (0)';
					break;
				default:
					bootTimeString = Utils.getLocaleString(bootTime);
			}
			node.boot_time = bootTimeString;
			node.alt_model = Utils.getSafe(() => node.model) ? node.model : 'not found';
			node.firmware = Utils.getSafe(() => node.nodeData.parsedDetails.firmware)
				? node.nodeData.parsedDetails.firmware
				: 'not found';
			node.primary_node = Utils.getSafe(() => node.automation_set.primary_node)
				? node.automation_set.primary_node === node.node_id
				: 'not found';
			return node;
		});
		// check whether perform step 2 - have node statistics returned
		if (Object.entries(this.state.nodeJoinStatistic).length === 0) {
			return transformedNodes;
		}

		// step 2 - append nodeJoinStatistics
		transformedNodes = transformedNodes.map(node => {
			node.node_join = this.getNodeJoinStatForNode(node, this.state.nodeJoinStatistic);
			return node;
		});

		return transformedNodes;
	};

	private toggleModal = (nodeId: string, selectedNodeForTransfer: any) => {
		this.setState(prevState => ({
			modal: !prevState.modal,
			nodeId,
			selectedNodeForTransfer: selectedNodeForTransfer,
			errorModal: false,
		}));
	};

	private submitModalData = async (selectedGateway: string) => {
		const reqparams = {
			method: 'POST',
			url: environment.APIaddress + `/autoset-transfer/${environment.APIstage}/update`,
			headers: {
				Authorization: this.state.token,
			},
			data: {
				node_id: this.state.nodeId,
				gateway_id: selectedGateway,
			},
		};
		axios(reqparams)
			.then(data => {
				console.log(data);
				this.setState(() => ({
					modal: false,
				}));
			})
			.catch((err: AxiosError) => {
				this.setState(() => ({
					modal: true,
					errorModal: true,
					errorMessage: err.response && err.response.data ? err.response.data : '',
				}));
			});
	};

	checkForNodeAssociatedGateway = node => {
		// TODO: why need to have greater than 1 for gatewayForLocation
		if (
			this.state.gatewaysForLocation.length > 1 &&
			node.associated_gateway &&
			!node.associated_gateway.includes('V1')
		) {
			return (
				<MoveNodesIcon
					onClick={() => {
						this.toggleModal(node.node_id, node);
					}}
					className={styles['svg-style']}
				/>
			);
		} else {
			return <></>;
		}
	};

	onStatusClick = ({ currentTarget: { id } }) => {
		const nodes = [...this.state.nodes];
		this.setState({ selectedNodeStatus: id });
		if (id === 'All') {
			this.setState({
				filteredNodes: [...this.state.nodes],
			});
		} else {
			const options = {
				shouldSort: true,
				threshold: 0.2,
				location: 0,
				distance: 100,
				maxPatternLength: 32,
				minMatchCharLength: 1,
				keys: ['last_seen_status', 'status'],
			};
			const filteredNodesToUpdate = new Fuse(nodes, options).search(id);
			this.setState({
				filteredNodes: [...filteredNodesToUpdate],
			});
		}
	};

	render() {
		const tableHeader = [
			{ headerName: 'ID', key: 'node_id' },
			{ headerName: 'LAST SEEN', key: 'nodeData.update_time' },
			{ headerName: 'HOTEL ROOM', key: 'room_name' },
			{ headerName: 'NODE TYPE', key: 'type' },
			{ headerName: 'AUTOSET', key: 'automation_set_name' },
			{ headerName: 'LAST JOINED G/W', key: 'associated_gateway' },
			{ headerName: 'RSSI', key: 'last_rssi' },
			{ headerName: 'SNR', key: 'last_snr' },
			{ headerName: 'NODE JOIN', key: 'node_join' },
			{ headerName: 'BATTERY LEVEL', key: 'battery_level' },
			{ headerName: 'AUTOSET ID', key: 'automation_set_id' },
			{ headerName: 'BOOT TIME', key: 'boot_time' },
			{ headerName: 'CREATED ON', key: 'created_time' },
			{ headerName: 'FIRMWARE VERSION', key: 'firmware' },
		];

		const isAdminOrSupport = PermissionUtils.isAdminOrSupport(this.context.cognitoGroups);

		return (
			<>
				<ListViewWrapper
					pageName={'Nodes'}
					tableHeader={tableHeader}
					listData={this.state.filteredNodes}
					refreshData={this.toggleRefreshData}
					addButton={false}
					error={this.state.error}
					loading={this.state.loading}
					isAdminOrSupport={isAdminOrSupport}
					nodeStatus={
						<NodeStatus
							Nodes={this.state.nodes}
							loading={this.state.loading}
							onStatusHandler={this.onStatusClick}
							selectedStatus={this.state.selectedNodeStatus}
						/>
					}
					dataRow={(nodes, columnIdxs) =>
						nodes.map((node, i) => (
							<tr key={i}>
								{Object.entries(node).map(([k, v], idx) => {
									if (columnIdxs[idx]) {
										if (columnIdxs[idx] === 'node_id') {
											return (
												<CustomTd cypress={node.node_id} key={k} index={k}>
													<CodeSnippet allowCopy content={node.node_id}>
														{node.node_id}
														{node.primary_node && node.primary_node !== 'not found' ? (
															<PrimaryNodeIndicator cypress={`${node.node_id}_primary`} />
														) : null}
													</CodeSnippet>
												</CustomTd>
											);
										}

										if (columnIdxs[idx] === 'nodeData.update_time') {
											return (
												<CustomTd key={k} index={k}>
													<StatusIndicator status={node.status} /> {node.last_seen_status}
												</CustomTd>
											);
										}

										if (columnIdxs[idx] === 'associated_gateway') {
											return (
												<CustomTd key={k} index={k}>
													{node.associated_gateway}
													{this.checkForNodeAssociatedGateway(node)}
												</CustomTd>
											);
										}

										if (columnIdxs[idx] === 'nodeData.parsedDetails.last_rssi') {
											return (
												<CustomTd
													key={k}
													index={k}
													style={{ color: CellUtils.getRssiCellColor(node.last_rssi) }}
												>
													{node.last_rssi}
												</CustomTd>
											);
										}

										if (columnIdxs[idx] === 'nodeData.parsedDetails.last_snr') {
											return (
												<CustomTd
													key={k}
													index={k}
													style={{ color: CellUtils.getRssiCellColor(node.last_snr) }}
												>
													{node.last_snr}
												</CustomTd>
											);
										}

										if (columnIdxs[idx] === 'nodeData.parsedDetails.battery_level') {
											return (
												<CustomTd
													key={k}
													index={k}
													style={{ color: CellUtils.getBatteryCellColor(node.battery_level) }}
												>
													{node.battery_level}
												</CustomTd>
											);
										}

										if (columnIdxs[idx] === 'automation_set_id') {
											return (
												<CustomTd key={k} index={k}>
													<CodeSnippet allowCopy content={node.automation_set_id}>
														{node.automation_set_id}
													</CodeSnippet>
												</CustomTd>
											);
										}

										return (
											<CustomTd cypress={columnIdxs[idx]} key={k} index={k}>
												{node[columnIdxs[idx]]}
											</CustomTd>
										);
									}
									return null;
								})}
							</tr>
						))
					}
				/>

				{this.state.nodeId ? (
					<NodeTransferModal
						GatewaysForLocation={this.state.gatewaysForLocation}
						Nodes={this.state.nodes}
						nodeId={this.state.nodeId}
						selectedNodeForTransfer={this.state.selectedNodeForTransfer}
						modal={this.state.modal}
						error={this.state.errorModal}
						errorMessage={this.state.errorMessage}
						toggleModal={this.toggleModal}
						submitModalData={this.submitModalData}
					/>
				) : (
					<></>
				)}
			</>
		);
	}
}

export default ListNodes;
ListNodes.contextType = UserContext;
