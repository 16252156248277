import React from 'react';
import { Spinner } from 'reactstrap';
import styles from './LoadingIndicator.module.css';

interface props {
	style?: any;
}

class LoadingIndicator extends React.Component<props, {}> {
	render() {
		return (
			<div className={styles['div-wrapper']}>
				<Spinner
					style={{ width: '3rem', height: '3rem', ...this.props.style }}
					data-cy='loading'
					color='primary'
				/>
			</div>
		);
	}
}

export default LoadingIndicator;
