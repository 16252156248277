import randomstring from 'randomstring';

export default class Utils {
	/**
	 *
	 * @param {string} verb
	 * @return {string} the verb on past tense
	 */
	static pastTense(verb: string) {
		// source: https://gist.github.com/letsgetrandy/1e05a68ea74ba6736eb5
		const exceptions = {
			are: 'were',
			eat: 'ate',
			go: 'went',
			have: 'had',
			inherit: 'inherited',
			is: 'was',
			run: 'ran',
			sit: 'sat',
			visit: 'visited',
		};
		if (exceptions[verb]) {
			return exceptions[verb];
		}
		if (/e$/i.test(verb)) {
			return verb + 'd';
		}
		if (/[aeiou]c/i.test(verb)) {
			return verb + 'ked';
		}
		// for american english only
		if (/el$/i.test(verb)) {
			return verb + 'ed';
		}
		if (/[aeio][aeiou][dlmnprst]$/.test(verb)) {
			return verb + 'ed';
		}
		if (/[aeiou][bdglmnprst]$/i.test(verb)) {
			return verb.replace(/(.+[aeiou])([bdglmnprst])/, '$1$2$2ed');
		}
		return verb + 'ed';
	}

	/**
	 *
	 * @param {string} text
	 * @return {string} the capitalized text
	 */
	static capitalize(text: string) {
		return text.charAt(0).toUpperCase() + text.substr(1).toLowerCase();
	}

	/**
	 *
	 * @param {number} length
	 * @return {string} the specified length of characters
	 */
	static generateString(length: number): string {
		return randomstring.generate({
			length: length,
			charset: 'hex',
		});
	}

	/**
	 *
	 * @param {string} streamId
	 * @return {string} nodeId
	 */

	static getNodeIdFromStreamId(streamId: string): string {
		return streamId.slice(7, streamId.length - 2);
	}

	/**
	 *
	 * @param {number} index
	 * @return {string} item
	 */

	static getModeTitles(index: number): string {
		return ['Auto', 'Cool', 'Dry', 'Fan'][index];
	}

	/**
	 *
	 * @param {number} index
	 * @return {string} item
	 * */

	static getFanSpeedTitles(index: number): string {
		return ['Auto', 'Low', 'Medium', 'High'][index];
	}

	/**
	 *
	 * @param {string} filteredItem
	 * @param {string} filterKeyword
	 * @return {any} array of item
	 * */

	static filterSearch(filteredItem: string, filterKeyword: string = ''): any {
		if (!filteredItem) {
			return;
		}
		return filteredItem.toLowerCase().includes(filterKeyword.toLowerCase());
	}

	/**
	 *
	 * @param {any} arr
	 * @param {string[]} key
	 * */

	static parseJSONInArrayOfObjects(arr: any[], key: string[] | string) {
		return arr.forEach(obj => {
			return Utils.iterate(obj, key);
		});
	}

	/**
	 *
	 * @param {any} obj
	 * @param {string[] | string} keys
	 * @return {any} Parsed value of the object property
	 * */

	static iterate(obj, keys: string[] | string) {
		for (const property in obj) {
			if (obj.hasOwnProperty(property)) {
				if (typeof obj[property] == 'object') {
					Utils.iterate(obj[property], keys);
				}
				if (Array.isArray(keys)) {
					// keys is an array of string
					keys.forEach(key => {
						parseAndAssignValue(property, key);
					});
				} else {
					// keys is a string
					parseAndAssignValue(property, keys);
				}
			}
		}

		function parseAndAssignValue(property: string, key: string) {
			if (property === key) {
				// check for JSON
				if (typeof obj[property] === 'string') {
					obj[property] = JSON.parse(obj[property]);
				}
			}
		}
		return obj;
	}

	/**
	 *
	 * @param { number } lastSeen
	 * @return { string } status
	 */

	static getStatus(lastSeen: number): string {
		lastSeen = this.getTimeStampInMilliSeconds(lastSeen);
		if (lastSeen >= Date.now() - 1000 * 60 * 30) {
			return 'online';
		} else if (lastSeen < Date.now() - 1000 * 60 * 30) {
			return 'offline';
		} else {
			return 'not found';
		}
	}

	static getStatusBoolean(lastSeen: number): boolean {
		lastSeen = this.getTimeStampInMilliSeconds(lastSeen);
		if (lastSeen >= Date.now() - 1000 * 60 * 30) {
			return true;
		} else {
			return false;
		}
	}

	static getTimeStampInMilliSeconds(date: number): number {
		if (!date) {
			// @ts-ignore
			return;
		}
		if (date.toString().length < 13) {
			date = date * 1000;
		}
		return date;
	}

	/**
	 *
	 * @param { number } date
	 * @return { string } value of date to iso string
	 */

	static getISODate(date: number): string {
		return new Date(date * 1000).toISOString();
	}

	/**
	 *
	 * @param { number } date
	 * @return { string }
	 */

	static getLocaleString(date: number): string {
		if (date.toString().length < 13) {
			date = date * 1000;
		}
		return new Date(date).toLocaleString();
	}

	/*
	 * handles timestamps that are in S or MS
	 */
	static getTimeSinceLastSeen(date: number): string {
		date = this.getTimeStampInMilliSeconds(date);

		let timeElapsed = (Date.now() - date) / 1000; //this in seconds
		let suffix = '';
		let plural = '';
		if (timeElapsed < 60) {
			suffix = 'sec';
		} else if (timeElapsed < 60 * 60) {
			timeElapsed = timeElapsed / 60;
			suffix = 'min';
		} else if (timeElapsed < 60 * 60 * 24) {
			timeElapsed = timeElapsed / 60 / 60;
			suffix = 'hour';
		} else if (timeElapsed < 60 * 60 * 24 * 7) {
			timeElapsed = timeElapsed / 60 / 60 / 24;
			suffix = 'day';
		} else if (timeElapsed < 60 * 60 * 24 * 7 * 4) {
			timeElapsed = timeElapsed / 60 / 60 / 24 / 7;
			suffix = 'week';
		} else if (timeElapsed < 60 * 60 * 24 * 7 * 4 * 12) {
			timeElapsed = timeElapsed / 60 / 60 / 24 / 7 / 4;
			suffix = 'month';
		} else {
			return '> year';
		}
		timeElapsed = Math.floor(timeElapsed);

		if (timeElapsed > 1) plural = 's';
		return `${timeElapsed} ${suffix}${plural} ago`;
	}

	/**
	 * Check nested property has value
	 * @param  {function } fn
	 * @return { function } call the function
	 */

	static getSafe = fn => {
		try {
			return fn();
		} catch (e) {
			return undefined;
		}
	};

	/**
	 *
	 * @param { object } obj
	 * @param { string } desc
	 * @return { object } value of the nested property
	 */

	static getNestedProp(obj, desc) {
		const arr = desc.split('.');
		while (arr.length && (obj = obj[arr.shift()]));
		return obj;
	}

	/**
	 * Check whether string has number
	 * @param {string } number
	 * @return { boolean }
	 * */

	static isNumeric(number): boolean {
		return !isNaN(parseFloat(number)) && isFinite(number);
	}

	/**
	 * Performs a deep copy
	 * @param object The object to copy
	 * @return {any} The copy
	 */
	static deepCopy(object: any) {
		return JSON.parse(JSON.stringify(object));
	}
}
