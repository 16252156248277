import UserRolesCognitoGroups from '../enums/roles';

export default class PermissionUtils {
	/**
	 *
	 * @param { string } roles
	 * @param { string } permissionForLambda
	 * @return { boolean }
	 **/
	static getCognitoGroups(roles: string[], permissionForLambda: string[]): boolean {
		if (!roles.length) {
			return false;
		}
		return roles.some(role => permissionForLambda.includes(role));
	}

	static isAdminOrSupport(roles: string[]): boolean {
		const permittedRoles: string[] = [UserRolesCognitoGroups.Admin, UserRolesCognitoGroups.Support];
		if (!roles.length) {
			return false;
		}
		return roles.some(role => permittedRoles.includes(role));
	}
}
