import React, { useState, useEffect } from 'react';
import { graphqlOperation, API } from 'aws-amplify';
import { Badge, UncontrolledCollapse } from 'reactstrap';

import { listGatewaysByAppIdQuery } from './FirmwareUpdateGraphQL';
import Utils from '../../../../utils';
import FirmwareUpdateCancel from './FirmwareUpdateCancel.component';

import styles from './FirmwareUpdateList.module.css';
import {
	FIRMWARE_UPDATE_STATUS,
	FIRMWARE_UPDATE_SCOPE,
	STATUS_TO_BADGE_MAPPING,
} from '../../../../enums/otaConstants';

interface Props {
	schedule: any;
	// schedule: {
	// 	app_id: string;
	// 	create_time: number;
	// 	firmware_id: string;
	// 	location_id: string;
	// 	location_name: string;
	// 	node_list?: [any];
	// 	update_id: string;
	// 	schedule_id?: string;
	// 	scheduled_time: number;
	// 	scope?: string;
	// 	status: string;
	// 	type: string;
	// 	version?: string;
	// };
}

interface getGatewaysResult {
	data: {
		listGateways: {
			items: any[];
		};
	};
}

const STATUS_TO_SHOW_CANCEL_BUTTON = [
	FIRMWARE_UPDATE_STATUS.SCHEDULED,
	FIRMWARE_UPDATE_STATUS.INPROGRESS,
	FIRMWARE_UPDATE_STATUS.CANCEL_PENDING,
];

export default function FirmwareUpdateListSidebar({ schedule }: Props) {
	// setup state
	const [gateways, setGateways] = useState<any[]>([]);

	const {
		app_id,
		type,
		location_name,
		updates,
		scope,
		node_list,
		// status, // not used - re-calculated for the whole batch
		firmware_id,
		firmware_name,
		schedule_id,
		// version,
		// scheduled_time, // not used - re-calculated for the whole batch
	} = schedule ?? {}; // default sub-params to undefined

	// count number of successful updates
	const successfulUpdatesCount = sumSuccessfulUpdates(updates);

	// wrap the async fetch and set within a helper function, as useEffect shld return nothing or clean up
	useEffect(() => {
		const asyncFetchGwData = async appId => {
			if (appId) {
				const gWs = await getGwWithStatusByAppId(appId);
				// there is a corner case of a race condition here since we can't guarantee that the network request returns in order, but for now leave it
				setGateways(gWs);
			}
		};
		asyncFetchGwData(app_id);
	}, [app_id]);

	if (!schedule) {
		return null;
	}

	const [startTimeInBatch, endTimeInBatch] = getMinMaxTimeInUpdateBatch(updates);
	const statusOfBatch = getStatusOfBatch(updates);

	const hasNodeList =
		scope === FIRMWARE_UPDATE_SCOPE.NODE_MULTIPLE ||
		(scope === FIRMWARE_UPDATE_SCOPE.NODE_SINGLE && node_list.length !== 0);

	return (
		<section className={styles.itemInfo}>
			<h1>{type}</h1>
			<h3>{location_name}</h3>
			<h3>
				{/* this just shows the no of update items, but not necessarily the same as the number of gateways for the location */}
				{updates.length} gateway{updates.length > 1 ? 's' : ''}
			</h3>
			<Badge color={STATUS_TO_BADGE_MAPPING[statusOfBatch]} style={{ marginBottom: '12px' }}>
				{statusOfBatch}
			</Badge>

			<dl>
				<dt>Update details</dt>
				<dd className={styles.smallText}>
					Schedule ID: <code>{schedule_id}</code>
					<br />
					Scope: <code>{scope}</code>
				</dd>
				{hasNodeList && (
					<>
						<dt>Nodes</dt>
						<dd className={styles.smallText}>
							<ul className={styles.nodesList}>
								{node_list.map(node => (
									<li key={node}>{node}</li>
								))}
							</ul>
						</dd>
					</>
				)}
				<dt>Firmware</dt>
				<dd className={styles.smallText}>
					Filename: <code>{firmware_name}</code>
					<br />
					Firmware ID: <code>{firmware_id}</code>
				</dd>
				<dt>Scheduled start time</dt>
				<dd>{Utils.getLocaleString(startTimeInBatch)}</dd>
				<dt>Estimated Completion</dt>
				<dd>{Utils.getLocaleString(endTimeInBatch + 60 * 60 * 1000)}</dd>
			</dl>
			{scope !== 'OTAV1' && (
				<>
					<h2>Progress</h2>
					<p>
						{successfulUpdatesCount} out of {updates.length} gateways updated
					</p>{' '}
					<dl className={styles.updateList}>
						{gateways.length &&
							updates &&
							updates.map(update => {
								const gateway = gateways.find(gateway => gateway.gateway_id === update.gateway_id);

								return (
									<div key={update.update_id} className={styles.update}>
										<dt>Gateway: {gateway?.name}</dt>
										<dd>
											<Badge color={STATUS_TO_BADGE_MAPPING[update.status]}>{update.status}</Badge>
											<br />
											Scheduled time: {Utils.getLocaleString(update.scheduled_time)}
											<br />
											Update ID: <code>{update.update_id}</code>
										</dd>
										{// only show cancel button if scheduled (TODO remove pending after debugging)
										STATUS_TO_SHOW_CANCEL_BUTTON.includes(update.status) && (
											<FirmwareUpdateCancel
												updateId={update.update_id}
												resendCancel={update.status === FIRMWARE_UPDATE_STATUS.CANCEL_PENDING}
											/>
										)}
										{update.ota_result_log && update.ota_result_log !== '{}' && (
											<span className={styles.updateLogLink} id={`collapse-${update.update_id}`}>
												View Log
											</span>
										)}
										{update.ota_result_log && update.ota_result_log !== '{}' && (
											<UncontrolledCollapse toggler={`#collapse-${update.update_id}`}>
												<textarea
													className={styles.updateLog}
													readOnly
													value={update.ota_result_log}
												></textarea>
											</UncontrolledCollapse>
										)}
									</div>
								);
							})}
					</dl>
				</>
			)}
		</section>
	);
}

const sumSuccessfulUpdates = (updates: { status: string }[]) => {
	let count = 0;
	if (updates?.length) {
		count = updates.filter(u => u?.status === FIRMWARE_UPDATE_STATUS.SUCCEEDED).length;
	}
	return count;
};

const getGwWithStatusByAppId = async appId => {
	try {
		const result = (await API.graphql(
			graphqlOperation(listGatewaysByAppIdQuery, {
				filter: {
					app_id: { eq: appId },
				},
			})
		)) as getGatewaysResult;

		// manipulate data
		const gateways = result.data.listGateways.items
			.filter(gateway => !gateway.deleted)
			.map(gateway => {
				return {
					// if last seen is within 30 mins of now, is online
					online: +new Date() - gateway.last_seen < 1000 * 60 * 30,
					name: JSON.parse(gateway.data).balenaDeviceNameAtInit || 'not found',
					gateway_id: gateway.gateway_id,
				};
			});

		return gateways;
	} catch (e) {
		console.error(e);
		return [];
	}
};

const getMinMaxTimeInUpdateBatch = (updates: Array<{ scheduled_time: number }>) => {
	// assume updates.length !== 0
	let min, max;
	min = max = updates[0].scheduled_time;

	if (updates.length > 1) {
		for (const update of updates) {
			max = Math.max(max, update.scheduled_time);
			min = Math.min(min, update.scheduled_time);
		}
	}

	return [min, max];
};

const getStatusOfBatch = (updates: Array<{ status: FIRMWARE_UPDATE_STATUS }>) => {
	// can further improve the logic of this - e.g. what if some pending? some failed? some succeed?
	// right now successful if all successful, else displays the first non-successful status
	let status = FIRMWARE_UPDATE_STATUS.SUCCEEDED;
	for (const u of updates) {
		if (u.status !== FIRMWARE_UPDATE_STATUS.SUCCEEDED) {
			status = u.status;
			break;
		}
	}
	return status;
};
