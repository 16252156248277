import React, { Component } from 'react';
import styles from './modal.module.css';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/icons/close.svg';
import { Button, Modal, ModalBody } from 'reactstrap';
import axios from 'axios';
import environment from '../../../../../environments';
import { Auth } from 'aws-amplify';
import { WithTranslation, withTranslation } from 'react-i18next';

interface Props extends WithTranslation {
	openModal: any;
	modal: boolean;
	balenaName: string;
	gatewayId: string;
	refreshData: any;
	appId: string | null;
	cypress: string;
}

interface State {
	balenaName: string;
	token: string;
}

class UpdateGatewayModal extends Component<Props, State> {
	state: State = {
		balenaName: this.props.balenaName,
		token: '',
	};

	updateBalenaName = e => {
		this.setState({ balenaName: e.target.value });
	};

	async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (prevProps.balenaName !== this.props.balenaName) {
			try {
				const user = await Auth.currentAuthenticatedUser();
				this.setState({
					token: user.signInUserSession.idToken.jwtToken,
					balenaName: this.props.balenaName,
				});
			} catch (e) {
				console.error(e);
			}
		}
	}

	submitForm = e => {
		e.preventDefault();
		this.props.openModal('');
		// check for desc valid
		if (this.state.balenaName && this.state.balenaName.search(/\s/g) > -1) {
			alert('Invalid gateway name');
			console.error('Invalid desc');
			return;
		}

		axios
			.post(
				environment.APIaddress + `/api/${environment.APIstage}/gateways/rename`,
				{
					gateway_name: this.state.balenaName,
					gateway_id: this.props.gatewayId,
					app_id: this.props.appId,
				},
				{
					headers: {
						Authorization: this.state.token,
					},
				}
			)
			.then(response => {
				this.props.refreshData();
			})
			.catch(error => {
				console.log(error);
			});
	};

	render() {
		const { t } = this.props;
		return (
			<Modal
				data-cy={this.props.cypress}
				isOpen={this.props.modal}
				toggle={() => this.props.openModal('')}
			>
				{/*<ModalHeader toggle={this.openModal} className={styles['custom-header']}><p className={styles['modal-header']}>Modal to Another Gateway</p></ModalHeader>*/}
				<div className={styles['custom-header']}>
					<p className={styles['custom-header-text']}>{t('Update Gateway')}</p>
					<CloseIcon
						className={styles['close-icon']}
						onClick={() => {
							this.props.openModal('');
						}}
					/>
				</div>
				<ModalBody className={styles['custom-modal-body']}>
					<form onSubmit={e => this.submitForm(e)}>
						<div className={styles['modal-info']}>
							<label htmlFor='nodeId'>{t('BALENA NAME')}</label>

							<input
								data-cy='update-gateway-input'
								className='form-control'
								name='balenaName'
								id='balenaName'
								type='text'
								value={this.state.balenaName}
								onChange={e => this.updateBalenaName(e)}
							/>
						</div>

						<div style={{ marginTop: '26px' }}>
							<Button
								className={'create-form-button modal-button'}
								color='secondary'
								disabled={this.state.balenaName === this.props.balenaName}
								data-cy='update-gateway-submit-button'
							>
								{t('SUBMIT')}
							</Button>

							<div style={{ marginTop: '24px' }} className={'create-form-cancel'}>
								<p
									onClick={() => {
										this.props.openModal('');
									}}
								>
									{t('CANCEL')}
								</p>
							</div>
						</div>
					</form>
				</ModalBody>
			</Modal>
		);
	}
}

export default withTranslation()(UpdateGatewayModal);
