import React, { Component } from 'react';
import { UserContext } from '../../../../common/Context/UserContext';
import CustomTd from '../../../../common/ListViewWrapper/CustomTd/CustomTd.component';
import ListViewWrapper from '../../../../common/ListViewWrapper/ListViewWrapper.component';
import CodeSnippet from '../../../../common/CodeSnippet/CodeSnippet.component';
import Utils from '../../../../../utils';
import StatusIndicator from '../../../../common/StatusIndicator/StatusIndicator.component';
import { API, graphqlOperation } from 'aws-amplify';
import NodeStatus from '../Nodes/NodeStatusInfo.component';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/edit-pencil.svg';
import { ReactComponent as TrashIcon } from '../../../../../assets/icons/trash-can.svg';
import UpdateGatewayModal from './UpdateGatewayModal.component';
import styles from './ListGateways.module.css';
import ConfirmationModal from '../../../../common/ConfirmationModal/ConfirmationModal.component';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from '../../../../../interface/Route';
import CellUtils from '../../../../../utils/cell-colours';
import PermissionUtils from '../../../../../utils/permission';
import Fuse from 'fuse.js';

interface State {
	loading: boolean;
	gateways: Gateway[];
	filteredGateways: Gateway[];
	selectedGatewayStatus: string;
	error: boolean;
	modalStatus: boolean;
	balenaName: string;
	gatewayId: string;
	modalState: boolean;
	deleteGatewayData: { gateway_id: string; app_id: string; deleted: boolean | null };
}

interface Gateway {
	threadCount: number;
	gatewayMacAddress: number;
	diskSpaceFreeMB: number;
	gateway_id: string;
	status: string;
	last_seen: string;
	wLanMacAddress: string;
	firmwareVersion: string;
	balena_name: string;
	wLanNoise: string;
	ethernetStatus: string;
}

interface Props {
	appId: string | null;
}

interface Props extends RouteComponentProps, WithTranslation {}

const gatewaysQuery = `query GatewaysQuery($app_id: String!) {
	listAuthGatewaysByAppId(app_id: $app_id) {
		alerted
		app_id
		data
		gateway_id
		ip_address
		last_seen
		location_id
		location_name
		deleted
  }
}`;

const gatewaysUpdate = `mutation GatewaysUpdate($gateway_id: String!, $app_id: String!, $deleted: Boolean) {
	updateGateways(input: { app_id: $app_id, gateway_id: $gateway_id, deleted: $deleted }) {
		items {
		    gateway_id
      		app_id	
			deleted
			alerted
		}
		success
	}
}`;

class ListGateways extends Component<Props, State> {
	state: State = {
		loading: false,
		gateways: [],
		filteredGateways: [],
		error: false,
		modalStatus: false,
		selectedGatewayStatus: 'All',
		balenaName: '',
		gatewayId: '',
		modalState: false,
		deleteGatewayData: { gateway_id: '', app_id: '', deleted: null },
	};

	fetchData = async () => {
		this.setState({ loading: true });
		try {
			const response: any = await API.graphql(
				graphqlOperation(gatewaysQuery, {
					app_id: this.props.appId,
				})
			);
			const cloneGateways = Utils.deepCopy(response.data.listAuthGatewaysByAppId);
			// TODO: Handle this on the backend
			const filterDeletedGateways = cloneGateways.filter(gateway => !gateway.deleted);
			const gateways = await this.transformGateways(filterDeletedGateways);
			this.setState(() => ({
				gateways,
				filteredGateways: [...gateways],
				loading: false,
				error: false,
			}));
		} catch (e) {
			this.setState({ error: true, loading: false });
			console.error(e);
		}
	};

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		if (prevProps.appId !== this.props.appId) {
			this.fetchData();
		}
	}

	async componentDidMount() {
		if (this.props.appId) {
			return this.fetchData();
		}
	}

	toggleRefreshData = () => {
		this.setState({ loading: true });
		return this.fetchData();
	};

	transformGateways = gateways => {
		gateways.forEach(gateway => {
			gateway.data =
				Utils.getSafe(() => gateway.data) && typeof gateway.data === 'string'
					? JSON.parse(gateway.data)
					: gateway.data;
			gateway.original_gateway_id = gateway.gateway_id;
			gateway.gateway_id = gateway.gateway_id.slice(0, 6);
			gateway.last_seen_status = Utils.getSafe(() => gateway.last_seen)
				? Utils.getTimeSinceLastSeen(gateway.last_seen)
				: 'not found';
			gateway.status = Utils.getSafe(() => gateway.last_seen)
				? Utils.getStatus(gateway.last_seen)
				: 'not found';
			gateway.wLanMacAddress = Utils.getSafe(() => gateway.data.wLanMacAddress)
				? gateway.data.wLanMacAddress
				: 'not found';
			gateway.firmwareVersion = Utils.getSafe(() => gateway.data.firmware)
				? gateway.data.firmware
				: 'not found';
			gateway.gatewayMacAddress = Utils.getSafe(() => gateway.data.gatewayMacAddress)
				? gateway.data.gatewayMacAddress
				: 'not found';
			gateway.diskSpaceFreeMB = Utils.getSafe(() => gateway.data.diskSpaceFreeMB)
				? gateway.data.diskSpaceFreeMB
				: 'not found';
			gateway.balena_name = Utils.getSafe(() => gateway.data.balenaDeviceNameAtInit)
				? gateway.data.balenaDeviceNameAtInit
				: 'not found';
			gateway.wLanLevel =
				typeof Utils.getSafe(() => gateway.data.wLanLevel) === 'number'
					? gateway.data.wLanLevel
					: 'not found';
			gateway.ethernetStatus = Utils.getSafe(() => gateway.data.ethernetStatus)
				? gateway.data.ethernetStatus
				: 'not found';
			// properties below not currently displayed in the table
			gateway.wLanNumberRetries =
				typeof Utils.getSafe(() => gateway.data.wLanNumberRetries) === 'number'
					? gateway.data.wLanNumberRetries
					: 'not found';
			gateway.threadCount = Utils.getSafe(() => gateway.data.threadCount)
				? gateway.data.threadCount
				: 'not found';
		});
		return gateways;
	};

	private openModal = (balenaName: string, gatewayId: string) => {
		this.setState(prevState => ({
			modalStatus: !prevState.modalStatus,
			balenaName,
			gatewayId,
		}));
	};

	deleteGateway = async () => {
		try {
			await API.graphql(
				graphqlOperation(gatewaysUpdate, {
					gateway_id: this.state.deleteGatewayData.gateway_id,
					app_id: this.state.deleteGatewayData.app_id,
					deleted: this.state.deleteGatewayData.deleted,
				})
			);
			this.toggleRefreshData();
		} catch (e) {
			console.error(e);
		}
	};

	toggleModal = gateway => {
		this.setState({
			modalState: !this.state.modalState,
			deleteGatewayData: {
				gateway_id: gateway.original_gateway_id,
				app_id: gateway.app_id,
				deleted: true,
			},
		});
	};

	confirmClick = () => {
		this.deleteGateway();
		this.setState({ modalState: !this.state.modalState });
	};

	cancelClick = () => {
		this.setState({
			modalState: !this.state.modalState,
			deleteGatewayData: { gateway_id: '', app_id: '', deleted: null },
		});
	};

	onStatusClick = ({ currentTarget: { id } }) => {
		const gateways = [...this.state.gateways];
		this.setState({ selectedGatewayStatus: id });
		if (id === 'All') {
			this.setState({
				filteredGateways: [...this.state.gateways],
			});
		} else {
			const options = {
				shouldSort: true,
				threshold: 0.2,
				location: 0,
				distance: 100,
				maxPatternLength: 32,
				minMatchCharLength: 1,
				keys: ['last_seen_status', 'status'],
			};
			const filteredGatewaysToUpdate = new Fuse(gateways, options).search(id);
			this.setState({
				filteredGateways: [...filteredGatewaysToUpdate],
			});
		}
	};

	render() {
		const { t } = this.props;

		const tableHeader = [
			{ headerName: 'ID', key: 'gateway_id' },
			{ headerName: 'BALENA NAME', key: 'balena_name' },
			{ headerName: 'LAST SEEN', key: 'last_seen' },
			{ headerName: 'MAC ADDRESS', key: 'gatewayMacAddress' },
			{ headerName: 'WLAN MAC ADDRESS', key: 'wLanMacAddress' },
			{ headerName: 'FREE SPACE', key: 'diskSpaceFreeMB' },
			{ headerName: 'ETHERNET', key: 'ethernetStatus' },
			{ headerName: 'WIFI SIGNAL STRENGTH', key: 'wLanLevel' },
			{ headerName: 'IP ADDRESS', key: 'ip_address' },
			{ headerName: 'VERSION', key: 'firmwareVersion' },
		];

		const isAdminOrSupport = PermissionUtils.isAdminOrSupport(this.context.cognitoGroups);
		return (
			<>
				<ListViewWrapper
					pageName={'Gateways'}
					tableHeader={tableHeader}
					listData={this.state.filteredGateways}
					addButton={true}
					addButtonLink={'/create-gateway'}
					addButtonText={'Set Up Gateway'}
					refreshData={this.toggleRefreshData}
					error={this.state.error}
					loading={this.state.loading}
					isAdminOrSupport={isAdminOrSupport}
					nodeStatus={
						<NodeStatus
							Nodes={this.state.gateways}
							loading={this.state.loading}
							onStatusHandler={this.onStatusClick}
							selectedStatus={this.state.selectedGatewayStatus}
						/>
					}
					dataRow={(displayedGateways, columnIdxs) =>
						displayedGateways.map((gateway, i) => {
							if (gateway.deleted !== true) {
								return (
									<tr key={gateway.gateway_id}>
										{Object.entries(gateway).map(([k, v], idx) => {
											if (columnIdxs[idx]) {
												if (columnIdxs[idx] === 'gateway_id') {
													return (
														<CustomTd key={k} index={k}>
															<CodeSnippet allowCopy content={gateway.original_gateway_id}>
																{gateway.gateway_id}
															</CodeSnippet>
														</CustomTd>
													);
												}

												if (columnIdxs[idx] === 'balena_name') {
													return (
														<CustomTd key={k} index={k}>
															{gateway.balena_name}
															<EditIcon
																data-cy={`update-${gateway.gateway_id}`}
																className={`svg-spacing`}
																style={{ cursor: 'pointer' }}
																onClick={() =>
																	this.openModal(gateway.balena_name, gateway.original_gateway_id)
																}
																id={`Tooltip-${i}`}
															/>
															<TrashIcon
																data-cy={`delete-${gateway.gateway_id}`}
																className={`svg-spacing ${styles['trash-icon']}`}
																onClick={() => this.toggleModal(gateway)}
																style={{ cursor: 'pointer' }}
															/>
														</CustomTd>
													);
												}

												if (columnIdxs[idx] === 'last_seen') {
													return (
														<CustomTd key={k} index={k}>
															<StatusIndicator status={gateway.status} /> {gateway.last_seen_status}
														</CustomTd>
													);
												}

												if (columnIdxs[idx] === 'wLanNoise') {
													return (
														<CustomTd
															key={k}
															index={k}
															style={{
																color: CellUtils.getWifiSignalStrength(
																	gateway.ethernetStatus === 'up' ? 'N/A' : gateway.wLanLevel
																),
															}}
														>
															{gateway.ethernetStatus === 'up' ? 'N/A' : gateway.wLanLevel}
														</CustomTd>
													);
												}

												return (
													<CustomTd key={k} index={k}>
														{gateway[columnIdxs[idx]]}
													</CustomTd>
												);
											}
											return null;
										})}
									</tr>
								);
							}
							return null;
						})
					}
				/>
				<UpdateGatewayModal
					cypress='update-modal'
					openModal={this.openModal}
					balenaName={this.state.balenaName}
					gatewayId={this.state.gatewayId}
					appId={this.props.appId}
					modal={this.state.modalStatus}
					refreshData={this.toggleRefreshData}
				/>
				<ConfirmationModal
					openModal={this.toggleModal}
					title={t('DELETE GATEWAY')}
					message={t('Are you sure you want to delete the gateway?')}
					modal={this.state.modalState}
					onConfirm={this.confirmClick}
					onCancel={this.cancelClick}
				/>
			</>
		);
	}
}

export default withTranslation()(ListGateways);
ListGateways.contextType = UserContext;
