import React, { useState } from 'react';
import {
	ButtonDropdown,
	DropdownMenu,
	DropdownToggle,
	Form,
	FormGroup,
	Input,
	Label,
} from 'reactstrap';
import { ReactComponent as ColumnsIcon } from '../../../../assets/icons/manage-columns.svg';
import styles from './ManageColumnsToggle.module.css';
import { useTranslation } from 'react-i18next';

function getFromLocalStorage(title, index): boolean {
	// @ts-ignore
	const getColumnFromLocalStorage = JSON.parse(localStorage.getItem('tableSettings'));
	if (getColumnFromLocalStorage && getColumnFromLocalStorage[title]) {
		return getColumnFromLocalStorage[title][index].checked;
	}
	return true;
}

interface Props {
	title: string;
	tableHeader: any[];
	getColumnHeader: (header) => void;
}

interface Header {
	checked: boolean;
	id: number;
	key: string;
	headerName: string;
}

const ManageColumnsToggle: React.FC<Props> = (props): JSX.Element => {
	const setHeaderInitialState = (name, i): Header => {
		return {
			checked: getFromLocalStorage(props.title, i),
			id: i,
			key: name.key,
			headerName: name.headerName,
		};
	};

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [headers, setHeader] = useState<Header[]>(props.tableHeader.map(setHeaderInitialState));
	const { t } = useTranslation();

	const toggleCheckbox = (e, i) => {
		setHeader([
			...headers.slice(0, i),
			Object.assign(headers[i], { id: i, checked: !headers[i].checked }),
			...headers.slice(i + 1),
		]);

		props.getColumnHeader(headers);
	};

	const toggleAllCheckbox = () => {
		setHeader(
			headers.map(newHeader => {
				newHeader.checked = true;
				return newHeader;
			})
		);

		props.getColumnHeader(headers);
	};

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	return (
		<ButtonDropdown data-cy='manage-column' isOpen={isOpen} toggle={toggle} className={'px-2'}>
			<DropdownToggle color={'light'}>
				<ColumnsIcon style={{ margin: '0 0.4em 0.1em 0' }} />
				{t('MANAGE COLUMNS')}
			</DropdownToggle>
			<DropdownMenu className={'table-dropdown'}>
				<Form>
					<FormGroup check className={styles['row-items']} onClick={toggleAllCheckbox}>
						<Label
							style={{ fontWeight: 600 }}
							className={`d-inline-block w-100 ${styles['label-custom']}`}
							check
						>
							<Input
								type='checkbox'
								name='cb'
								id='cb'
								data-cy='show-all'
								onChange={toggleAllCheckbox}
								checked={headers.every(head => head.checked)}
							/>
							{t('SHOW ALL')}
						</Label>
					</FormGroup>
					{headers.map((header, i) => {
						return (
							<FormGroup check key={i} className={styles['row-items']}>
								<Label className={`d-inline-block w-100 ${styles['label-custom']}`} check>
									<Input
										type='checkbox'
										data-cy='manage-column-input'
										data-cy-name={header.headerName}
										checked={headers[i].checked}
										onChange={e => toggleCheckbox(e, i)}
									/>
									{t(`${header.headerName}`)}
								</Label>
							</FormGroup>
						);
					})}
				</Form>
			</DropdownMenu>
		</ButtonDropdown>
	);
};

export default ManageColumnsToggle;
