import React from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import FirmwareUpload from './FirmwareUpload/FirmwareUpload.component';
import styles from './Firmware.module.css';
import FirmwareDocuments from './FirmwareDocuments/FirmwareDocuments.component';

interface State {
	activeTab: string;
}

class Firmware extends React.Component<{}, State> {
	state: State = {
		activeTab: '1',
	};

	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	};

	setClassname = (check: boolean): string => {
		return check ? [styles['nav-link-active'], styles['tab-style']].join(' ') : styles['tab-style'];
	};

	render() {
		return (
			<div className={styles['container']}>
				<Nav tabs>
					<NavItem>
						<NavLink
							data-cy='firsTab'
							className={this.setClassname(this.state.activeTab === '1')}
							onClick={() => this.toggle('1')}
						>
							All Files
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							data-cy='secondTab'
							className={this.setClassname(this.state.activeTab === '2')}
							onClick={() => this.toggle('2')}
						>
							Upload File
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
					<TabPane tabId='1'>
						<Row>
							<Col sm='12'>
								<FirmwareDocuments />
							</Col>
						</Row>
					</TabPane>
					<TabPane tabId='2'>
						<Row>
							<Col>
								<FirmwareUpload />
							</Col>
						</Row>
					</TabPane>
				</TabContent>
			</div>
		);
	}
}

export default Firmware;
