import React, { FunctionComponent, useState } from 'react';
import copyToClipboard from 'copy-text-to-clipboard';
import { Tooltip } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import styles from './CodeSnippet.module.css';
import copyIcon from '../../../assets/icons/icons8-copy-48.png';

interface Props {
	content: string;
	allowCopy: boolean;
}

const TOOLTIP_OPEN_TIME = 2000; // 3 seconds

const CodeSnippet: FunctionComponent<Props> = ({ allowCopy, content, children }) => {
	// create a unique id to associate the tooltip
	const [tooltipTargetId] = useState(`tooltip-${uuid()}`);
	const [tooltipOpen, setTooltipOpen] = useState(false);

	const handleCopy = () => {
		copyToClipboard(content);
		setTooltipOpen(true);
		setTimeout(() => {
			setTooltipOpen(false);
		}, TOOLTIP_OPEN_TIME);
	};

	return (
		<>
			<code className={styles.codeSnippet} title={content}>
				{children}
			</code>
			{allowCopy && (
				<>
					{' '}
					<img
						className={styles.copyIcon}
						src={copyIcon}
						onClick={handleCopy}
						id={tooltipTargetId}
						title='Copy to clipboard'
						alt='Copy to clipboard'
					/>
					<Tooltip className='copyTooltip' isOpen={tooltipOpen} target={tooltipTargetId}>
						Copied!
					</Tooltip>
				</>
			)}
		</>
	);
};

export default CodeSnippet;
