import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import GatewayParent from './CreateGateway/GatewayParent.component';
import CreateGatewayLoadingScreen from './CreateGateway/CreateGatewayLoadingScreen.component';
import CreateGatewayComplete from './CreateGateway/CreateGatewayComplete.component';
import Location from './Location/Location.component';
import TestingUpdates from './TestingUpdates/TestingUpdates.component';
import ListUsers from './User/ListUsers.component';
import User from './User/User.component';
import Room from './Room/Room.component';
import LaunchOta from './FirmwareUpdate/FirmwareUpdateList.component';
import Firmware from './Firmware/Firmware.component';
import FirmwareUpdateSchedule from './FirmwareUpdate/FirmwareUpdateSchedule/FirmwareUpdateSchedule.component';
import RouteAuthorizer from '../../common/authorization/RouteAuthorizer.component';
import ManageLocation from './Location/ManageLocation.component';
import ListLocations from './ListLocations/ListLocations.component';
import Error404 from '../../common/Error404/Error404.component';
import ACModelForm from './ACModel/ACModelForm.component';
import ListACModel from './ACModel/ListACModel.component';

import styles from './MainPage.module.css';

class MainPage extends Component<any> {
	render() {
		return (
			<div className={styles.mainPage}>
				<RouteAuthorizer>
					<Switch>
						<Route exact path='/' component={ListLocations} />
						<Route exact path='/manage-location/:location_id' component={ManageLocation} />
						<Route exact path='/create-gateway' component={GatewayParent} />
						<Route exact path='/locations' component={ListLocations} />
						<Route exact path='/locations/:location_id' component={Location} />
						<Route exact path='/locations/:location_id/room/:room_id' component={Room} />
						{/* remove this testing-path when done */}
						<Route path='/testing-updates' component={TestingUpdates} />
						<Route path='/testing-loading' component={CreateGatewayLoadingScreen} />
						<Route path='/testing-complete' component={CreateGatewayComplete} />
						<Route exact path='/firmware-updates' component={LaunchOta} />
						<Route exact path='/firmware-updates/schedule' component={FirmwareUpdateSchedule} />
						<Route exact path='/firmware-updates/firmware-files' component={Firmware} />
						<Route exact path='/users' component={ListUsers} />
						<Route exact path='/users/:cognito_id' component={User} />
						<Route exact path='/ac-model' component={ListACModel} />
						<Route exact path='/ac-model/new' component={ACModelForm} />
						<Route exact path='/ac-model/:id' component={ACModelForm} />
						<Route component={Error404} />
					</Switch>
				</RouteAuthorizer>
			</div>
		);
	}
}

export default MainPage;
