import React from 'react';
import { Button, Modal } from 'reactstrap';
import styles from './ConfirmationModal.module.css';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { useTranslation } from 'react-i18next';

interface Props {
	openModal: any;
	title: string;
	message: string;
	modal: boolean;
	onConfirm: (e) => void;
	onCancel: (e) => void;
}

const ConfirmationModal: React.FC<Props> = (props: Props): JSX.Element => {
	const { t } = useTranslation();

	return (
		<div>
			<Modal
				data-cy='confirm-modal'
				centered={true}
				isOpen={props.modal}
				toggle={props.openModal}
				className={'center-modal'}
				contentClassName={'content-class'}
				cssModule={{ backgroundColor: 'blue' }}
			>
				<div className={styles['custom-header']}>
					<p className={styles['custom-header-text']}>{props.title}</p>
					<CloseIcon
						className={styles['close-icon']}
						onClick={() => {
							props.openModal('');
						}}
					/>
				</div>
				<div className={styles['modal-body']}>
					<p>{props.message}</p>
				</div>
				<div className={styles['modal-footer']}>
					<Button
						value='confirm'
						color='secondary'
						className={styles['confirm-button']}
						onClick={e => props.onConfirm(e)}
					>
						{t("YES, I'M SURE")}
					</Button>{' '}
					<Button
						value='cancel'
						color={'light'}
						className={styles['cancel-button']}
						onClick={e => props.onCancel(e)}
					>
						{t('NO')}
					</Button>
				</div>
			</Modal>
		</div>
	);
};

export default ConfirmationModal;
