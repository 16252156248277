import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Input, Label, Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal.component';
import { RouteComponentProps } from '../../../../interface/Route';
import { API, graphqlOperation } from 'aws-amplify';
import { createACModel, updateACModel } from './ACModelQueries';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ModalActions } from '../../../../enums/confirmationModal';

interface Props extends RouteComponentProps {
	history: {
		location: {
			state: {
				type: 'UPDATE' | undefined;
				id: number | undefined;
				brandName: string;
				modelName: string;
			};
		};
		push: any;
	};
}

interface ACValue {
	ac_model_id: number | undefined;
	ac_brand: string;
	ac_model: string;
}

const ACModelForm: React.FC<Props> = (props: Props): JSX.Element => {
	const type = props.history?.location?.state?.type ?? '';
	const [modalState, setModalState] = useState<boolean>(false);
	const [ACValue, setACValue] = useState<ACValue>({
		ac_model_id: undefined,
		ac_brand: '',
		ac_model: '',
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [modalActions, setModalActions] = useState<number | null>(null);
	const { t } = useTranslation();

	const message = (type): JSX.Element => {
		const term = type === 'UPDATE' ? 'updated' : 'added';
		return (
			<Alert>
				{t(`${ACValue.ac_model} has been ${term} successfully.`)}{' '}
				<Link to={'/ac-model'} className='alert-link'>
					{t('View All AC Models')}
				</Link>
			</Alert>
		);
	};

	const isFormDirty: boolean = !!(ACValue.ac_model_id || ACValue.ac_brand || ACValue.ac_model);
	const isValidForm: boolean = Boolean(ACValue.ac_model_id && ACValue.ac_brand && ACValue.ac_model);
	const title: string = type === 'UPDATE' ? 'Update' : 'Add';

	const onIdChange = e => {
		setACValue({ ...ACValue, ac_model_id: e.target.value });
		setSubmitted(false);
	};
	const onBrandChange = e => {
		setACValue({ ...ACValue, ac_brand: e.target.value });
		setSubmitted(false);
	};
	const onModelChange = e => {
		setACValue({ ...ACValue, ac_model: e.target.value });
		setSubmitted(false);
	};

	const toggleModal = (action: ModalActions) => {
		setModalActions(action);
		setModalState(!modalState);
	};

	const cancel = () => {
		if (isFormDirty) {
			toggleModal(ModalActions.Cancel);
		} else {
			props.history.push('/ac-model');
		}
	};

	const onSubmitForm = e => {
		e.preventDefault();
		toggleModal(ModalActions.Submit);
	};

	const submit = async () => {
		setLoading(true);
		try {
			type === 'UPDATE'
				? await API.graphql(
						graphqlOperation(updateACModel, {
							input: {
								ac_model_id: ACValue.ac_model_id,
								ac_brand: ACValue.ac_brand.trim(),
								ac_model: ACValue.ac_model.trim(),
							},
						})
				  )
				: await API.graphql(
						graphqlOperation(createACModel, {
							input: {
								ac_model_id: ACValue.ac_model_id,
								ac_brand: ACValue.ac_brand.trim(),
								ac_model: ACValue.ac_model.trim(),
							},
						})
				  );
			setLoading(false);
			setSubmitted(true);
		} catch (e) {
			setLoading(false);
			setError(true);
			console.error(e);
		}
	};

	const confirmClick = () => {
		if (modalActions === ModalActions.Submit) {
			submit();
			setModalState(!modalState);
		}

		if (modalActions === ModalActions.Cancel) {
			props.history.push('/ac-model');
		}
	};

	const cancelClick = () => {
		setModalState(!modalState);
	};

	useEffect(() => {
		if (type === 'UPDATE') {
			const { id, brandName, modelName } = props.history.location.state;
			setACValue({ ac_model_id: id, ac_brand: brandName, ac_model: modelName });
		}
	}, [props.history.location.state, type]);

	return (
		<>
			<div className='form-wrapper container'>
				{submitted ? (
					message(type)
				) : error ? (
					<Alert color='danger-alert'>{t('Some error occurred - please contact admin')}</Alert>
				) : null}
				<h1 className='create-form-title'>{title} AC Model</h1>
				<Row>
					<Col xs='3' />
					<Col xs='6'>
						<Form data-cy='ac-model-form' onSubmit={onSubmitForm}>
							<div className='create-form-card'>
								<div className='d-flex flex-column'>
									<div className='list-group-item'>
										<div className='create-form-group'>
											<Label htmlFor='ac-id' className='col-6'>
												{t('AC MODEL ID')}
											</Label>
											<br />
											<Input
												data-cy='ac-id'
												type='number'
												className='form-control col-12'
												id='ac-id'
												value={ACValue.ac_model_id}
												disabled={type === 'UPDATE'}
												onChange={onIdChange}
											/>
										</div>
									</div>
									<div className='list-group-item'>
										<div className='create-form-group'>
											<Label htmlFor='ac-id' className='col-6'>
												{t('BRAND NAME')}
											</Label>
											<br />
											<Input
												data-cy='ac-id'
												type='text'
												className='form-control col-12'
												value={ACValue.ac_brand}
												id='ac-id'
												onChange={onBrandChange}
											/>
										</div>
									</div>
									<div className='list-group-item'>
										<div className='create-form-group'>
											<Label htmlFor='sac-id' className='col-6'>
												{t('MODEL NAME')}
											</Label>
											<br />
											<Input
												data-cy='ac-id'
												type='text'
												className='form-control col-12'
												value={ACValue.ac_model}
												id='ac-id'
												onChange={onModelChange}
											/>
										</div>
									</div>
								</div>
							</div>
							<div className='d-flex align-items-center flex-column'>
								<Button
									type='submit'
									data-cy='user-form-button'
									color='secondary'
									disabled={!isValidForm || loading}
								>
									{t(`${loading ? 'LOADING...' : 'SUBMIT'}`)}
								</Button>
								<Button color='borderless' onClick={cancel} disabled={loading}>
									{t('CANCEL')}
								</Button>
							</div>
						</Form>
					</Col>
					<Col xs='3' />
				</Row>
			</div>
			<ConfirmationModal
				openModal={toggleModal}
				title={t(
					modalActions === ModalActions.Submit
						? type === 'UPDATE'
							? 'UPDATE AC MODEL'
							: 'CREATE NEW AC MODEL'
						: 'CONFIRM CANCELLATION'
				)}
				message={t(
					modalActions === ModalActions.Submit
						? 'Are you sure you want to submit?'
						: 'All unsaved changes would be lost. Are you sure you want to cancel?'
				)}
				modal={modalState}
				onConfirm={confirmClick}
				onCancel={cancelClick}
			/>
		</>
	);
};

export default ACModelForm;
