import React from 'react';
import { AutomationSetStreams } from '../../../../../../interface/AutomationSetStreams';
import { AutomationSet } from '../../../../../../interface/AutomationSet';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import RoomStyles from '../../Room.module.css';
import { ConnectionStatus } from '../../RoomDevice.component';
import { DeviceStreamStatus } from '../../../../../../interface/DeviceStreamStatus';
import Utils from '../../../../../../utils';
import DeviceStream from './DeviceStream/DeviceStream.component';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { AirconDataFromAutomationSet } from '../RoomAutomationSet.component';
import AirconSettingsDisplay from './AirconSettingsDisplay/AirconSettingsDisplay';

interface AirconDataFromDeviceStream {
	automation_status: string;
	fan_speed: number;
	guest_overidden: boolean;
	guest_setpoint: number;
	measurement_setpoint: number;
	mode: number;
	powered: boolean;
	speed: number;
	stream_id: string;
	stream_type: string;
	temperature_set: number;
	vanne: number;
}

interface Props {
	automationStream: AutomationSetStreams;
	airconStreamData: AirconDataFromAutomationSet;
	autoset: AutomationSet;
	getConnection: boolean;
	locationId: string;
}

class AutomationSetDevice extends React.Component<Props, any> {
	render() {
		const onCreateDeviceStreamStatus = `subscription OnCreateDeviceStreamStatus ($stream_id: String) {
      onCreateDeviceStreamStatus(stream_id: $stream_id) {
        stream_id,
        data
      }
    }`;

		const { automationStream, airconStreamData, autoset, getConnection } = this.props;
		return (
			<Card className={RoomStyles.cardLayout}>
				<CardHeader className={RoomStyles.deviceTitle}>
					Aircon: {automationStream.device_name}
					<ConnectionStatus getConnection={getConnection} />
				</CardHeader>
				<CardBody className={RoomStyles.cardBodyLayout}>
					<Row>
						<Col className={RoomStyles.airconLayout} md='6'>
							{getConnection ? (
								<Connect
									subscription={graphqlOperation(onCreateDeviceStreamStatus, {
										stream_id: automationStream.stream_id,
									})}
									onSubscriptionMsg={(prevData, { onCreateDeviceStreamStatus: newStatus }) => {
										return newStatus;
									}}
								>
									{({
										data,
										loading,
										error,
									}: {
										data: DeviceStreamStatus;
										loading: boolean;
										error: boolean;
									}) => {
										if (error) return <h3>Error</h3>;
										if (loading) return <h3>Loading...</h3>;
										const hasNewData = data.data && data.timestamp > airconStreamData.timestamp;
										const getLatestData: AirconDataFromDeviceStream = hasNewData
											? JSON.parse(data.data)
											: null;
										const mode = hasNewData ? getLatestData.mode : airconStreamData.mode;
										const temperature = hasNewData
											? getLatestData.temperature_set
											: airconStreamData.temperature;
										const speed = hasNewData ? getLatestData.fan_speed : airconStreamData.speed;
										const power = hasNewData ? getLatestData.powered : airconStreamData.power;
										return (
											<AirconSettingsDisplay
												locationId={this.props.locationId}
												automtionStream={automationStream}
												mode={mode}
												temperature={temperature}
												speed={speed}
												power={power}
											/>
										);
									}}
								</Connect>
							) : (
								<h2>You are offline</h2>
							)}
						</Col>
						{autoset.automation_stream.map(stream => {
							const deviceData = JSON.parse(stream.data);
							if (
								Utils.getNodeIdFromStreamId(stream.stream_id) ===
								Utils.getNodeIdFromStreamId(automationStream.stream_id)
							) {
								return (
									<DeviceStream
										key={stream.stream_id}
										stream={stream}
										getConnection={getConnection}
										deviceData={deviceData}
									/>
								);
							}
							return null;
						})}
					</Row>
				</CardBody>
			</Card>
		);
	}
}

export default AutomationSetDevice;
