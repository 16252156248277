// Should not use test2 env
const test2 = {
	aws_project_region: 'ap-southeast-1',
	aws_cognito_identity_pool_id: 'ap-southeast-1:ac62e8d0-5eec-4e41-bc0f-c1e38430ea11',
	aws_cognito_region: 'ap-southeast-1',
	aws_user_pools_id: 'ap-southeast-1_hbShD9zfV',
	aws_user_pools_web_client_id: 'hgrdk1br5l5f6vhitab5gpjkq',
	aws_appsync_graphqlEndpoint:
		'https://mcp2osaqqfaphabt4clc2bhfde.appsync-api.ap-southeast-1.amazonaws.com/graphql',
	aws_appsync_region: 'ap-southeast-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	APIaddress: 'https://test2-api.sensorflow.org',
	APIstage: 'test2',
};

export default test2;
