// contains functions for data fetching and manipulation
import { API, graphqlOperation } from 'aws-amplify';
import { FILE_STATUS } from '../../../../../enums/otaConstants';
import Utils from '../../../../../utils';
import { listGatewaysByAppIdQuery, listNodesByLocationIdQuery } from '../FirmwareUpdateGraphQL';

interface getGatewaysResult {
	data: {
		listGateways: {
			items: any[];
		};
	};
}

export interface gatewaysState {
	gatewaysAll: any[];
	gatewaysOffline: any[];
	gatewaysOnline: any[];
	gatewaysByMac: {};
}

export interface nodeWithSelectedFields {
	associatedGatewayId: string;
	firmwareVersion: string;
	gatewayMac: string;
	lastUpdateTime: number;
	nodeId: string;
	online: boolean;
	roomName: string;
	type: string;
}

export const asyncGetGatewaysByAppId = async (app_id: string) => {
	// if no app id, return empty
	if (!app_id) {
		return [];
	}
	try {
		const result = (await API.graphql(
			graphqlOperation(listGatewaysByAppIdQuery, {
				filter: {
					app_id: { eq: app_id },
				},
			})
		)) as getGatewaysResult;
		return result.data.listGateways.items;
	} catch (e) {
		console.error(e); // TODO - set error in state here? or in function that calls this
		return [];
	}
};

export const asyncGetNodesByLocationId = async (location_id: string) => {
	if (!location_id) {
		return [];
	}

	try {
		const result = await API.graphql(
			graphqlOperation(listNodesByLocationIdQuery, {
				location_id,
			})
		);
		return result.data.listNodesByLocation.nodes;
	} catch (e) {
		// need to pass error upwards
		console.error(e);
		return {};
	}
};

export const processNodesData = (nodesData: any[], gatewaysByMac: {}): {} => {
	// TODO: think about what data we want for the node... id/last seen/type/firmware version? room name and autoset name?

	// Transformation 1 - make an array with the fields we want
	const processedNodesArray = nodesData
		// filter out nodes that have never connected, we don't know which gw they are associated with
		.filter(node => node?.nodeData !== null && node?.nodeData?.details !== null)
		// process data into only the fields we need
		.map((node: any): nodeWithSelectedFields | null => {
			if (node?.nodeData?.details) {
				const processedNodeDetailsData = JSON.parse(node.nodeData.details);

				const gatewayMac = processedNodeDetailsData.associated_gateway;

				// get the gateway id from gatewaysByMac
				const associatedGatewayId = gatewaysByMac[gatewayMac]?.gateway_id;

				return {
					// if last seen is within 30 mins of now, is online
					associatedGatewayId,
					firmwareVersion: processedNodeDetailsData.firmware,
					gatewayMac,
					lastUpdateTime: node.nodeData.update_time,
					nodeId: node.node_id,
					online: Utils.getStatusBoolean(
						Utils.getTimeStampInMilliSeconds(node.nodeData.update_time)
					),
					roomName: node.room.room_name,
					type: node.type,
				};
			}
			return null;
		});

	const processedNodesObject = {};
	// Transformation 2 - transform this array into an object
	processedNodesArray.forEach(node => {
		const gatewayId = node?.associatedGatewayId;
		if (!gatewayId) {
			return;
		}
		// if object doesn't have the gateway id key, init it as an empty array
		if (!processedNodesObject.hasOwnProperty(gatewayId)) {
			processedNodesObject[gatewayId] = [];
		}
		// same for the type key
		if (!processedNodesObject[gatewayId].hasOwnProperty(node?.type)) {
			processedNodesObject[gatewayId][node?.type] = [];
		}
		// then push to it
		processedNodesObject[gatewayId][node?.type].push(node);
	});
	return processedNodesObject;
};

export const processGatewayData = (gatewaysData: any[]): gatewaysState => {
	// process the raw data into gatewaysAll
	const gatewaysAll = gatewaysData
		// filter out soft-deleted gateways
		.filter(gateway => !gateway.deleted)
		.map(gateway => {
			const gatewayData = JSON.parse(gateway.data);
			return {
				// if last seen is within 30 mins of now, is online
				online: Utils.getStatusBoolean(gateway.last_seen),
				name: gatewayData.balenaDeviceNameAtInit || 'not found',
				gateway_id: gateway.gateway_id,
				data: gatewayData,
			};
		})
		.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

	// create online and offline
	const gatewaysOffline = gatewaysAll.filter(g => g?.online !== true);
	const gatewaysOnline = gatewaysAll.filter(g => g?.online);

	// gatewaysByMac is a cache object to help us filter the nodes by gatewayMacAddress, because the nodes returned data doesn't have gateway_id
	const gatewaysByMac = {};
	gatewaysAll.forEach(g => {
		if (g?.data?.gatewayMacAddress) {
			// assign to object, with mac address as key, and gateway as value
			// we can do more manipulation here if we want
			gatewaysByMac[g.data.gatewayMacAddress] = g;
		}
	});

	return {
		gatewaysAll,
		gatewaysOffline,
		gatewaysOnline,
		gatewaysByMac,
	};
};

export const filterRelevantFirmware = (firmwares: any[], type: string) => {
	return firmwares
		.filter(firmware => {
			return (
				(firmware.status === FILE_STATUS.AVAILABLE || firmware.status === FILE_STATUS.REPLACED) &&
				firmware.type === type
			);
		})
		.map(firmware => {
			return {
				label: `${firmware.name} (Version: ${firmware.version})`,
				value: firmware.firmware_id,
			};
		});
};

export const checkIfAllowSelectionOfNodes = (
	nodesDataForLocation: {},
	selectedGateways: any[],
	selectedType: string
): boolean => {
	// if no nodes, no type, or no selected gateways
	if (
		Object.keys(nodesDataForLocation).length === 0 ||
		!selectedType ||
		selectedGateways.length === 0
	) {
		return false;
	}
	// also don't deal with all gateways
	const selectedGatewayId = selectedGateways[0].value;
	if (selectedGatewayId === 'ALL') {
		return false;
	}
	// temporary, for now only support one gateway
	if (selectedGateways.length > 1) {
		return false;
	}
	return true;
};

export const filterNodesBasedOnGatewaySelection = (
	nodesDataForLocation: {},
	selectedGateways: any[],
	selectedType: string
): [] => {
	// map types
	// TODO refactor this into constants file
	const selectedTypeInFormToNodeDataTypeEnum = {
		'2PFC': 'aircon',
		ACIR: 'aircon',
		DAIKIN: 'aircon',
		BTU: 'btu',
		DOOR: 'door',
		EMON: 'energy',
		OCCUPANCY: 'occupancy',
		PIPE_TEMP: 'pipeMonitor',
		FLOW_METER: 'flowMeter',
	};

	// TODO: this needs to become an array if we want to support nodes across multiple gateway
	const selectedGatewayId = selectedGateways[0].value;

	const nodesInGateway = nodesDataForLocation[selectedGatewayId];
	const selectedTypeTranslated = selectedTypeInFormToNodeDataTypeEnum[selectedType];

	// if selected gateway has no nodes, or the selected type is not in the map enum
	if (!nodesInGateway || !selectedTypeTranslated) {
		return [];
	}

	const nodesOfTypeInGateway = nodesInGateway[selectedTypeTranslated];

	// if no nodes of this type in this gateway
	if (!nodesOfTypeInGateway) {
		return [];
	}

	const nodeOptions = nodesOfTypeInGateway.map(node => {
		const { nodeId, online, firmwareVersion, roomName, type } = node;
		return {
			label: `${nodeId} - ${
				online ? 'Online' : 'Offline'
			} - Type: ${type} - FW version: ${firmwareVersion} - Room: ${roomName}`,
			value: nodeId,
			firmwareVersion,
			nodeId,
			roomName,
			online,
			type,
		};
	});

	return nodeOptions;
};
