import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Button, Toast, ToastBody, ToastHeader } from 'reactstrap';
import { updateFirmwareUpdatesV2, UpdateFirmwareUpdatesInputV2 } from './FirmwareUpdateGraphQL';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal.component';

import styles from './FirmwareUpdateList.module.css';

interface Props {
	updateId: string;
	resendCancel: boolean;
}

const CLOSE_TOAST_TIME_MILLISECONDS = 6000;

const FirmwareUpdateCancel = (props: Props) => {
	const { resendCancel } = props;
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [showToast, setShowToast] = useState<boolean>(false);
	const [showModal, setShowModal] = useState<boolean>(false);

	// start a timer for the toast when success changes
	useEffect(() => {
		const toastTimer = setTimeout(() => {
			if (success) {
				closeToast();
			}
			console.log('timer running');
		}, CLOSE_TOAST_TIME_MILLISECONDS);
		return () => clearTimeout(toastTimer);
	}, [success]);

	const closeToast = () => {
		setShowToast(false);
	};

	const toggleModal = () => {
		setShowModal(!showModal);
	};

	const cancelFirmwareUpdate = async () => {
		// TODO remove this after done debugging
		setLoading(true);
		setShowModal(false);

		try {
			const input: UpdateFirmwareUpdatesInputV2 = {
				status: 'CANCEL_PENDING',
				update_id: props.updateId,
			};

			const result = await API.graphql(graphqlOperation(updateFirmwareUpdatesV2, { input }));
			console.log(result);
			setLoading(false);
			setError(false);
			setSuccess(true);
			setShowToast(true);
		} catch (e) {
			setLoading(false);
			setError(true);
			console.error(e);
		}
	};

	return (
		<>
			<Button
				className={styles.cancelButton}
				outline
				size='sm'
				color='danger'
				onClick={toggleModal}
				disabled={loading}
			>
				{loading && 'Please wait...'}
				{!loading && (resendCancel ? 'Resend Cancel' : 'Cancel Update')}
			</Button>
			<ConfirmationModal
				openModal={toggleModal}
				title={resendCancel ? 'Confirm Resend Cancel' : 'Confirm Cancel Update'}
				message={
					resendCancel
						? 'Are you sure you want to resend the cancel update command?'
						: 'Are you sure you want to cancel this firmware update? This cannot be undone.'
				}
				modal={showModal}
				onConfirm={cancelFirmwareUpdate}
				onCancel={toggleModal}
			/>
			<Toast isOpen={showToast} className={styles.toast}>
				<ToastHeader toggle={closeToast} icon='success'>
					{resendCancel ? 'Resend Successful' : 'Cancel Successful'}
				</ToastHeader>
				<ToastBody>
					{resendCancel
						? 'Cancel command was successfully resent.'
						: 'Firmware update for gateway was successfully cancelled.'}
				</ToastBody>
			</Toast>
			<Toast isOpen={error} className={styles.toast}>
				<ToastHeader icon='danger'>Error cancelling update</ToastHeader>
				<ToastBody>
					A backend error occured when trying to cancel this update. Most likely it is because this
					update ID <code>{props.updateId}</code> was not found in the database. There may be more
					details in the console. <br /> Please try again or contact support.
				</ToastBody>
			</Toast>
		</>
	);
};

export default FirmwareUpdateCancel;
