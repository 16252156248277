import dev1 from './dev1';
import test2 from './test2';
import prod from './prod';
import glenn from './glenn';
import test1 from './test1';
import ReactAppEnvValues from './IReactAppEnv';
import dev3 from './dev3';

export enum SensorflowEnvironment {
	'dev1' = 'dev1',
	'dev3' = 'dev3',
	'test2' = 'test2',
	'test1' = 'test1',
	'production' = 'production', // legacy mapping because CI pipeline still using flag as "production"
	'glenn' = 'glenn', // have not shifted Glenn's env over for React app build
}

type ReactAppEnvironments = { [key in SensorflowEnvironment]: ReactAppEnvValues };

// add to mapping when you populate new environments
export const environments: ReactAppEnvironments = {
	dev1: dev1,
	dev3: dev3,
	test2: test2,
	test1: test1,
	production: prod,
	glenn: glenn,
};
