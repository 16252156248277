import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import ReactSelect from 'react-select';
import { withRouter, generatePath } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';

import styles from './QuickNavigateLocations.module.css';

const listLocationsQuery = /* GraphQL */ `
	query listLocationsQuery {
		listLocations {
			items {
				location_id
				location_name
			}
		}
	}
`;

const QuickNavigateLocation = props => {
	const [allLocations, setAllLocations] = useState([]);
	const [loading, setLoading] = useState(false);
	const [currentlySelectedLocation, setCurrentlySelectedLocation] = useState<object | null>({});

	const fetchAllLocations = async () => {
		setLoading(true);
		try {
			const result = await API.graphql(graphqlOperation(listLocationsQuery));
			const allLocations = result.data.listLocations.items.sort((a: any, b: any) =>
				a.location_name.localeCompare(b.location_name)
			);
			setAllLocations(allLocations);
			setLoading(false);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		fetchAllLocations();
	}, []);

	// basically get the location id from the path, if it changes
	// this lets us catch cases of user manually navigating to those pages, or if user has navigated to other routes
	useEffect(() => {
		const pathNameSplit = props.location.pathname.split('/');
		// locations data not loaded yet
		if (allLocations.length === 0) {
			return;
		}

		if (pathNameSplit.length === 3 && pathNameSplit[1] === 'locations') {
			const currentLocation: any = allLocations.find(
				(location: any) => location.location_id === pathNameSplit[2]
			);
			if (currentLocation) {
				setCurrentlySelectedLocation({
					label: currentLocation.location_name,
					value: currentLocation.location_id,
				});
			}
		} else {
			setCurrentlySelectedLocation(null);
		}
	}, [props.location.pathname, allLocations]);

	const quickNavigateToLocation = selection => {
		setCurrentlySelectedLocation(selection);

		const { value } = selection;
		if (!value) {
			return null;
		}
		props.history.push({
			pathname: generatePath('/locations/:location_id', { location_id: value }),
		});
	};

	if (!loading)
		return (
			<div className={styles.quickNavigateLocationContainer}>
				<ReactSelect
					className={styles.quickNavigateLocation}
					options={allLocations.map((location: any) => {
						return {
							label: location.location_name,
							value: location.location_id,
						};
					})}
					onChange={selection => quickNavigateToLocation(selection)}
					placeholder='Location'
					isClearable={false}
					value={currentlySelectedLocation}
				/>
			</div>
		);
	return <Skeleton />;
};

export default withRouter(QuickNavigateLocation);
