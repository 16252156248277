import React, { useState, useEffect } from 'react';
import { Connect } from 'aws-amplify-react';
import { v4 as uuid } from 'uuid';
import { graphqlOperation, API } from 'aws-amplify';
import {
	createFirmwareUpdates,
	CreateFirmwareUpdatesInputV2,
	listLocationsAndFirmwares,
} from '../FirmwareUpdateGraphQL';

import LoadingIndicator from '../../../../common/LoadingIndicator/LoadingIndicator.component';
import ErrorIndicator from '../../../../common/ErrorIndicator/ErrorIndicator.component';
import FirmwareUpdateScheduleForm from './FirmwareUpdateScheduleForm.component';
import { OTA_STAGGER_TIME_MS, FIRMWARE_UPDATE_SCOPE } from '../../../../../enums/otaConstants';
import {
	asyncGetGatewaysByAppId,
	asyncGetNodesByLocationId,
	processGatewayData,
	processNodesData,
	gatewaysState,
} from './FirmwareUpdateSchedule.data';

import styles from './FirmwareUpdateSchedule.module.css';

const FirmwareUpdateSchedule = () => {
	// setup state
	const [gateways, setGateways] = useState<gatewaysState>({
		gatewaysAll: [],
		gatewaysOffline: [],
		gatewaysOnline: [],
		gatewaysByMac: {},
	});
	const [gwLoading, setGwLoading] = useState(false);
	const [location, setLocation] = useState({ app_id: '', location_id: '', location_name: '' });
	const [nodesDataForLocation, setnodesDataForLocation] = useState({});
	const [nodesLoading, setNodesLoading] = useState(false);
	const [error, setError] = useState(false);
	const [errorMessages, setErrorMessages] = useState<any>([]);
	const [scheduling, setScheduling] = useState(false);
	const [success, setSuccess] = useState(false);

	// query gateways and nodes
	useEffect(() => {
		// workaround for not being able to declare an async fuction in useEffect
		// TODO: find out implications of this approach
		async function getGatewaysAndNodes() {
			//gateways
			setGwLoading(true);
			const gatewaysData = await asyncGetGatewaysByAppId(location.app_id);
			const { gatewaysAll, gatewaysOffline, gatewaysOnline, gatewaysByMac } = processGatewayData(
				gatewaysData
			);
			setGateways({
				gatewaysAll,
				gatewaysOffline,
				gatewaysOnline,
				gatewaysByMac,
			});
			setGwLoading(false);

			// nodes
			setNodesLoading(true);
			const nodesData = await asyncGetNodesByLocationId(location.location_id);
			// if no gateways by mac, skip processing node data
			const processedNodesData =
				Object.keys(gatewaysByMac).length > 0 ? processNodesData(nodesData, gatewaysByMac) : {};
			setnodesDataForLocation(processedNodesData);
			setNodesLoading(false);
		}

		getGatewaysAndNodes();
	}, [location]);

	// TODO: refactor this into data file
	const scheduleFirmwareUpdates = () => {
		return async (formInput: {
			location_id: string;
			firmware_id: string;
			type: string;
			scheduled_time: number;
			scope: FIRMWARE_UPDATE_SCOPE;
			node_list: string[];
			gateway_list: string[];
			version: string;
		}) => {
			setError(false);
			setSuccess(false);
			setErrorMessages([]);
			setScheduling(true);

			console.log(formInput);

			let gatewaysToLoopOver: string[] = [];
			switch (formInput.scope) {
				case FIRMWARE_UPDATE_SCOPE.LOCATION_SINGLE:
					// all gateways in the location, just the ids
					gatewaysToLoopOver = gateways.gatewaysAll.map(gateway => gateway.gateway_id);
					break;
				case FIRMWARE_UPDATE_SCOPE.GATEWAY_SINGLE:
				case FIRMWARE_UPDATE_SCOPE.GATEWAY_MULTIPLE:
				case FIRMWARE_UPDATE_SCOPE.NODE_SINGLE:
				case FIRMWARE_UPDATE_SCOPE.NODE_MULTIPLE:
					// gateways selected by user
					gatewaysToLoopOver = formInput.gateway_list;
					break;
				default:
					console.error(`Error`, 'invalid scope');
					setError(true);
					setErrorMessages(['Invalid scope, this is probably a problem with the form']);
			}

			const schedule_id = uuid(); // generate a schedule_id which will remain constant for all gateways in this schedule

			// loop over gateways and set up schedule
			const allSchedulingRequestsPromises = gatewaysToLoopOver.map(
				async (gatewayId: string, index) => {
					const update_id = uuid(); // generate a unique update_id for each gateway
					const staggeredScheduleTime = formInput.scheduled_time + OTA_STAGGER_TIME_MS * index;

					const input: CreateFirmwareUpdatesInputV2 = {
						update_id,
						schedule_id,
						gateway_id: gatewayId,
						location_id: formInput.location_id,
						firmware_id: formInput.firmware_id,
						scope: formInput.scope,
						status: 'PENDING', // hard coded for now
						type: formInput.type,
						scheduled_time: staggeredScheduleTime,
						version: '0', // default to 0, not being used in V2
						node_list: formInput.node_list,
					};
					// try {
					return API.graphql(graphqlOperation(createFirmwareUpdates, { input }));
					// } catch (error) {
					// 	// catch errors individually and pass them to the form as a prop for display
					// 	console.error(`Error`, error);
					// 	setError(true);
					// 	if (error.errors?.length > 0) {
					// 		error.errors.forEach(error => {
					// 			setErrorMessages([...errorMessages, `${error.errorType} - ${error.message}`]);
					// 		});
					// 	}
					// 	throw error;
					// }
				}
			);

			// TODO: add catch here to catch if a single one errors
			await Promise.all(allSchedulingRequestsPromises)
				.then(responses => {
					console.log({ responses });
					console.log(
						'successfully scheduled all firmware updates! or I could be lying, you see this because all the promises have been resolved'
					);
					setScheduling(false);
					setSuccess(true);
				})
				.catch(error => {
					// handle error
					console.error(error);
				});
		};
	};

	return (
		<div style={{ paddingTop: '2em', paddingBottom: '4em' }}>
			<h1 className={styles.pageHeader}>Schedule Firmware Update</h1>
			<Connect query={graphqlOperation(listLocationsAndFirmwares)}>
				{({ data: { listLocations, listFirmwares }, loading, errors }) => {
					if (errors.length) {
						return <ErrorIndicator errorMsg={errors} />;
					}
					if (loading) {
						return <LoadingIndicator />;
					}

					const firmwares = listFirmwares.items;
					const locations = listLocations.items;

					return (
						<FirmwareUpdateScheduleForm
							error={error}
							errorMessages={errorMessages}
							firmware={firmwares}
							gateways={gateways}
							gwLoading={gwLoading}
							loading={loading}
							location={location}
							locations={locations}
							nodesDataForLocation={nodesDataForLocation}
							nodesLoading={nodesLoading}
							onSubmit={scheduleFirmwareUpdates()}
							scheduling={scheduling}
							setLocation={setLocation}
							success={success}
						/>
					);
				}}
			</Connect>
		</div>
	);
};

export default FirmwareUpdateSchedule;
