export const FIRMWARE_DISPLAY_TYPES: Array<{ value: string; label: string }> = [
	{ value: '2PFC', label: '2PFC' },
	{ value: 'ACIR', label: 'ACIR' },
	{ value: 'BTU', label: 'BTU' },
	{ value: 'DAIKIN', label: 'Daikin' },
	{ value: 'DOOR', label: 'Door' },
	{ value: 'EMON', label: 'Energy Monitor' },
	{ value: 'OCCUPANCY', label: 'Occupancy' },
	{ value: 'PIPE_TEMP', label: 'Pipe Temperature' },
	{ value: 'FLOW_METER', label: 'Flow Meter'}
];

export enum FIRMWARE_UPDATE_STATUS {
	'PENDING' = 'PENDING', // means created from Dashboard, but not yet acknowledged by Gateway
	'SCHEDULED' = 'SCHEDULED', // means acknowledged by Gateway, but has update has not been performed
	'INPROGRESS' = 'INPROGRESS', // means gateway has indicated that the update is in progress
	'SUCCEEDED' = 'SUCCEEDED', // means update successfully completed
	'FAILED' = 'FAILED', // means update completed but with errors
	'CANCEL_PENDING' = 'CANCEL_PENDING', // means that the update has been cancelled from the Dashboard
	'CANCELLED' = 'CANCELLED', // means that the update has been cancelled from the Dashboard (option to do so in OTA V-next)
}

export enum FIRMWARE_UPDATE_SCOPE {
	'LOCATION_SINGLE' = 'LOCATION_SINGLE',
	'GATEWAY_SINGLE' = 'GATEWAY_SINGLE',
	'GATEWAY_MULTIPLE' = 'GATEWAY_MULTIPLE',
	'NODE_SINGLE' = 'NODE_SINGLE',
	'NODE_MULTIPLE' = 'NODE_MULTIPLE',
}

export enum FILE_STATUS {
	'AVAILABLE' = 'AVAILABLE',
	'REPLACED' = 'REPLACED',
}

export const SCOPE_DESCRIPTIONS = {
	OTAV1: 'OTA V1 (all gateways)',
	LOCATION_SINGLE: 'Location (All Gateways)',
	GATEWAY_SINGLE: 'Single Gateway',
	GATEWAY_MULTIPLE: 'Multiple Gateways',
	NODE_SINGLE: 'Single Node',
	NODE_MULTIPLE: 'Multiple Nodes',
};

export enum STATUS_TO_BADGE_MAPPING {
	'PENDING' = 'secondary', // means created from Dashboard, but not yet acknowledged by Gateway
	'SCHEDULED' = 'secondary', // means acknowledged by Gateway, but has update has not been performed
	'INPROGRESS' = 'info', // means gateway has indicated that the update is in progress
	'SUCCEEDED' = 'success', // means update successfully completed
	'FAILED' = 'danger', // means update completed but with errors
	'CANCEL_PENDING' = 'warning',
	'CANCELLED' = 'danger', // means that the update has been cancelled from the Dashboard (option to do so in OTA V-next)
}

export const OTA_STAGGER_TIME_MS = 60 * 60 * 1000; // 1hr

export const OTA_LEAD_TIME_MS = 5 * (1000 * 60); // minimum time that must elapse between now and the deployment time // 5 mins for testing // 30 mins in production
