import React from 'react';
import { Button, Container, Input, Row, Table, Spinner, Alert } from 'reactstrap';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { UserContext } from '../../../../common/Context/UserContext';
import environment from '../../../../../environments';
import { Prompt } from 'react-router-dom';
import styles from './FirmwareUpload.module.css';
import { graphqlOperation, API } from 'aws-amplify';
import { listFirmwares } from './FirmwareUploadQuery';
import { IUserDetails } from '../../../../../interface/User';
import { FIRMWARE_DISPLAY_TYPES, FILE_STATUS } from '../../../../../enums/otaConstants';

interface FilesUploaded {
	file: File;
	version: string;
	filename: string;
	type: string;
	uploaded_by: string;
}

interface State {
	files: FilesUploaded[];
	versionInput: string;
	typeInput: string;
	token: string;
	error: boolean;
	errorMsg: string;
	uploaded: boolean;
	loading: boolean;
	// fileExists: boolean;
	repeatedFiles: any;
}

class FirmwareUpload extends React.Component<{}, State> {
	state: State = {
		files: [],
		versionInput: '',
		typeInput: '',
		token: '',
		error: false,
		errorMsg: '',
		uploaded: true,
		loading: false,
		// fileExists: false,
		repeatedFiles: [],
	};

	private userDetails: IUserDetails = this.context.userDetails;
	private cognito_id = this.userDetails.username;

	async componentDidMount() {
		try {
			const user = await Auth.currentAuthenticatedUser();

			this.setState({
				token: user.signInUserSession.idToken.jwtToken,
			});
		} catch (error) {
			console.error('Error fetching token');
			this.setState({ error: true });
		}
	}

	submit = async e => {
		this.setState({
			error: false,
			errorMsg: '',
		});
		e.preventDefault();
		let isValid = true; //checks only for existing files with the same name
		const repeatedFiles: string[] = [];
		await Promise.all(
			this.state.files.map(async file => {
				if (!(await this.validateFile(file.filename))) {
					isValid = false;
					repeatedFiles.push(`"${file.filename}"`);
				}
			})
		);
		this.setState({ repeatedFiles });
		if (!isValid) {
			const confirmOverwrite = window.confirm(
				`There are existing file(s) with the same name: \n${this.state.repeatedFiles} \nDo you wish to overwrite?`
			);
			if (confirmOverwrite) {
				this.uploadToS3();
			}
		} else {
			this.uploadToS3();
		}
	};

	upload = e => {
		const file: File = e.target.files[0];
		//ensure same file is not uploaded multiple times
		if (file) {
			if (
				this.state.files.some(uploadedFile => {
					return uploadedFile.filename === file.name;
				})
			) {
				alert('Files cannot have the same name');
			} else {
				const fileDetails: FilesUploaded = {
					file,
					filename: file.name,
					version: this.state.versionInput,
					type: this.state.typeInput,
					uploaded_by: this.cognito_id,
				};
				this.setState({ files: [...this.state.files, fileDetails], uploaded: false });
			}
		}
	};

	uploadToS3 = () => {
		this.setState({ loading: true });
		const formData = new FormData();

		this.state.files.forEach(item => {
			formData.append('file', item.file, item.file.name);
		});

		formData.append('details', JSON.stringify(this.state.files));
		axios
			.post(
				environment.APIaddress + `/firmware-documents/${environment.APIstage}/upload`,
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data', Authorization: this.state.token },
				}
			)
			.then(result => {
				console.log(result);
				this.setState({ uploaded: true, loading: false });
			})
			.catch(err => {
				console.error(JSON.stringify(err, null, 2));
				this.setState({
					uploaded: false,
					loading: false,
					error: true,
					errorMsg:
						'Unable to upload firmware. Check that the total number of uploaded firmwares does not exceed 20.',
				});
			});
	};

	// conditional checks:
	// 1. no other file with same name
	//2. if there is such a file, prompt user whether to overwrite

	// TODO: can further optimise, this is fetching the entire FW list on every validate file
	validateFile = async (name: string) => {
		const firmwares: any = await API.graphql(graphqlOperation(listFirmwares));
		console.log(firmwares);
		const isValid = !firmwares.data.listFirmwares.items.some(file => {
			return (
				file.name === name &&
				(file.status === FILE_STATUS.AVAILABLE || file.status === FILE_STATUS.REPLACED)
			);
		});

		return isValid;
	};

	onVersionChange = (e, i) => {
		this.setState({
			files: [
				...this.state.files.slice(0, i),
				Object.assign(this.state.files[i], { version: e.target.value }),
				...this.state.files.slice(i + 1),
			],
		});
	};

	onTypeChange = (e, i) => {
		this.setState({
			files: [
				...this.state.files.slice(0, i),
				Object.assign(this.state.files[i], { type: e.target.value }),
				...this.state.files.slice(i + 1),
			],
		});
	};

	isDisabled = () => {
		const checkForEmptyValues: boolean = this.state.files.every(file => {
			return Object['values'](file).every(details => Boolean(details));
		});
		return !this.state.files.length || !checkForEmptyValues || this.state.loading;
	};

	dismiss = () => {
		this.setState({
			error: false,
		});
	};

	remove = uploadedFile => {
		this.setState({
			files: this.state.files.filter(file => file !== uploadedFile),
		});
	};

	render() {
		return (
			<Container>
				{/* <dialog open={this.state.fileExists && !this.state.uploaded}>
					{`There are existing file(s) with the same name: \n${this.state.repeatedFiles} \nDo you wish to overwrite?`}
					<Button onClick={this.uploadToS3}>Yes</Button>
					<Button onClick={undefined}>No</Button>
				</dialog> */}

				<Container>
					<br />
					{/* temporary notice while the 20 FW limit exists */}
					<h6>
						Please check that total number of uploaded Firmwares does not exceed 20 (page does not
						currently support pagination). Delete old ones if you wish to add more.
					</h6>
					<br />
					<Prompt
						when={!this.state.uploaded}
						message={
							'Are you sure you want to leave? Some files have not been uploaded to the server'
						}
					/>

					<h3>Upload Firmware File</h3>
					{this.state.error && (
						<Alert color='danger' toggle={this.dismiss}>
							Error: {this.state.errorMsg}
						</Alert>
					)}
					<Row className={styles['input-file-row']}>
						<Input data-cy='input' type='file' name='file' onChange={this.upload} />
					</Row>
					<Row>
						<Table>
							<thead>
								<tr>
									<th>Name</th>
									<th>Version</th>
									<th>Type</th>
									<th>Size</th>
									<th />
								</tr>
							</thead>
							{this.state.files.map((uploadedFile: any, i) => {
								return (
									<React.Fragment key={i}>
										<tbody>
											<tr>
												<td data-cy='filename'>{uploadedFile.filename}</td>
												<td>
													<Input
														type='number'
														value={this.state.files[i].version}
														onChange={e => this.onVersionChange(e, i)}
														name='version'
														min='1'
														required
													/>
												</td>
												<td>
													<Input
														value={this.state.files[i].type}
														onChange={e => this.onTypeChange(e, i)}
														type='select'
														name='select'
														required
													>
														<option value=''>---Choose a type---</option>
														{FIRMWARE_DISPLAY_TYPES.map(fw => (
															<option key={fw.value} value={fw.value}>
																{fw.label}
															</option>
														))}
													</Input>
												</td>
												<td>{uploadedFile.file.size}</td>
												<td>
													{this.state.loading ? (
														<Spinner size='sm' color='primary' />
													) : this.state.uploaded ? (
														<span>Uploaded</span>
													) : (
														<Button
															size='sm'
															color='danger'
															onClick={() => this.remove(uploadedFile)}
														>
															Remove
														</Button>
													)}
												</td>
											</tr>
										</tbody>
									</React.Fragment>
								);
							})}
						</Table>

						<Button
							onClick={this.submit}
							className={styles['submit-button']}
							color='primary'
							disabled={this.isDisabled()}
						>
							{this.state.loading ? 'Uploading...' : 'Submit'}
						</Button>
					</Row>

					{/* {this.state.fileExists? <FirmwareUploadModal />: <></>}  */}
				</Container>
			</Container>
		);
	}
}

export default FirmwareUpload;
FirmwareUpload.contextType = UserContext;
