import React, { Component } from 'react';
import styles from './ListNodes.module.css';
import { ReactComponent as CloseIcon } from '../../../../../../src/assets/icons/close.svg';
import { Button, Modal, ModalBody } from 'reactstrap';
import Utils from '../../../../../utils';
import { WithTranslation, withTranslation } from 'react-i18next';
import ErrorIndicator from '../../../../common/ErrorIndicator/ErrorIndicator.component';

interface Props extends WithTranslation {
	modal: boolean;
	Nodes: any[];
	GatewaysForLocation: any[];
	toggleModal: any;
	nodeId: string;
	submitModalData: any;
	selectedNodeForTransfer: any;
	error: boolean;
	errorMessage: string;
}

interface State {
	gwStatus: boolean;
	selectedGateway: string;
}

class NodeTransferModal extends Component<Props, State> {
	state = {
		gwStatus: true,
		selectedGateway: '',
	};

	componentDidMount(): void {
		const checkNodeData =
			this.props.selectedNodeForTransfer.nodeData &&
			this.props.selectedNodeForTransfer.nodeData.parsedDetails &&
			this.props.selectedNodeForTransfer.nodeData.parsedDetails.associated_gateway;
		this.setState({
			selectedGateway: checkNodeData
				? this.props.selectedNodeForTransfer.nodeData.parsedDetails.associated_gateway
				: '',
		});
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
		const checkNodeData =
			this.props.selectedNodeForTransfer.nodeData &&
			this.props.selectedNodeForTransfer.nodeData.parsedDetails &&
			this.props.selectedNodeForTransfer.nodeData.parsedDetails.associated_gateway;
		if (prevProps.selectedNodeForTransfer.node_id !== this.props.selectedNodeForTransfer.node_id) {
			this.setState({
				selectedGateway: checkNodeData
					? this.props.selectedNodeForTransfer.nodeData.parsedDetails.associated_gateway
					: '',
			});
		}
	}

	private changeGatewayMac = e => {
		//check status of the chosen gateway
		const index = e.nativeEvent.target.selectedIndex;
		const gwLastSeen = e.nativeEvent.target[index].id; //obtain gatewayLastSeen property stored as id attribute on the selected <option>
		if (Utils.getStatus(gwLastSeen) === 'online') {
			this.setState({
				gwStatus: true,
			});
		} else {
			this.setState({
				gwStatus: false,
			});
		}
		this.setState({ selectedGateway: e.target.value });
	};

	private onSubmit = () => {
		this.props.submitModalData(this.state.selectedGateway);
		this.setState({
			selectedGateway: '',
		});
	};

	private onToggle = () => {
		this.props.toggleModal('');
		this.setState({
			selectedGateway: '',
		});
	};

	render() {
		const { t } = this.props;
		return (
			<Modal
				isOpen={this.props.modal}
				toggle={() => this.onToggle()}
				contentClassName={styles['content-height']}
			>
				{/*<ModalHeader toggle={this.openModal} className={styles['custom-header']}><p className={styles['modal-header']}>Modal to Another Gateway</p></ModalHeader>*/}
				<div className={styles['custom-header']}>
					<p className={styles['custom-header-text']}>Move to Another Gateway</p>
					<CloseIcon
						className={styles['close-icon']}
						onClick={() => {
							this.props.toggleModal('');
						}}
					/>
				</div>
				<ModalBody className={styles['custom-modal-body']}>
					<div style={{ marginTop: '10px', marginBottom: '10px' }}>
						<span>
							{t('Please ensure that both node and gateway are online to avoid any errors')}.
						</span>
					</div>

					<div className={styles['modal-info']}>
						<label htmlFor='nodeId'>NODE ID</label>

						<input
							className='form-control'
							name='nodeId'
							id='nodeId'
							type='text'
							value={
								this.props.selectedNodeForTransfer.node_id +
								' (Last seen: ' +
								this.props.selectedNodeForTransfer.last_seen_status +
								')'
							}
							disabled
						/>
					</div>
					<div className={styles['modal-info']}>
						<label htmlFor='nodeType'>{t('NODE TYPE')}</label>

						<input
							className='form-control'
							name='nodeType'
							id='nodeType'
							type='text'
							value={this.props.selectedNodeForTransfer.type}
							disabled
						/>
					</div>
					<div className={styles['modal-info']}>
						<label htmlFor='automationSet'>{t('AUTOMATION SET NAME')}</label>

						<input
							className='form-control'
							name='automationSet'
							id='automationSet'
							type='text'
							value={this.props.selectedNodeForTransfer.automation_set_name}
							disabled
						/>
					</div>
					<div className={styles['modal-info']}>
						<label htmlFor='roomName'>{t('ROOM NAME')}</label>

						<input
							className='form-control'
							name='roomName'
							id='roomName'
							type='text'
							value={this.props.selectedNodeForTransfer.room_name}
							disabled
						/>
					</div>

					<div className={styles['modal-info']}>
						<label htmlFor='selectedGateway'>{t('SELECT GATEWAY')}</label>
						<select
							className='form-control'
							id='selectedGateway'
							name='selectedGateway'
							value={this.state.selectedGateway}
							onChange={this.changeGatewayMac}
						>
							<option key='blank' value='' disabled>
								{t('Select One')}
							</option>

							{Utils.getSafe(() =>
								this.props.GatewaysForLocation.map(gw => {
									return (
										<option key={gw.gateway_id} id={gw.last_seen} value={gw.data.gatewayMacAddress}>
											{gw.data.balenaDeviceNameAtInit} {'(' + Utils.getStatus(gw.last_seen) + ')'}
										</option>
									);
								})
							)}
						</select>
					</div>

					<div style={{ marginTop: '8px' }}>
						<span className={styles['modal-error-text']} hidden={this.state.gwStatus}>
							{t(`Warning: The gateway selected seems to be offline. Updates cannot occur until the
							gateway is online`)}
							.
						</span>
					</div>

					<div style={{ marginTop: '8px' }}>
						{this.props.error ? (
							<ErrorIndicator
								errorMsg={
									this.props.errorMessage ? this.props.errorMessage : `Error transferring node`
								}
							/>
						) : null}
					</div>
					<div style={{ marginTop: '26px' }}>
						<Button
							className={'create-form-button modal-button'}
							color='secondary'
							onClick={() => this.onSubmit()}
							disabled={this.state.selectedGateway === '' || this.props.nodeId === ''}
						>
							{t('SUBMIT')}
						</Button>

						<div style={{ marginTop: '24px' }} className={'create-form-cancel'}>
							<p
								onClick={() => {
									this.props.toggleModal('');
								}}
							>
								{t('CANCEL')}
							</p>
						</div>
					</div>
				</ModalBody>
			</Modal>
		);
	}
}

export default withTranslation()(NodeTransferModal);
