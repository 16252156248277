import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { API, graphqlOperation } from 'aws-amplify';
import CreateGatewayForm from './CreateGatewayForm.component';
import { RouteComponentProps } from '../../../../interface/Route';

interface Props extends RouteComponentProps {
	onSubmit: (location: { location_id: string; description: string }) => any;
	clicked: boolean;
}

const listLocationsQuery = `
	query listLocationsQuery {
		listLocations {
			items {
				location_id
				location_name
			}
		}
	}
`;

interface Locations {
	location_id: string;
	location_name: string;
}

const CreateGateway: React.FC<Props> = props => {
	const [locations, setLocations] = useState<Locations[]>([]);

	const fetchLocations = async () => {
		try {
			const result = await API.graphql(graphqlOperation(listLocationsQuery));
			// @ts-ignore
			setLocations(result.data.listLocations.items);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		fetchLocations();
	}, []);

	return (
		<div>
			<Container>
				<CreateGatewayForm
					onSubmit={props.onSubmit}
					locations={locations}
					clicked={props.clicked}
					history={props.history}
				/>
			</Container>
		</div>
	);
};

export default CreateGateway;
