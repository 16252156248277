import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import LoadingIndicator from '../../../common/LoadingIndicator/LoadingIndicator.component';
import UserForm, { IUser, IUserLocation, IUserRoleCognitoGroup } from './Form.component';
import { RouteComponentProps } from '../../../../interface/Route';
import styles from './User.module.css';

/*
CY - hard coding CLIENT_GROUPS and USER_GROUPS until requirements get confirmed
Client side : milky-way/dashboardV2/src/components/app/MainPage/UserManagement/User.component.tsx
Server side: milky-way/Lambdas/CognitoServiceV2/models/index.ts
*/
const CLIENT_GROUPS = ['default', 'MBS'];

const USER_GROUPS: IUserRoleCognitoGroup[] = [
	{
		name: 'User',
		id: 'Users',
		disabled: true,
	},
	{
		name: 'Client admin',
		id: 'Client_Admin',
		disabled: false,
	},
	{
		name: 'Installer',
		id: 'Installer',
		disabled: false,
	},
	{
		name: 'Support',
		id: 'Support',
		disabled: true,
	},
	{
		name: 'Lambda',
		id: 'Lambda',
		disabled: true,
	},
];

const userCreatePageQuery = `
query userCreatePage($cognito_id: String!) {
  getUsers(cognito_id: $cognito_id) {
    client_group
    cognito_id
    create_time
    created_by
    customer_name
    email
    last_update_time
    last_updated_by
    locations
    roles
  }
  listLocations {
	 items {
	   location_name
       location_id
     }
  }
}
`;

const listLocationsQuery = `
	query listLocationsQuery {
		listLocations {
			items {
				location_id
				location_name
			}
		}
	}
`;

interface State {
	user: IUser | null;
	locations: IUserLocation[];
	loading: boolean;
	alertMessage: JSX.Element | null;
}

class User extends React.Component<RouteComponentProps, State> {
	cognitoId = this.props.match.params.cognito_id; // cognitoId can be "new", returns User = null
	isCreate = this.cognitoId === 'new';

	state: State = {
		user: null,
		locations: [],
		loading: false,
		alertMessage: null,
	};

	async componentDidMount() {
		this.setState({ loading: true });
		try {
			if (this.props.match.params.cognito_id === 'new') {
				const response: any = await API.graphql(graphqlOperation(listLocationsQuery));
				this.setState({
					loading: !this.state.loading,
					locations: response.data.listLocations.items,
				});
			} else {
				const response: any = await API.graphql(
					graphqlOperation(userCreatePageQuery, {
						cognito_id: this.props.match.params.cognito_id,
					})
				);
				const user = await this.transformData(response.data.getUsers);
				this.setState({
					loading: !this.state.loading,
					user,
					locations: response.data.listLocations.items,
				});
			}
		} catch (e) {
			console.error(e);
			this.setState({ loading: false });
		}
	}

	onSubmittingForm = message => {
		this.setState({ alertMessage: message });
	};

	renderUserForm() {
		if (this.state.loading) {
			return <LoadingIndicator />;
		} else {
			return (
				<UserForm
					user={this.state.user}
					locations={this.state.locations}
					userRolesCognitoGroups={USER_GROUPS}
					clientGroups={CLIENT_GROUPS}
					onSubmittingForm={this.onSubmittingForm}
				/>
			);
		}
	}

	transformData = user => {
		return {
			...user,
			locations: JSON.parse(user.locations),
			roles: JSON.parse(user.roles),
		};
	};

	render() {
		return (
			<div className={`${styles['wrapper']} container`}>
				{this.state.alertMessage ? <>{this.state.alertMessage} </> : null}
				<h1 data-cy='user-form-title' className='create-form-title'>
					{this.isCreate ? 'Create User' : 'Update User'}
				</h1>
				{this.renderUserForm()}
			</div>
		);
	}
}

export default User;
