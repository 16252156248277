import React, { useEffect, useRef, useState } from 'react';
import styles from './SearchBar.module.css';
import { ReactComponent as SearchLogo } from '../../../../assets/icons/search.svg';
import { Badge, Button, Dropdown, DropdownMenu } from 'reactstrap';

const onFocusInput = {
	borderColor: '#043b74',
};

const onHover = {
	backgroundColor: '#2a68a8',
	color: '#ffffff',
};

const SearchBar = ({ getFilteredSearchKeyword, suggestion, onClickSuggestion }) => {
	const [focus, setFocus] = useState<boolean>(false);
	const [hoverIndex, setHoverIndex] = useState<number>(0);
	const [keyword, setKeyword] = useState<any>('');
	const [submitted, setSubmitted] = useState<boolean>(false);
	const [keyName, setKeyName] = useState<string>('');
	const inputEl = useRef<HTMLInputElement>(null);

	const onSubmit = e => {
		e.preventDefault();
		setSubmitted(true);
		setKeyword('');
		setKeyName('');
	};

	const onKeywordChange = e => {
		const word = e.target.value;
		setKeyword(word);
		setSubmitted(false);
	};

	const onClickAutoSuggest = i => {
		const filtered = suggestion.filter((v, index) => index === i);
		if (filtered.length) {
			onClickSuggestion(...filtered);
			setKeyName(filtered[0].headerName);
			if (inputEl && inputEl.current) {
				inputEl.current.focus();
			}
		}
	};

	const onCancelAutoSuggest = () => {
		onClickSuggestion([]);
		setKeyName('');
	};

	const onBackSpace = e => {
		if (e.keyCode === 8) {
			setKeyName('');
		}
		if (suggestion.length) {
			if (e.keyCode === 40 && hoverIndex < suggestion.length) {
				setHoverIndex(hoverIndex + 1);
			}
			if (e.keyCode === 38 && hoverIndex > 1) {
				setHoverIndex(hoverIndex - 1);
			}
			if (e.keyCode === 13) {
				onClickAutoSuggest(hoverIndex - 1);
				setHoverIndex(0);
			}
		}
	};

	const isFirstRun = useRef(true);
	// this filtering need not useEffect because it is not a side effect
	// they are simply plain callbacks on keydown/ submit event
	useEffect(
		() => {
			// this is to prevent useEffect to run on initial render because the initial value is empty string
			if (isFirstRun.current) {
				isFirstRun.current = false;
				return;
			}

			// Putting the callback in the useEffect is to ensure we get the latest value
			// will get invoked if the argument value changes
			const _keyword = keyword.trim();
			getFilteredSearchKeyword(_keyword, submitted);
		},
		[keyword, submitted, getFilteredSearchKeyword] // will only run useEffect if value changes
	);

	return (
		<>
			<form
				className={`d-flex flex-row ${styles['search']}`}
				style={focus ? onFocusInput : {}}
				onSubmit={onSubmit}
			>
				{' '}
				<small onClick={() => onCancelAutoSuggest()}>
					<Badge color='secondary'>{keyName}</Badge>
				</small>
				<input
					className={`form-control ${styles['custom-input']}`}
					data-cy='search-input'
					placeholder={'Search'}
					type='text'
					ref={inputEl}
					value={keyword}
					onChange={e => onKeywordChange(e)}
					onKeyDown={e => onBackSpace(e)}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)}
				/>
				<Button
					type='submit'
					data-cy='submit-button'
					disabled={!keyword}
					className={`${styles['custom-button']}`}
				>
					<SearchLogo
						className={`${styles['search-logo']} ${focus ? styles['search-logo-focus'] : {}}`}
					/>
				</Button>
				<Dropdown isOpen={!!suggestion.length && !keyName} toggle={() => null}>
					<DropdownMenu
						style={{ left: '-222px', top: '45px', borderRadius: '4px' }}
						className={styles['custom-dropdown']}
					>
						{suggestion.map((autoSuggest, i) => {
							return (
								<div
									key={i}
									style={hoverIndex === i + 1 ? onHover : {}}
									onMouseEnter={() => setHoverIndex(i + 1)}
									onMouseLeave={() => setHoverIndex(0)}
									className={styles['auto-suggest-dropdown']}
									onClick={() => onClickAutoSuggest(i)}
								>
									Search in {autoSuggest.headerName}
								</div>
							);
						})}
					</DropdownMenu>
				</Dropdown>
			</form>
		</>
	);
};

export default SearchBar;
