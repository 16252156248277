// @ts-nocheck
import React from 'react';
import userMgtStyles from './User.module.css';
import Form from 'reactstrap/lib/Form';
import Button from 'reactstrap/lib/Button';
import Input from 'reactstrap/lib/Input';
import Label from 'reactstrap/lib/Label';
import { Link, withRouter } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import { createUsers, updateUsers } from '../../../../graphql/mutations';
import Select, { IRenderer } from 'react-dropdown-select';
import { ReactComponent as ClearIcon } from '../../../../../src/assets/icons/close.svg';
import { ReactComponent as DropdownIcon } from '../../../../../src/assets/icons/down-arrow.svg';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal.component';
import { Alert } from 'reactstrap';
import { ReactComponent as LocationIcon } from './../../../../assets/icons/location-icon-big.svg';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from '../../../../interface/Route';
import { ModalActions } from '../../../../enums/confirmationModal';

export interface IUserLocation {
	location_id: string;
	location_name: string;
}

export interface IUserRoleCognitoGroup {
	name: string;
	id: string;
	disabled: boolean;
}

export interface IUser {
	client_group?: string;
	cognito_id?: string;
	customer_name: string;
	email?: string;
	locations: Array<IUserLocation>; // AppSync stores this as JSON strings
	roles: Array<string>;
	// server -side attributes below
	// create_time: number;
	// last_update_time: string;
	// last_updated_by: string;
	// created_by: string;
}

interface Props extends WithTranslation, RouteComponentProps {
	locations: Array<IUserLocation>;
	user: IUser;
	userRolesCognitoGroups: Array<IUserRoleCognitoGroup>;
	clientGroups: Array<string>;
	onSubmittingForm: (value: JSX.Element) => JSX.Element;
}

interface State {
	user_locations: Array<IUserLocation>;
	user_customer_name: string;
	user_roles: Array<IUserRoleCognitoGroup>;
	user_client_group: string;
	user_email: string;
	user_cognito_id: string;
	isOpen: boolean;
	is_create: boolean;
	modal: boolean;
	loading: boolean;
	modalActions: number | null;
	modalState: boolean;
}

class UserForm extends React.Component<Props, State> {
	private myRef: React.RefObject<HTMLInputElement> = React.createRef();
	// TODO: Refactor the type. Maybe remove the optional or the null value on the parent
	// @ts-ignore
	state: State = this.props.user
		? {
				is_create: false,
				user_email: this.props.user.email,
				user_customer_name: this.props.user.customer_name,
				user_locations: this.props.user.locations,
				user_client_group: this.props.user.client_group || 'default', // backwards comptability for ancient users with no client_group
				user_roles: this.props.user.roles.map(role => {
					return this.props.userRolesCognitoGroups.find(
						hardCodedUserRoles => role === hardCodedUserRoles.id
					);
				}),
				user_cognito_id: this.props.user.cognito_id,
				isOpen: false,
				modal: false,
				loading: false,
				modalActions: null,
				modalState: false,
		  }
		: {
				is_create: true,
				user_email: '',
				user_locations: [],
				user_customer_name: '',
				user_roles: [],
				user_client_group: this.props.clientGroups[0], // provide a default value
				user_cognito_id: '',
				isOpen: false,
				modal: false,
				loading: false,
				modalActions: null,
				modalState: false,
		  };

	submitForm = async () => {
		this.setState({ loading: true });
		let roles = this.state.user_roles.map(role => role.id);
		if (!roles.includes('Users')) {
			// Need Users role for every user
			roles = [...roles, 'Users'];
		}
		const locations = this.state.user_locations.map(location => ({
			location_id: location.location_id,
		}));

		const input = this.state.is_create
			? {
					client_group: this.state.user_client_group,
					customer_name: this.state.user_customer_name,
					email: this.state.user_email,
					roles: JSON.stringify(roles),
					locations: JSON.stringify(locations),
			  }
			: {
					cognito_id: this.state.user_cognito_id,
					customer_name: this.state.user_customer_name,
					roles: JSON.stringify(roles),
					locations: JSON.stringify(locations),
			  };

		let message;

		try {
			const operation = this.state.is_create ? createUsers : updateUsers;
			await API.graphql(graphqlOperation(operation, { input }));
			if (this.state.is_create) {
				this.setState({
					is_create: true,
					user_email: '',
					user_locations: [],
					user_customer_name: '',
					user_roles: [],
					user_client_group: this.props.clientGroups[0], // provide a default value
					user_cognito_id: '',
					isOpen: false,
					modal: false,
					loading: false,
				});
			} else {
				this.setState({ loading: false });
			}
			message = this.state.is_create ? (
				<Alert>
					{this.state.user_customer_name} has been added successfully.{' '}
					<Link to={'/users'} className='alert-link'>
						{' '}
						View All Users
					</Link>
				</Alert>
			) : (
				<Alert>
					{this.state.user_customer_name} has been updated successfully.{' '}
					<Link to={'/users'} className='alert-link'>
						{' '}
						View All Users
					</Link>
				</Alert>
			);
		} catch (error) {
			console.error(`Error creating or updating user: ${JSON.stringify(error, null, 2)}`);
			message = <Alert color='danger-alert'>Some error occurred - please contact admin</Alert>;
			this.setState({ loading: false });
		} finally {
			this.props.onSubmittingForm(message);
		}
	};

	onEmailChange = e => this.setState({ user_email: e.target.value });

	onCustNameChange = event => this.setState({ user_customer_name: event.target.value });

	onClientGroupChange = event => this.setState({ user_client_group: event.target.value });

	onSetUserRoles = role => this.setState({ user_roles: role });

	onSetUserLocations = location => this.setState({ user_locations: location });

	onSubmit = e => {
		this.toggleModal(ModalActions.Submit);
		e.preventDefault();
	};

	cancel = () => {
		if (
			this.state.user_customer_name ||
			this.state.user_email ||
			this.state.user_locations.length ||
			this.state.user_roles.length
		) {
			this.toggleModal(ModalActions.Cancel);
		} else {
			this.props.history.push('/users');
		}
	};

	confirmClick = () => {
		if (this.state.modalActions === ModalActions.Submit) {
			this.submitForm();
			this.setState(prev => ({
				modalState: !prev.modalState,
			}));
		}
		if (this.state.modalActions === ModalActions.Cancel) {
			this.props.history.push('/users');
		}
	};

	cancelClick = () => {
		this.setState(prev => ({
			modalState: !prev.modalState,
		}));
	};

	toggleModal = (action: ModalActions) => {
		this.setState(prev => ({
			modalActions: action,
			modalState: !prev.modalState,
		}));
	};

	customContentRenderer = ({ props, state, methods }) => {
		return (
			<div>
				Access to <b>{this.state.user_locations.length}</b> out of{' '}
				<b>{this.props.locations.length}</b> location(s)
				<input
					className={userMgtStyles['custom-input-search']}
					type='text'
					ref={this.myRef}
					onChange={e => methods.setSearch(e)}
				/>
			</div>
		);
	};

	customClearRenderer = ({ props, state, methods }: IRenderer<any>) => {
		return (
			<ClearIcon
				viewBox='-15 -15 45 45'
				width='30'
				height='30'
				onClick={() => methods?.clearAll()}
				className={userMgtStyles['clear-icon']}
			/>
		);
	};

	customDropdownHandlerRenderer = ({ props, state, methods }: IRenderer<any>) => {
		return (
			<DropdownIcon
				onClick={() => methods?.dropDown('toggle', null)}
				className={userMgtStyles['down-icon']}
			/>
		);
	};

	customDropdownRenderer = ({ props, state, methods }: IRenderer<any>) => {
		// @ts-ignore
		const regexp = new RegExp(state.search, 'i');
		return (
			<div>
				<div className={userMgtStyles['search-and-toggle']}>
					<div className={userMgtStyles['dropdown-buttons']}>
						<div>Search and select:</div>
						{methods?.areAllSelected() ? (
							<Button className='clear' onClick={methods.clearAll}>
								Clear all
							</Button>
						) : (
							// @ts-ignore
							<Button onClick={methods?.selectAll}>Select all</Button>
						)}
					</div>
					<Input
						type='text'
						value={state?.search}
						onChange={methods?.setSearch}
						placeholder='Type anything'
					/>
				</div>
				{props?.options
					.sort((a, b) => a.location_name.localeCompare(b.location_name))
					// @ts-ignore
					.filter(item => regexp.test(item[props.searchBy] || item[props.labelField]))
					.map(item => {
						if (!props.keepSelectedInList && methods?.isSelected(item)) {
							return null;
						}

						return (
							<div
								className={
									item.disabled
										? `${userMgtStyles['multi-select-dropdown']} ${userMgtStyles['role-disabled']} `
										: `${userMgtStyles['multi-select-dropdown']}`
								}
								onClick={() => methods.addItem(item)}
								key={item.location_id}
							>
								{item && item.disabled ? (
									<div aria-disabled>
										{item.location_name}
										<ins>disabled</ins>
									</div>
								) : (
									<div onClick={() => methods?.addItem(item)}>
										{item.location_name}{' '}
										<input
											className='float-right'
											onChange={() => methods?.addItem(item)}
											type='checkbox'
											data-cy={'select-location-option'}
											checked={
												// @ts-ignore
												state.values.findIndex(i => i.location_id === item.location_id) !== -1
											}
										/>
									</div>
								)}
							</div>
						);
					})}
			</div>
		);
	};

	onDropdownOpen = () => {
		// @ts-ignore
		this.myRef.current.focus();
	};

	isFormInvalid = () => {
		return !(
			this.state.user_client_group &&
			this.state.user_customer_name &&
			this.state.user_email &&
			this.state.user_locations.length
		);
	};

	removeLocation = locationId => {
		const filterLocation = this.state.user_locations.filter(
			location => location.location_id !== locationId
		);
		this.setState({ user_locations: filterLocation });
	};

	checkItemOnDropdown = (methods, item) => {
		if (methods.isSelected(item)) {
			methods.removeItem(null, item, false);
		} else {
			methods.addItem(item);
		}
	};

	render() {
		// @ts-ignore
		// @ts-ignore
		return (
			<>
				<Form data-cy='user-form' className={userMgtStyles['form-card']} onSubmit={this.onSubmit}>
					<div className='d-flex justify-content-around'>
						<div className='create-form-card col-6 mr-5'>
							<div className='d-flex flex-column'>
								<div className='list-group-item'>
									<div className='create-form-group'>
										<Label htmlFor='user_customer_name' className='col-6'>
											{this.props.t('CUSTOMER NAME')}
										</Label>
										<br />
										<Input
											data-cy='customer-name'
											type='text'
											className='form-control col-12'
											id='user_customer_name'
											value={this.state.user_customer_name}
											onChange={this.onCustNameChange}
										/>
									</div>
								</div>
								<div className='list-group-item'>
									<div className='create-form-group'>
										<Label htmlFor='email' className='col-xs-6'>
											{this.props.t('EMAIL ADDRESS')}
										</Label>
										<br />
										<Input
											data-cy='user-email-address'
											className='form-control col-12'
											id='user_email'
											value={this.state.user_email}
											onChange={this.onEmailChange}
											disabled={!this.state.is_create}
										/>
									</div>
								</div>
								<div className='list-group-item'>
									<div className='create-form-group'>
										<Label htmlFor='user_role' className='col-xs-6'>
											{this.props.t('CLIENT GROUP')}
										</Label>
										<br />
										<select
											data-cy='user-client-group'
											className='form-control col-12'
											value={this.state.user_client_group}
											onChange={this.onClientGroupChange}
											disabled={!this.state.is_create}
										>
											{this.props.clientGroups.map(clientGroup => (
												<option key={clientGroup} value={clientGroup}>
													{clientGroup}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className='list-group-item'>
									<div className='create-form-group'>
										<Label htmlFor='user_role' className='col-xs-6'>
											{this.props.t('USER ROLE(S)')}
										</Label>
										{this.state.user_roles && (
											<Select
												className={'user-role-select'} // dummy class for Cypress selecting
												style={{ border: 'none' }}
												multi
												options={this.props.userRolesCognitoGroups}
												values={this.state.user_roles}
												labelField={'name'}
												valueField={'id'}
												clearable={!!this.state.user_roles.length}
												color={'#ed8347'}
												onChange={values => this.onSetUserRoles(values)}
												clearRenderer={this.customClearRenderer}
												dropdownHandleRenderer={this.customDropdownHandlerRenderer}
												itemRenderer={({ item, itemIndex, props, state, methods }) => {
													return (
														<div
															className={
																item.disabled
																	? `${userMgtStyles['multi-select-dropdown']} ${userMgtStyles['role-disabled']} `
																	: `${userMgtStyles['multi-select-dropdown']}`
															}
															onClick={() => methods?.addItem(item)}
														>
															{item.disabled ? (
																<div aria-disabled>
																	{item.name}
																	<ins>disabled</ins>
																</div>
															) : (
																<div onClick={() => methods?.addItem(item)}>
																	{item.name}{' '}
																	<input
																		className='float-right'
																		onChange={() => methods?.addItem(item)}
																		type='checkbox'
																		data-cy={'select-role-option'}
																		checked={state?.values.findIndex(i => i.id === item.id) !== -1}
																	/>
																</div>
															)}
														</div>
													);
												}}
											/>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className={'create-form-card col-6'}>
							<div className='d-flex flex-column'>
								<div className='list-group-item'>
									<div className='create-form-group'>
										<Label htmlFor='user_customer_name' className='col-xs-6'>
											{this.props.t('LOCATION ACCESS')}
										</Label>
										<br />
										{this.state.user_locations && (
											<Select
												style={{ border: 'none' }}
												multi
												className={`${userMgtStyles['custom-dropdown-placeholder']} user-location-select`} // dummy class for cypress select
												placeholder={'Search for location(s)'}
												createNewLabel={'add {search}'}
												disabledLabel={'disabled'}
												onDropdownOpen={this.onDropdownOpen}
												options={this.props.locations}
												values={this.state.user_locations}
												labelField={'location_name'}
												valueField={'location_id'}
												clearable={!!this.state.user_locations.length}
												color='#ed8347'
												clearRenderer={this.customClearRenderer}
												dropdownHandleRenderer={this.customDropdownHandlerRenderer}
												dropdownRenderer={this.customDropdownRenderer}
												contentRenderer={this.customContentRenderer}
												onChange={location => this.onSetUserLocations(location)}
											/>
										)}
									</div>
								</div>
								<div
									className={`list-group-item location-group-item d-flex flex-column scrollbox ${!this
										.state.user_locations.length && 'justify-content-center align-items-center'}`}
								>
									{this.state.user_locations.length ? (
										<div>
											<Button
												className='float-right'
												color={'borderless'}
												onClick={() => this.onSetUserLocations([])}
											>
												{this.props.t('Clear All')}
											</Button>
										</div>
									) : null}
									{this.state.user_locations.length ? (
										<ul className={userMgtStyles['locations-list']}>
											{this.state.user_locations
												.sort((a, b) => a.location_name.localeCompare(b.location_name))
												.map((location, i) => {
													return (
														<li key={i} className={userMgtStyles['location-item']}>
															{location.location_name}
															<ClearIcon
																onClick={() => this.removeLocation(location.location_id)}
																className={`${userMgtStyles['location-clear-icon']} float-right`}
																viewBox='-15 -10 45 45'
																width='30'
																height='30'
															/>
														</li>
													);
												})}
										</ul>
									) : (
										<>
											<LocationIcon />
											<p className={userMgtStyles['placeholder-text']}>
												{this.props.t('Add location(s) that this user can access')}
											</p>
										</>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='d-flex align-items-center flex-column'>
						<Button
							type='submit'
							data-cy='user-form-button'
							color='secondary'
							disabled={(this.state.is_create && this.isFormInvalid()) || this.state.loading}
						>
							{this.props.t(`${this.state.loading ? 'LOADING...' : 'SUBMIT'}`)}
						</Button>
						<Button onClick={this.cancel} color='borderless' disabled={this.state.loading}>
							{this.props.t('CANCEL')}
						</Button>
					</div>
				</Form>
				<ConfirmationModal
					openModal={this.toggleModal}
					title={this.props.t(
						this.state.modalActions === ModalActions.Submit
							? this.state.is_create
								? 'ADD NEW USER'
								: 'UPDATE USER'
							: 'CONFIRM CANCELLATION'
					)}
					message={this.props.t(
						this.state.modalActions === ModalActions.Submit
							? 'Are you sure you want to submit?'
							: 'All unsaved changes would be lost. Are you sure you want to cancel?'
					)}
					modal={this.state.modalState}
					onConfirm={this.confirmClick}
					onCancel={this.cancelClick}
				/>
			</>
		);
	}
}

// @ts-ignore
export default withRouter(withTranslation()(UserForm));
