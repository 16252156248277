import React from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { RouteComponentProps } from '../../../../interface/Route';
import styles from './Location.module.css';
import ListNodes from './Nodes/ListNodes.component';
import ListGateways from './Gateways/ListGateways.component';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { withTranslation, WithTranslation } from 'react-i18next';

export interface Locations {
	app_id: string | null;
	create_time: string | null;
	created_by: string | null;
	customer_name: string | null;
	last_update_by: string | null;
	last_update_ip_address: string | null;
	last_update_time: string | null;
	location_id: string;
	location_name: string | null;
	model: string | null;
	settings: string | null;
	settings_update_by: string | null;
	settings_update_ip_address: string | null;
	settings_update_time: string | null;
}

interface Nodes {
	automation_set_id: string;
	create_time: string | null;
	created_by: string | null;
	description: string | null;
	last_update_time: string | null;
	last_updated_by: string | null;
	location_id: string | null;
	model: string | null;
	name: string | null;
	nodeData: {
		__typename: 'NodeData';
		data_status: string | null;
		details: string | null;
		node_id: string;
		update_time: string | null;
	} | null;
	node_id: string;
	room_id: string | null;
	template_node_id: string | null;
	type: string | null;
}

interface Rooms {
	create_time: string;
	created_by: string;
	last_update_time: string;
	location_id: string;
	room_id: string;
	room_name: string;
	template_id: string;
	nodes: {
		automation_set_id: string;
		create_time: string;
		created_by: string;
		description: string;
		last_update_time: string;
		last_updated_by: string;
		location_id: string;
		model: string;
		name: string;
		nodeData: {
			data_status: string;
			details: string;
			node_id: string;
			update_time: string;
		};
		node_id: string;
		room_id: string;
		template_node_id: string;
		type: string;
	}[];
}

interface State {
	locationId: string;
	activeTab: string;
	rooms: Rooms[];
	location: Locations;
	loading: boolean;
}

const locationStatusPageQuery = `query locationStatusPage($location_id: String!) {
    getLocations(location_id: $location_id) {
        app_id
        create_time
        created_by
        customer_name
        last_update_by
        last_update_ip_address
        last_update_time
        location_id
        location_name
        model
        settings
        settings_update_by
        settings_update_ip_address
        settings_update_time
     }
}
`;

interface Props extends RouteComponentProps, WithTranslation {}

class Location extends React.Component<Props, State> {
	state: State = {
		locationId: this.props.match.params.location_id ? this.props.match.params.location_id : '',
		activeTab:
			this.props.location.state && this.props.location.state.activeTab
				? this.props.location.state.activeTab
				: '4',
		rooms: [],
		location: {
			app_id: null,
			create_time: null,
			created_by: null,
			customer_name: null,
			last_update_by: null,
			last_update_ip_address: null,
			last_update_time: null,
			location_id: '',
			location_name: null,
			model: null,
			settings: null,
			settings_update_by: null,
			settings_update_ip_address: null,
			settings_update_time: null,
		},
		loading: false,
	};

	toggle = tab => {
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	};

	setClassname = (check: boolean): string => {
		return check ? [styles['nav-link-active'], styles['tab-style']].join(' ') : styles['tab-style'];
	};

	fetchData = async () => {
		try {
			const result = await API.graphql(
				graphqlOperation(locationStatusPageQuery, { location_id: this.state.locationId })
			);

			const {getLocations: location} = result.data;

			if (!location) {
				return this.props.history.push('/404');
			}

			this.setState({ location, loading: false });
		} catch (e) {
			console.error(e);
		}
	};

	componentDidMount() {
		this.setState({ loading: true });
		this.fetchData();
	}

	componentDidUpdate(prevProps) {
		// set to component state and refetch if location_id has changed
		if (this.props.match.params.location_id !== prevProps.match.params.location_id) {
			this.setState(
				{
					locationId: this.props.match.params.location_id,
				},
				this.refetch
			);
		}
	}

	refetch = () => {
		this.fetchData();
	};

	render() {
		const { t } = this.props;
		return (
			<>
				<Nav tabs className={'d-flex justify-content-end'}>
					<div className={'mr-auto'}>
						<SkeletonTheme color={'white'} highlightColor={'#f9f9f9'}>
							<p className={`${styles['location-name']} mr-auto`} data-cy='location-table-header'>
								{this.state.location.location_name || <Skeleton width={'400px'} />}
							</p>
						</SkeletonTheme>
					</div>

					{/*<NavItem className={styles['custom-nav-item']}>*/}
					{/*	<NavLink*/}
					{/*		className={this.setClassname(this.state.activeTab === '1')}*/}
					{/*		onClick={() => {*/}
					{/*			this.toggle('1');*/}
					{/*		}}*/}
					{/*		disabled={true}*/}
					{/*	>*/}
					{/*		OVERVIEW*/}
					{/*	</NavLink>*/}
					{/*</NavItem>*/}
					{/*<NavItem className={styles['custom-nav-item']}>*/}
					{/*	<NavLink*/}
					{/*		className={this.setClassname(this.state.activeTab === '2')}*/}
					{/*		onClick={() => {*/}
					{/*			this.toggle('2');*/}
					{/*		}}*/}
					{/*		disabled={true}*/}
					{/*	>*/}
					{/*		ROOMS ({this.state.rooms.length})*/}
					{/*	</NavLink>*/}
					{/*</NavItem>*/}
					<NavItem className={`${styles['custom-nav-item']} d-flex align-items-end`}>
						<NavLink
							className={this.setClassname(this.state.activeTab === '3')}
							onClick={() => {
								this.toggle('3');
							}}
							data-cy='gateway-tab'
						>
							{t('GATEWAYS')}
						</NavLink>
					</NavItem>
					<NavItem className={`${styles['custom-nav-item']} d-flex align-items-end`}>
						<NavLink
							className={this.setClassname(this.state.activeTab === '4')}
							onClick={() => {
								this.toggle('4');
							}}
						>
							{t('NODES')}
						</NavLink>
					</NavItem>
					{/*<NavItem className={styles['custom-nav-item']}>*/}
					{/*	<NavLink*/}
					{/*		className={this.setClassname(this.state.activeTab === '5')}*/}
					{/*		onClick={() => {*/}
					{/*			this.toggle('5');*/}
					{/*		}}*/}
					{/*		disabled={true}*/}
					{/*	>*/}
					{/*		USERS*/}
					{/*	</NavLink>*/}
					{/*</NavItem>*/}
				</Nav>
				<TabContent activeTab={this.state.activeTab}>
					{/*<TabPane tabId='1'>*/}
					{/*	<Row>*/}
					{/*		<Col sm='12'>*/}
					{/*			<p>Hello world</p>*/}
					{/*		</Col>*/}
					{/*	</Row>*/}
					{/*</TabPane>*/}
					{/*<TabPane tabId='2'>*/}
					{/*	<Row>*/}
					{/*		<Col sm='12'>*/}
					{/*			<RoomsTable rooms={this.state.rooms} />*/}
					{this.state.activeTab === '3' ? (
						<TabPane tabId='3'>
							<Row>
								<Col sm='12'>
									<ListGateways
										appId={
											this.props.location.state && this.props.location.state.appId
												? this.props.location.state.appId
												: this.state.location.app_id
										}
									/>
								</Col>
							</Row>
						</TabPane>
					) : null}
					{this.state.activeTab === '4' ? (
						<TabPane tabId='4'>
							<Row>
								<Col sm='12'>
									<ListNodes locationId={this.state.locationId} />
								</Col>
							</Row>
						</TabPane>
					) : null}
				</TabContent>
			</>
		);
	}
}

export default withTranslation()(Location);
