import React from 'react';
import { ReactComponent as RefreshLogo } from '../../../../assets/icons/refresh.svg';
import { Button } from 'reactstrap';

const RefreshButton = ({ refreshData }) => {
	return (
		<>
			<Button
				onClick={refreshData}
				color={'light-custom'}
				style={{ height: '2.857em', width: '2.857em' }}
			>
				<RefreshLogo />
			</Button>
		</>
	);
};

export default RefreshButton;
