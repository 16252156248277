import React from 'react';
import { AutomationSetStreams } from '../../../../../../../interface/AutomationSetStreams';
import { UpdateAirconDesiredState } from '../../../../TestingUpdates/TestingUpdates.component';
import { CreateAuditInput } from '../../../../../../../API';
import { createAudit } from '../../../../../../../graphql/mutations';
import { Temp } from '../../../RoomDevice.component';
import { Button, Col, Row } from 'reactstrap';
import Utils from '../../../../../../../utils/index';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { UserContext } from '../../../../../../common/Context/UserContext';

enum UpdateAirconValue {
	Mode,
	Temperature,
	Speed,
	Power,
}

interface SettingsProps {
	automtionStream: AutomationSetStreams;
	mode: number;
	temperature: number;
	speed: number;
	power: boolean;
	locationId: string;
}

interface SettingState {
	updateConfirmationPrompt: boolean;
	temperature: number;
	mode: number;
	speed: number;
	power: boolean;
	maxAcTempBySettings: number;
	minAcTempBySettings: number;
}

class AirconSettingsDisplay extends React.Component<SettingsProps, SettingState> {
	state: SettingState = {
		maxAcTempBySettings: 31, // hardcode now, pending method to fetch settings
		minAcTempBySettings: 15, // hardcode now, pending method to fetch settings
		updateConfirmationPrompt: false,
		temperature: this.props.temperature,
		mode: this.props.mode,
		speed: this.props.speed,
		power: this.props.power,
	};

	updateValue = (val: any, type: number) => {
		let newValue: number = 0;
		this.setState({ updateConfirmationPrompt: true });
		switch (type) {
			case UpdateAirconValue.Temperature:
				newValue = this.state.temperature + val;
				this.setState({ temperature: newValue });
				break;
			case UpdateAirconValue.Mode:
				newValue = this.state.mode + val;
				if (newValue % 4 === 0) {
					newValue = 0;
				}
				this.setState({ mode: newValue });
				break;
			case UpdateAirconValue.Speed:
				newValue = this.state.speed + val;
				if (newValue % 4 === 0) {
					newValue = 0;
				}
				this.setState({ speed: newValue });
				break;
			case UpdateAirconValue.Power:
				this.setState({ power: !val });
				break;
		}
	};

	submit = (mutation: any) => {
		const cognitoId = this.context.userDetails.attributes.sub;
		return async () => {
			const nodeId = this.props.automtionStream.node_id;
			const locationId = this.props.locationId;
			const action = 'UPDATE';
			const timestamp = Date.now();
			const sourceIpAddress = 'Some source mock';

			const record: UpdateAirconDesiredState = {
				stream_type: 'ACController',
				temperature_set: this.state.temperature,
				mode: this.state.mode,
				fan_speed: this.state.speed,
				powered: this.state.power,
				stream_id: this.props.automtionStream.stream_id,
				type: 'aircon',
				update_source: 'dashboard',
			};
			const input: CreateAuditInput = {
				action: action,
				cognito_id: cognitoId,
				source_ip: sourceIpAddress,
				id: `${nodeId}_AcControls`,
				location_id: locationId,
				record: JSON.stringify(record),
				timestamp: timestamp,
			};

			try {
				const response = await mutation({ input });
				console.log('Response ');
				console.log(response);
			} catch (error) {
				console.error(`Error`, error);
			}
		};
	};

	cancel = () => {
		this.setState({ updateConfirmationPrompt: false });
	};

	render() {
		const { temperature, mode, speed, power } = this.state;
		return (
			<Connect mutation={graphqlOperation(createAudit)}>
				{({ mutation }) => {
					return (
						<>
							<p>
								<em>If you want to be cancel, please refresh the page for now.</em>
							</p>
							<p>
								Temperature: <Temp>{temperature}</Temp>
								<Button
									onClick={() => this.updateValue(1, UpdateAirconValue.Temperature)}
									color={'success'}
									disabled={this.state.temperature === this.state.maxAcTempBySettings}
								>
									+
								</Button>
								<Button
									onClick={() => this.updateValue(-1, UpdateAirconValue.Temperature)}
									color={'danger'}
									disabled={this.state.temperature === this.state.minAcTempBySettings}
								>
									-
								</Button>
							</p>
							<p>Mode: {Utils.getModeTitles(mode)}</p>
							<Button onClick={() => this.updateValue(1, UpdateAirconValue.Mode)} color={'primary'}>
								Toggle
							</Button>
							<p>Speed: {Utils.getFanSpeedTitles(speed)}</p>
							<Button
								onClick={() => this.updateValue(1, UpdateAirconValue.Speed)}
								color={'primary'}
							>
								Toggle
							</Button>
							<p>Power: {power ? 'on' : 'off'}</p>
							<Button
								onClick={() => this.updateValue(power, UpdateAirconValue.Power)}
								color={'primary'}
							>
								Toggle
							</Button>
							{this.state.updateConfirmationPrompt && (
								<Row>
									<Col sm='12' md={{ size: 6, offset: 3 }}>
										<Button onClick={this.submit(mutation)} color={'success'}>
											Save
										</Button>
										{/* Don't expost cancel button first since it does not reset the display */}
										{/* <Button onClick={this.cancel} color={'default'}>
											Cancel
										</Button> */}
									</Col>
								</Row>
							)}
						</>
					);
				}}
			</Connect>
		);
	}
}
AirconSettingsDisplay.contextType = UserContext;

export default AirconSettingsDisplay;
