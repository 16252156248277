import React from 'react';

const Error404 = () => {
	return (
		<div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
			<div>
				<h1>404</h1>
				<h2>We couldn't find this page</h2>
			</div>
		</div>
	);
};

export default Error404;
