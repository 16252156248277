// tslint:disable
// this is an auto generated file. This will be overwritten

export const getAudit = `query GetAudit($id: String!, $timestamp: Int!) {
  getAudit(id: $id, timestamp: $timestamp) {
    action
    cognito_id
    id
    location_id
    record
    source_ip
    timestamp
  }
}
`;
export const getAuthUser = `query GetAuthUser($cognito_id: String!) {
  getAuthUser(cognito_id: $cognito_id) {
    client_group
    cognito_id
    create_time
    created_by
    customer_name
    email
    last_update_time
    last_updated_by
    locations
    roles
  }
}
`;
export const getAutomationSetStreams = `query GetAutomationSetStreams(
  $automation_set_id: String!
  $stream_id: String!
) {
  getAutomationSetStreams(
    automation_set_id: $automation_set_id
    stream_id: $stream_id
  ) {
    automation_set_id
    data
    device_name
    device_type
    node_id
    stream_id
  }
}
`;
export const getAutomationSets = `query GetAutomationSets($automation_set_id: String!, $room_id: String!) {
  getAutomationSets(automation_set_id: $automation_set_id, room_id: $room_id) {
    aircon_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    automation_set_id
    automation_set_name
    automation_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    create_time
    created_by
    last_update_ip_adress
    last_update_time
    last_updated_by
    primary_node
    room_id
    settings
    template_autoset_id
  }
}
`;
export const getAutomationSetsByRoomId = `query GetAutomationSetsByRoomId($room_id: String) {
  getAutomationSetsByRoomId(room_id: $room_id) {
    aircon_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    automation_set_id
    automation_set_name
    automation_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    create_time
    created_by
    last_update_ip_adress
    last_update_time
    last_updated_by
    primary_node
    room_id
    settings
    template_autoset_id
  }
}
`;
export const getDeviceStreamStatus = `query GetDeviceStreamStatus($stream_id: String!, $timestamp: AWSTimestamp!) {
  getDeviceStreamStatus(stream_id: $stream_id, timestamp: $timestamp) {
    data
    stream_id
    timestamp
  }
}
`;
export const getEnergyStreamStatus = `query GetEnergyStreamStatus($stream_id: String!, $timestamp: AWSTimestamp!) {
  getEnergyStreamStatus(stream_id: $stream_id, timestamp: $timestamp) {
    data
    stream_id
    timestamp
  }
}
`;
export const getFirmware = `query GetFirmware($firmware_id: String!) {
  getFirmware(firmware_id: $firmware_id) {
    create_time
    firmware_id
    name
    s3_link
    type
    uploaded_by
    version
  }
}
`;
export const getFirmwareUpdates = `query GetFirmwareUpdates($id: Int!) {
  getFirmwareUpdates(id: $id) {
    create_time
    firmware_id
    location_id
    scheduled_time
    status
    type
    update_id
  }
}
`;
export const getGateways = `query GetGateways($app_id: String!, $gateway_id: String!) {
  getGateways(app_id: $app_id, gateway_id: $gateway_id) {
    alerted
    app_id
    data
    gateway_id
    ip_address
    last_seen
    location_id
    location_name
  }
}
`;
export const getLocations = `query GetLocations($location_id: String!) {
  getLocations(location_id: $location_id) {
    app_id
    create_time
    created_by
    customer_name
    last_update_by
    last_update_ip_address
    last_update_time
    location_id
    location_name
    model
    settings
    settings_update_by
    settings_update_ip_address
    settings_update_time
  }
}
`;
export const getNodeData = `query GetNodeData($node_id: String!) {
  getNodeData(node_id: $node_id) {
    data_status
    details
    node_id
    update_time
  }
}
`;
export const getNodeMetaData = `query GetNodeMetaData($node_id: String!, $timestamp: Int!) {
  getNodeMetaData(node_id: $node_id, timestamp: $timestamp) {
    meta_data
    node_id
    timestamp
  }
}
`;
export const getNodes = `query GetNodes($automation_set_id: String!, $node_id: String!) {
  getNodes(automation_set_id: $automation_set_id, node_id: $node_id) {
    automation_set_id
    create_time
    created_by
    description
    last_update_time
    last_updated_by
    location_id
    model
    name
    nodeData {
      data_status
      details
      node_id
      update_time
    }
    node_id
    room_id
    template_node_id
    type
  }
}
`;
export const getRawGateways = `query GetRawGateways($device_uuid: String!) {
  getRawGateways(device_uuid: $device_uuid) {
    certs_issued
    description
    device_uuid
    download_link
    error
    expire_time
    location_id
    one_time_password
    status
  }
}
`;
export const getRooms = `query GetRooms($location_id: String!, $room_id: String!) {
  getRooms(location_id: $location_id, room_id: $room_id) {
    create_time
    created_by
    last_update_time
    location_id
    nodes {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    room_id
    room_name
    template_id
  }
}
`;
export const getShadowStatus = `query GetShadowStatus($node_id: String!, $timestamp: AWSTimestamp!) {
  getShadowStatus(node_id: $node_id, timestamp: $timestamp) {
    data
    node_id
    timestamp
  }
}
`;
export const getUsers = `query GetUsers($cognito_id: String!) {
  getUsers(cognito_id: $cognito_id) {
    client_group
    cognito_id
    create_time
    created_by
    customer_name
    email
    last_update_time
    last_updated_by
    locations
    roles
  }
}
`;
export const listAudits = `query ListAudits(
  $filter: TableAuditFilterInput
  $limit: Int
  $nextToken: String
) {
  listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      action
      cognito_id
      id
      location_id
      record
      source_ip
      timestamp
    }
    nextToken
  }
}
`;
export const listAuthGatewaysByAppId = `query ListAuthGatewaysByAppId($app_id: String!) {
  listAuthGatewaysByAppId(app_id: $app_id) {
    alerted
    app_id
    data
    gateway_id
    ip_address
    last_seen
    location_id
    location_name
  }
}
`;
export const listAuthNodesByLocationId = `query ListAuthNodesByLocationId($location_id: String!) {
  listAuthNodesByLocationId(location_id: $location_id) {
    automation_set_id
    create_time
    created_by
    description
    last_update_time
    last_updated_by
    location_id
    model
    name
    nodeData {
      data_status
      details
      node_id
      update_time
    }
    node_id
    room_id
    template_node_id
    type
  }
}
`;
export const listAutomationSetStreams = `query ListAutomationSetStreams(
  $filter: TableAutomationSetStreamsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAutomationSetStreams(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    nextToken
  }
}
`;
export const listAutomationSets = `query ListAutomationSets(
  $filter: TableAutomationSetsFilterInput
  $limit: Int
  $nextToken: String
) {
  listAutomationSets(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      aircon_stream {
        automation_set_id
        data
        device_name
        device_type
        node_id
        stream_id
      }
      automation_set_id
      automation_set_name
      automation_stream {
        automation_set_id
        data
        device_name
        device_type
        node_id
        stream_id
      }
      create_time
      created_by
      last_update_ip_adress
      last_update_time
      last_updated_by
      primary_node
      room_id
      settings
      template_autoset_id
    }
    nextToken
  }
}
`;
export const listDeviceStreamStatuses = `query ListDeviceStreamStatuses(
  $filter: TableDeviceStreamStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listDeviceStreamStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      data
      stream_id
      timestamp
    }
    nextToken
  }
}
`;
export const listEnergyStreamStatuses = `query ListEnergyStreamStatuses(
  $filter: TableEnergyStreamStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listEnergyStreamStatuses(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      data
      stream_id
      timestamp
    }
    nextToken
  }
}
`;
export const listFirmwareUpdates = `query ListFirmwareUpdates(
  $filter: TableFirmwareUpdatesFilterInput
  $limit: Int
  $nextToken: String
) {
  listFirmwareUpdates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      create_time
      firmware_id
      location_id
      scheduled_time
      status
      type
      update_id
    }
    nextToken
  }
}
`;
export const listFirmwares = `query ListFirmwares(
  $filter: TableFirmwareFilterInput
  $limit: Int
  $nextToken: String
) {
  listFirmwares(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      create_time
      firmware_id
      name
      s3_link
      type
      uploaded_by
      version
      status
    }
    nextToken
  }
}
`;
export const listGateways = `query ListGateways(
  $filter: TableGatewaysFilterInput
  $limit: Int
  $nextToken: String
) {
  listGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      alerted
      app_id
      data
      gateway_id
      ip_address
      last_seen
      location_id
      location_name
    }
    nextToken
  }
}
`;
export const listGatewaysByUser = `query ListGatewaysByUser {
  listGatewaysByUser {
    alerted
    app_id
    data
    gateway_id
    ip_address
    last_seen
    location_id
    location_name
  }
}
`;
export const listLocations = `query ListLocations(
  $filter: TableLocationsFilterInput
  $limit: Int
  $nextToken: String
) {
  listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      app_id
      create_time
      created_by
      customer_name
      last_update_by
      last_update_ip_address
      last_update_time
      location_id
      location_name
      model
      settings
      settings_update_by
      settings_update_ip_address
      settings_update_time
    }
    nextToken
  }
}
`;
export const listNodeData = `query ListNodeData(
  $filter: TableNodeDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listNodeData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      data_status
      details
      node_id
      update_time
    }
    nextToken
  }
}
`;
export const listNodeMetaData = `query ListNodeMetaData(
  $filter: TableNodeMetaDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listNodeMetaData(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      meta_data
      node_id
      timestamp
    }
    nextToken
  }
}
`;
export const listNodes = `query ListNodes(
  $filter: TableNodesFilterInput
  $limit: Int
  $nextToken: String
) {
  listNodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    nextToken
  }
}
`;
export const listRawGateways = `query ListRawGateways(
  $filter: TableRawGatewaysFilterInput
  $limit: Int
  $nextToken: String
) {
  listRawGateways(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      certs_issued
      description
      device_uuid
      download_link
      error
      expire_time
      location_id
      one_time_password
      status
    }
    nextToken
  }
}
`;
export const listRooms = `query ListRooms(
  $filter: TableRoomsFilterInput
  $limit: Int
  $nextToken: String
) {
  listRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      create_time
      created_by
      last_update_time
      location_id
      nodes {
        automation_set_id
        create_time
        created_by
        description
        last_update_time
        last_updated_by
        location_id
        model
        name
        nodeData {
          data_status
          details
          node_id
          update_time
        }
        node_id
        room_id
        template_node_id
        type
      }
      room_id
      room_name
      template_id
    }
    nextToken
  }
}
`;
export const listRoomsByLocationId = `query ListRoomsByLocationId($location_id: String!) {
  listRoomsByLocationId(location_id: $location_id) {
    create_time
    created_by
    last_update_time
    location_id
    nodes {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    room_id
    room_name
    template_id
  }
}
`;
export const listShadowStatuses = `query ListShadowStatuses(
  $filter: TableShadowStatusFilterInput
  $limit: Int
  $nextToken: String
) {
  listShadowStatuses(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      data
      node_id
      timestamp
    }
    nextToken
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: TableUsersFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      client_group
      cognito_id
      create_time
      created_by
      customer_name
      email
      last_update_time
      last_updated_by
      locations
      roles
    }
    nextToken
  }
}
`;
export const queryAutomationSetsByAutomationSetIdIndex = `query QueryAutomationSetsByAutomationSetIdIndex(
  $after: String
  $automation_set_id: String!
  $first: Int
) {
  queryAutomationSetsByAutomationSetIdIndex(
    after: $after
    automation_set_id: $automation_set_id
    first: $first
  ) {
    items {
      aircon_stream {
        automation_set_id
        data
        device_name
        device_type
        node_id
        stream_id
      }
      automation_set_id
      automation_set_name
      automation_stream {
        automation_set_id
        data
        device_name
        device_type
        node_id
        stream_id
      }
      create_time
      created_by
      last_update_ip_adress
      last_update_time
      last_updated_by
      primary_node
      room_id
      settings
      template_autoset_id
    }
    nextToken
  }
}
`;
export const queryLocationsByAppIdIndex = `query QueryLocationsByAppIdIndex(
  $after: String
  $app_id: String!
  $first: Int
) {
  queryLocationsByAppIdIndex(after: $after, app_id: $app_id, first: $first) {
    items {
      app_id
      create_time
      created_by
      customer_name
      last_update_by
      last_update_ip_address
      last_update_time
      location_id
      location_name
      model
      settings
      settings_update_by
      settings_update_ip_address
      settings_update_time
    }
    nextToken
  }
}
`;
export const queryLocationsByCustomerNameIndex = `query QueryLocationsByCustomerNameIndex(
  $after: String
  $customer_name: String!
  $first: Int
) {
  queryLocationsByCustomerNameIndex(
    after: $after
    customer_name: $customer_name
    first: $first
  ) {
    items {
      app_id
      create_time
      created_by
      customer_name
      last_update_by
      last_update_ip_address
      last_update_time
      location_id
      location_name
      model
      settings
      settings_update_by
      settings_update_ip_address
      settings_update_time
    }
    nextToken
  }
}
`;
export const queryNodesByNodeAutomationSetIdIndex = `query QueryNodesByNodeAutomationSetIdIndex(
  $after: String
  $automation_set_id: String!
  $first: Int
) {
  queryNodesByNodeAutomationSetIdIndex(
    after: $after
    automation_set_id: $automation_set_id
    first: $first
  ) {
    items {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    nextToken
  }
}
`;
export const queryNodesByNodeLocationIdIndex = `query QueryNodesByNodeLocationIdIndex(
  $after: String
  $first: Int
  $location_id: String!
) {
  queryNodesByNodeLocationIdIndex(
    after: $after
    first: $first
    location_id: $location_id
  ) {
    items {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    nextToken
  }
}
`;
export const queryNodesByNodeRoomIdIndex = `query QueryNodesByNodeRoomIdIndex(
  $after: String
  $first: Int
  $room_id: String!
) {
  queryNodesByNodeRoomIdIndex(after: $after, first: $first, room_id: $room_id) {
    items {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    nextToken
  }
}
`;
export const queryRoomsByRoomIdIndex = `query QueryRoomsByRoomIdIndex($after: String, $first: Int, $room_id: String!) {
  queryRoomsByRoomIdIndex(after: $after, first: $first, room_id: $room_id) {
    items {
      create_time
      created_by
      last_update_time
      location_id
      nodes {
        automation_set_id
        create_time
        created_by
        description
        last_update_time
        last_updated_by
        location_id
        model
        name
        nodeData {
          data_status
          details
          node_id
          update_time
        }
        node_id
        room_id
        template_node_id
        type
      }
      room_id
      room_name
      template_id
    }
    nextToken
  }
}
`;
