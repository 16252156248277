// tslint:disable
// this is an auto generated file. This will be overwritten

export const createAudit = `mutation CreateAudit($input: CreateAuditInput!) {
  createAudit(input: $input) {
    action
    cognito_id
    id
    location_id
    record
    source_ip
    timestamp
  }
}
`;
export const createAuthRawGateways = `mutation CreateAuthRawGateways($input: UpdateRawGatewaysInput!) {
  createAuthRawGateways(input: $input) {
    certs_issued
    description
    device_uuid
    download_link
    error
    expire_time
    location_id
    one_time_password
    status
  }
}
`;
export const createAutomationSetStreams = `mutation CreateAutomationSetStreams($input: CreateAutomationSetStreamsInput!) {
  createAutomationSetStreams(input: $input) {
    automation_set_id
    data
    device_name
    device_type
    node_id
    stream_id
  }
}
`;
export const createAutomationSets = `mutation CreateAutomationSets($input: CreateAutomationSetsInput!) {
  createAutomationSets(input: $input) {
    aircon_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    automation_set_id
    automation_set_name
    automation_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    create_time
    created_by
    last_update_ip_adress
    last_update_time
    last_updated_by
    primary_node
    room_id
    settings
    template_autoset_id
  }
}
`;
export const createDeviceStreamStatus = `mutation CreateDeviceStreamStatus($input: CreateDeviceStreamStatusInput!) {
  createDeviceStreamStatus(input: $input) {
    data
    stream_id
    timestamp
  }
}
`;
export const createEnergyStreamStatus = `mutation CreateEnergyStreamStatus($input: CreateEnergyStreamStatusInput!) {
  createEnergyStreamStatus(input: $input) {
    data
    stream_id
    timestamp
  }
}
`;
export const createFirmware = `mutation CreateFirmware($input: CreateFirmwareInput!) {
  createFirmware(input: $input) {
    create_time
    firmware_id
    name
    s3_link
    type
    uploaded_by
    version
  }
}
`;
export const createFirmwareUpdates = `mutation CreateFirmwareUpdates($input: CreateFirmwareUpdatesInput!) {
  createFirmwareUpdates(input: $input) {
    create_time
    firmware_id
    location_id
    scheduled_time
    status
    type
    update_id
  }
}
`;
export const createGateways = `mutation CreateGateways($input: CreateGatewaysInput!) {
  createGateways(input: $input) {
    alerted
    app_id
    data
    gateway_id
    ip_address
    last_seen
    location_id
    location_name
  }
}
`;
export const createLocations = `mutation CreateLocations($input: CreateLocationsInput!) {
  createLocations(input: $input) {
    app_id
    create_time
    created_by
    customer_name
    last_update_by
    last_update_ip_address
    last_update_time
    location_id
    location_name
    model
    settings
    settings_update_by
    settings_update_ip_address
    settings_update_time
  }
}
`;
export const createNodeData = `mutation CreateNodeData($input: CreateNodeDataInput!) {
  createNodeData(input: $input) {
    data_status
    details
    node_id
    update_time
  }
}
`;
export const createNodeMetaData = `mutation CreateNodeMetaData($input: CreateNodeMetaDataInput!) {
  createNodeMetaData(input: $input) {
    meta_data
    node_id
    timestamp
  }
}
`;
export const createNodes = `mutation CreateNodes($input: CreateNodesInput!) {
  createNodes(input: $input) {
    automation_set_id
    create_time
    created_by
    description
    last_update_time
    last_updated_by
    location_id
    model
    name
    nodeData {
      data_status
      details
      node_id
      update_time
    }
    node_id
    room_id
    template_node_id
    type
  }
}
`;
export const createRawGateways = `mutation CreateRawGateways($input: CreateRawGatewaysInput!) {
  createRawGateways(input: $input) {
    certs_issued
    description
    device_uuid
    download_link
    error
    expire_time
    location_id
    one_time_password
    status
  }
}
`;
export const createRooms = `mutation CreateRooms($input: CreateRoomsInput!) {
  createRooms(input: $input) {
    create_time
    created_by
    last_update_time
    location_id
    nodes {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    room_id
    room_name
    template_id
  }
}
`;
export const createShadowStatus = `mutation CreateShadowStatus($input: CreateShadowStatusInput!) {
  createShadowStatus(input: $input) {
    data
    node_id
    timestamp
  }
}
`;
export const createUsers = `mutation CreateUsers($input: CreateUsersInput!) {
  createUsers(input: $input) {
    client_group
    cognito_id
    create_time
    created_by
    customer_name
    email
    last_update_time
    last_updated_by
    locations
    roles
  }
}
`;
export const deleteAudit = `mutation DeleteAudit($input: DeleteAuditInput!) {
  deleteAudit(input: $input) {
    action
    cognito_id
    id
    location_id
    record
    source_ip
    timestamp
  }
}
`;
export const deleteAutomationSetStreams = `mutation DeleteAutomationSetStreams($input: DeleteAutomationSetStreamsInput!) {
  deleteAutomationSetStreams(input: $input) {
    automation_set_id
    data
    device_name
    device_type
    node_id
    stream_id
  }
}
`;
export const deleteAutomationSets = `mutation DeleteAutomationSets($input: DeleteAutomationSetsInput!) {
  deleteAutomationSets(input: $input) {
    aircon_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    automation_set_id
    automation_set_name
    automation_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    create_time
    created_by
    last_update_ip_adress
    last_update_time
    last_updated_by
    primary_node
    room_id
    settings
    template_autoset_id
  }
}
`;
export const deleteDeviceStreamStatus = `mutation DeleteDeviceStreamStatus($input: DeleteDeviceStreamStatusInput!) {
  deleteDeviceStreamStatus(input: $input) {
    data
    stream_id
    timestamp
  }
}
`;
export const deleteEnergyStreamStatus = `mutation DeleteEnergyStreamStatus($input: DeleteEnergyStreamStatusInput!) {
  deleteEnergyStreamStatus(input: $input) {
    data
    stream_id
    timestamp
  }
}
`;
export const deleteFirmware = `mutation DeleteFirmware($input: DeleteFirmwareInput!) {
  deleteFirmware(input: $input) {
    create_time
    firmware_id
    name
    s3_link
    type
    uploaded_by
    version
  }
}
`;
export const deleteFirmwareUpdates = `mutation DeleteFirmwareUpdates($input: DeleteFirmwareUpdatesInput!) {
  deleteFirmwareUpdates(input: $input) {
    create_time
    firmware_id
    location_id
    scheduled_time
    status
    type
    update_id
  }
}
`;
export const deleteGateways = `mutation DeleteGateways($input: DeleteGatewaysInput!) {
  deleteGateways(input: $input) {
    alerted
    app_id
    data
    gateway_id
    ip_address
    last_seen
    location_id
    location_name
  }
}
`;
export const deleteLocations = `mutation DeleteLocations($input: DeleteLocationsInput!) {
  deleteLocations(input: $input) {
    app_id
    create_time
    created_by
    customer_name
    last_update_by
    last_update_ip_address
    last_update_time
    location_id
    location_name
    model
    settings
    settings_update_by
    settings_update_ip_address
    settings_update_time
  }
}
`;
export const deleteNodeData = `mutation DeleteNodeData($input: DeleteNodeDataInput!) {
  deleteNodeData(input: $input) {
    data_status
    details
    node_id
    update_time
  }
}
`;
export const deleteNodeMetaData = `mutation DeleteNodeMetaData($input: DeleteNodeMetaDataInput!) {
  deleteNodeMetaData(input: $input) {
    meta_data
    node_id
    timestamp
  }
}
`;
export const deleteNodes = `mutation DeleteNodes($input: DeleteNodesInput!) {
  deleteNodes(input: $input) {
    automation_set_id
    create_time
    created_by
    description
    last_update_time
    last_updated_by
    location_id
    model
    name
    nodeData {
      data_status
      details
      node_id
      update_time
    }
    node_id
    room_id
    template_node_id
    type
  }
}
`;
export const deleteRawGateways = `mutation DeleteRawGateways($input: DeleteRawGatewaysInput!) {
  deleteRawGateways(input: $input) {
    certs_issued
    description
    device_uuid
    download_link
    error
    expire_time
    location_id
    one_time_password
    status
  }
}
`;
export const deleteRooms = `mutation DeleteRooms($input: DeleteRoomsInput!) {
  deleteRooms(input: $input) {
    create_time
    created_by
    last_update_time
    location_id
    nodes {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    room_id
    room_name
    template_id
  }
}
`;
export const deleteShadowStatus = `mutation DeleteShadowStatus($input: DeleteShadowStatusInput!) {
  deleteShadowStatus(input: $input) {
    data
    node_id
    timestamp
  }
}
`;
export const deleteUsers = `mutation DeleteUsers($input: DeleteUsersInput!) {
  deleteUsers(input: $input) {
    client_group
    cognito_id
    create_time
    created_by
    customer_name
    email
    last_update_time
    last_updated_by
    locations
    roles
  }
}
`;
export const updateAudit = `mutation UpdateAudit($input: UpdateAuditInput!) {
  updateAudit(input: $input) {
    action
    cognito_id
    id
    location_id
    record
    source_ip
    timestamp
  }
}
`;
export const updateAutomationSetStreams = `mutation UpdateAutomationSetStreams($input: UpdateAutomationSetStreamsInput!) {
  updateAutomationSetStreams(input: $input) {
    automation_set_id
    data
    device_name
    device_type
    node_id
    stream_id
  }
}
`;
export const updateAutomationSets = `mutation UpdateAutomationSets($input: UpdateAutomationSetsInput!) {
  updateAutomationSets(input: $input) {
    aircon_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    automation_set_id
    automation_set_name
    automation_stream {
      automation_set_id
      data
      device_name
      device_type
      node_id
      stream_id
    }
    create_time
    created_by
    last_update_ip_adress
    last_update_time
    last_updated_by
    primary_node
    room_id
    settings
    template_autoset_id
  }
}
`;
export const updateDeviceStreamStatus = `mutation UpdateDeviceStreamStatus($input: UpdateDeviceStreamStatusInput!) {
  updateDeviceStreamStatus(input: $input) {
    data
    stream_id
    timestamp
  }
}
`;
export const updateEnergyStreamStatus = `mutation UpdateEnergyStreamStatus($input: UpdateEnergyStreamStatusInput!) {
  updateEnergyStreamStatus(input: $input) {
    data
    stream_id
    timestamp
  }
}
`;
export const updateFirmware = `mutation UpdateFirmware($input: UpdateFirmwareInput!) {
  updateFirmware(input: $input) {
    create_time
    firmware_id
    name
    s3_link
    type
    uploaded_by
    version
  }
}
`;
export const updateFirmwareUpdates = `mutation UpdateFirmwareUpdates($input: UpdateFirmwareUpdatesInput!) {
  updateFirmwareUpdates(input: $input) {
    create_time
    firmware_id
    location_id
    scheduled_time
    status
    type
    update_id
  }
}
`;
export const updateGateways = `mutation UpdateGateways($input: UpdateGatewaysInput!) {
  updateGateways(input: $input) {
    alerted
    app_id
    data
    gateway_id
    ip_address
    last_seen
    location_id
    location_name
    deleted
  }
}
`;
export const updateLocations = `mutation UpdateLocations($input: UpdateLocationsInput!) {
  updateLocations(input: $input) {
    app_id
    create_time
    created_by
    customer_name
    last_update_by
    last_update_ip_address
    last_update_time
    location_id
    location_name
    model
    settings
    settings_update_by
    settings_update_ip_address
    settings_update_time
  }
}
`;
export const updateNodeData = `mutation UpdateNodeData($input: UpdateNodeDataInput!) {
  updateNodeData(input: $input) {
    data_status
    details
    node_id
    update_time
  }
}
`;
export const updateNodeMetaData = `mutation UpdateNodeMetaData($input: UpdateNodeMetaDataInput!) {
  updateNodeMetaData(input: $input) {
    meta_data
    node_id
    timestamp
  }
}
`;
export const updateNodes = `mutation UpdateNodes($input: UpdateNodesInput!) {
  updateNodes(input: $input) {
    automation_set_id
    create_time
    created_by
    description
    last_update_time
    last_updated_by
    location_id
    model
    name
    nodeData {
      data_status
      details
      node_id
      update_time
    }
    node_id
    room_id
    template_node_id
    type
  }
}
`;
export const updateRawGateways = `mutation UpdateRawGateways($input: UpdateRawGatewaysInput!) {
  updateRawGateways(input: $input) {
    certs_issued
    description
    device_uuid
    download_link
    error
    expire_time
    location_id
    one_time_password
    status
  }
}
`;
export const updateRooms = `mutation UpdateRooms($input: UpdateRoomsInput!) {
  updateRooms(input: $input) {
    create_time
    created_by
    last_update_time
    location_id
    nodes {
      automation_set_id
      create_time
      created_by
      description
      last_update_time
      last_updated_by
      location_id
      model
      name
      nodeData {
        data_status
        details
        node_id
        update_time
      }
      node_id
      room_id
      template_node_id
      type
    }
    room_id
    room_name
    template_id
  }
}
`;
export const updateShadowStatus = `mutation UpdateShadowStatus($input: UpdateShadowStatusInput!) {
  updateShadowStatus(input: $input) {
    data
    node_id
    timestamp
  }
}
`;
export const updateUsers = `mutation UpdateUsers($input: UpdateUsersInput!) {
  updateUsers(input: $input) {
    client_group
    cognito_id
    create_time
    created_by
    customer_name
    email
    last_update_time
    last_updated_by
    locations
    roles
  }
}
`;
