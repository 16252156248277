import { Component } from 'react';
import { UserContext } from '../Context/UserContext';
import { permissions } from './permissions';

class RouteAuthorizer extends Component<{}> {
	render() {
		let allRoutes: any = this.props.children;
		if (!Array.isArray(allRoutes)) {
			allRoutes = [allRoutes];
		}
		const userRoles: string[] = this.context.cognitoGroups;
		const rolePermissions: string[] = [].concat(
			...userRoles.map(role => {
				return permissions[role];
			})
		);
		let authorizedRoutes: string[] = [];
		const componentsBeingAuthorized = this.props.children;
		//identify the property that should be authorized against, since this RouteAuthorizer can be used for Navlinks and Routes
		const property = this.identifyComponent(componentsBeingAuthorized);

		if (rolePermissions.includes('*')) {
			authorizedRoutes = allRoutes;
		} else {
			console.log('allroute', allRoutes);
			authorizedRoutes = allRoutes.filter(route => {
				return rolePermissions.includes(route.props[property]);
			});
		}
		return authorizedRoutes;
	}

	//check if the component being authorized is a Route or NavLink
	private identifyComponent(componentBeingAuthorized) {
		//Obtain component type from props.children. Assume that children being authorized are of the same type, i.e. NavItems OR Route
		if (!Array.isArray(componentBeingAuthorized)) {
			//handle cases where there is only 1 child
			componentBeingAuthorized = this.props.children!['type']['name'];
		} else {
			componentBeingAuthorized = this.props.children![0]['type']['name'];
		}

		let property;
		switch (componentBeingAuthorized) {
			case 'Route':
				property = 'path';
				break;
			case 'NavLink':
				property = 'to';
				break;
		}
		return property;
	}
}
export default RouteAuthorizer;
RouteAuthorizer.contextType = UserContext;
