import React, { Component } from 'react';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { Row, Col, Badge, Toast, ToastBody, ToastHeader } from 'reactstrap';

import LoadingIndicator from '../../../common/LoadingIndicator/LoadingIndicator.component';
import ErrorIndicator from '../../../common/ErrorIndicator/ErrorIndicator.component';
import Utils from '../../../../utils';

import ListViewWrapper from '../../../common/ListViewWrapper/ListViewWrapper.component';
import CustomTd from '../../../common/ListViewWrapper/CustomTd/CustomTd.component';
import { SCOPE_DESCRIPTIONS, STATUS_TO_BADGE_MAPPING } from '../../../../enums/otaConstants';

import FirmwareupdateListSidebar from './FirmwareUpdateListSidebar.component';

import styles from './FirmwareUpdateList.module.css';
import { UserContext } from '../../../common/Context/UserContext';
import PermissionUtils from '../../../../utils/permission';

import {
	listFirmwareUpdatesExpanded,
	onCreateAndUpdate_FirmwareUpdatesV2,
} from './FirmwareUpdateGraphQL';

interface state {
	clicked: boolean;
	//controls visibility of message displaying outcome of form send
	failureVisible: boolean;
	successVisible: boolean;
	errorMsgs: string[];
	currentPage: number;
	keyToSortBy: string;
	// to track sort button being clicked and prevent sort from happening everytime rerender occurs
	sortClicked: boolean;
	selectedSchedule: {
		schedule_id?: string;
	} | null;
	isForceReload: boolean;
	showSuccessToast: boolean;
	successToastMessage: string;
}

interface tableItems {
	update_id: string;
	schedule_id: string;
	location_name: string;
	firmware_id: string;
	firmware_name: string;
	type: string;
	scheduled_time: number;
	create_time: number;
	status: string;
	version: string;
	scope?: string;
}

class FirmwareUpdateList extends Component<any, state> {
	state: state = {
		clicked: false,
		failureVisible: false,
		successVisible: false,
		errorMsgs: [],
		currentPage: 0,
		keyToSortBy: 'create_time',
		sortClicked: false,
		selectedSchedule: null,
		isForceReload: false,
		showSuccessToast: false,
		successToastMessage: '',
	};

	componentDidMount() {
		const { state: stateFromRouter } = this.props?.location;
		if (stateFromRouter) {
			const { showSuccessToast, successToastMessage } = stateFromRouter;
			this.setState({
				showSuccessToast,
				successToastMessage,
			});
		}
	}

	handlePageClick = event => {
		this.setState({ currentPage: event.selected, sortClicked: false });
		console.log('navigating to page: ', event);
	};

	handleItemClick = item => {
		// only update if first time or not currently selected
		if (
			!this.state.selectedSchedule ||
			item.schedule_id !== this.state.selectedSchedule.schedule_id
		) {
			this.setState({ selectedSchedule: item });
		}
	};

	refreshData = () => {
		// force the component to reload with buffer for visual effect
		const reloadIntervalBuffer = 1000;
		this.setState({ isForceReload: true });
		setTimeout(() => {
			this.setState({ isForceReload: false });
		}, reloadIntervalBuffer);
	};

	render() {
		const tableHeaders = [
			{ headerName: 'Location', key: 'location_name' },
			{ headerName: 'Firmware Type', key: 'type' },
			{ headerName: 'Scope', key: 'scope' },
			{ headerName: 'Firmware', key: 'firmware_version' },
			{ headerName: 'Status', key: 'status' },
			{ headerName: 'Scheduled Time', key: 'scheduled_time' },
		];

		const isAdminOrSupport = PermissionUtils.isAdminOrSupport(this.context.cognitoGroups);

		return (
			<>
				{this.state.isForceReload && <LoadingIndicator />}
				{!this.state.isForceReload && (
					<Connect
						query={graphqlOperation(listFirmwareUpdatesExpanded)}
						subscription={graphqlOperation(onCreateAndUpdate_FirmwareUpdatesV2)}
						onSubscriptionMsg={(prevlistFirmwareUpdatesExpandedData, subscriptionMessage) => {

							const { onCreateAndUpdate_FirmwareUpdatesV2: newItem } = subscriptionMessage;

							if(!newItem) {
								return prevlistFirmwareUpdatesExpandedData
							}

							// find the new item in the previous data
							const index = prevlistFirmwareUpdatesExpandedData.listFirmwareUpdates.items.findIndex(
								update => update.update_id === newItem.update_id
							);
							// if it is a new update, add it to the array
							if (index === -1) {
								prevlistFirmwareUpdatesExpandedData.listFirmwareUpdates.items = [
									...prevlistFirmwareUpdatesExpandedData.listFirmwareUpdates.items,
									newItem,
								];
							} else {
								prevlistFirmwareUpdatesExpandedData.listFirmwareUpdates.items[index].status =
									newItem.status;
							}
							return prevlistFirmwareUpdatesExpandedData;
						}}
					>
						{({ data: { listFirmwareUpdates, listLocations, listFirmwares }, loading, errors }) => {
							if (errors.length) {
								return <ErrorIndicator errorMsg={errors} />;
							}
							if (loading || !listFirmwareUpdates) {
								return <LoadingIndicator />;
							}

							const updates = listFirmwareUpdates.items;
							const firmwares = listFirmwares.items;
							const locations = listLocations.items;

							// sort by scheduled_time
							updates.sort((a, b) => b.scheduled_time - a.scheduled_time);

							// group updates, which have a single data item per scheduled gateway, into schedules, which is what the user expects to see, e,g. they scheduled an OTA for 5 gateways in a location
							const scheduleItems: any[] = [];
							updates.forEach(update => {
								// cater for otav1 updates, which won't have a schedule_id
								// we can remove this if we can be sure that all the rows in the DB are OTA v2
								if (!update.schedule_id) {
									const scheduleItem = {
										...update,
										schedule_id: update.update_id,
										scope: 'OTAV1',
										updates: [update],
									};
									scheduleItems.push(scheduleItem);
								} else {
									// check if array already contains a schedule item with the same schedule_id
									let scheduleItem = scheduleItems.find(
										item => item.schedule_id === update.schedule_id
									);

									// if don't have, we construct the object, spreading in the first update
									if (!scheduleItem) {
										scheduleItem = {
											...update,
											updates: [update],
										};
										// remove fields we don't want in schedule as it may be confusing
										delete scheduleItem.update_id;
										delete scheduleItem.gateway_id;
										delete scheduleItem.ota_result_log;
										scheduleItems.push(scheduleItem);
									} else {
										// if have we push in
										scheduleItem.updates.push(update);
									}

									// final step: update scheduled_time if the individual update has an earlier start time
									scheduleItem.scheduled_time = Math.min(
										scheduleItem.scheduled_time,
										update.scheduled_time
									);
								}
							});

							//processing data for the table, essentially looking up the location and firmware from the returned data
							const tableItems: tableItems[] = scheduleItems.map(schedule => {
								const location = locations.find(loc => loc.location_id === schedule.location_id);

								let locationName = 'Unknown location';

								if (location) {
									locationName = location.location_name
										? location.location_name
										: 'location has no name';
								}

								schedule.location_name = locationName;
								schedule.app_id = location?.app_id ?? '';

								const firmware = firmwares.find(f => f.firmware_id === schedule.firmware_id);
								if (!firmware || firmware.version == null) {
									console.warn(
										`Invalid firmware_id ${schedule.firmware_id}, firmware is ${JSON.stringify(
											firmware,
											null,
											2
										)}`
									);
								}
								schedule.firmware_version = firmware?.version ?? 'unknown or deleted firmware';
								schedule.firmware_name = firmware?.name ?? 'unknown or deleted firmware';
								schedule.scheduled_time = Utils.getLocaleString(schedule.scheduled_time);
								return schedule;
							});

							// sort by scheduled_time
							tableItems.sort((a, b) => b.scheduled_time - a.scheduled_time);

							return (
								<Row>
									<Col lg='9'>
										<ListViewWrapper
											addButton={true}
											addButtonLink={'/firmware-updates/schedule'}
											addButtonText='Schedule Firmware Update'
											error={false}
											listData={tableItems}
											loading={loading}
											pageName='Firmware Updates'
											refreshData={this.refreshData}
											tableHeader={tableHeaders}
											title={true}
											isAdminOrSupport={isAdminOrSupport}
											dataRow={(items, columnIdxs) =>
												items.map((item, i) => (
													<tr
														className={
															this.state.selectedSchedule &&
															item.schedule_id === this.state.selectedSchedule.schedule_id
																? styles.selected
																: ''
														}
														key={i}
														onClick={() => this.handleItemClick(item)}
													>
														{Object.entries(item).map(([k, v], idx) => {
															if (columnIdxs[idx] === 'scope') {
																return (
																	<CustomTd index={k} key={k}>
																		<span title={SCOPE_DESCRIPTIONS[item.scope]}>{item.scope}</span>
																	</CustomTd>
																);
															}

															if (columnIdxs[idx] === 'status') {
																return (
																	<CustomTd index={k} key={k}>
																		<Badge color={STATUS_TO_BADGE_MAPPING[item.status]}>
																			{item.status}
																		</Badge>
																	</CustomTd>
																);
															}

															if (columnIdxs[idx] === 'scheduled_time') {
																return (
																	<CustomTd index={k} key={k}>
																		{item.scheduled_time}
																	</CustomTd>
																);
															}

															if (columnIdxs[idx]) {
																return (
																	<CustomTd key={k} index={k}>
																		{item[columnIdxs[idx]]}
																	</CustomTd>
																);
															}
															return null;
														})}
													</tr>
												))
											}
										/>
									</Col>
									<Col lg='3' className={styles.itemInfoContainer}>
										<FirmwareupdateListSidebar schedule={this.state.selectedSchedule} />
									</Col>
									<Toast isOpen={this.state.showSuccessToast} className={styles.toast}>
										<ToastHeader icon='success'>Successfully Scheduled Firmware Update</ToastHeader>
										<ToastBody>
											{this.state.successToastMessage}
											<br />
											<br />
											<Link to='/firmware-updates/schedule'>Schedule another Firmware Update</Link>
										</ToastBody>
									</Toast>
								</Row>
							);
						}}
					</Connect>
				)}
			</>
		);
	}
}

export default FirmwareUpdateList;
FirmwareUpdateList.contextType = UserContext;
