import React from 'react';

interface Props {
	status: string;
}

class StatusIndicator extends React.Component<Props, {}> {
	render() {
		switch (this.props.status) {
			case 'online':
				return <span className='green-dot' />;
			case 'offline':
				return <span className='red-dot' />;
			default:
				return <span className='grey-dot' />;
		}
	}
}

export default StatusIndicator;
