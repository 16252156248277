import React from 'react';
import { Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';

interface Props {
	clicked: boolean;
	switchOffLoadIndicator: any;
	device_uuid: string;
	onStatusChange: any;
}

const onUpdateRawGateways = `subscription OnUpdateRawGateways(
	$device_uuid: String
  ) {
	onUpdateRawGateways(
	  device_uuid: $device_uuid
	) {
			device_uuid
			download_link
			location_id
			status
			description
			error
    }
  }
  `;

class CreateGatewayDisplay extends React.PureComponent<Props, any> {
	render() {
		return (
			<Connect
				key={this.props.device_uuid}
				subscription={graphqlOperation(onUpdateRawGateways, {
					device_uuid: this.props.device_uuid,
				})} //{ input: { device_uuid: "36622533618491647cc8e3718d1ae6b4727e36d15967510649a90e4fd26047" } }
				// onSubscriptionMsg is a middleware event hook that gives you you two params, previousData and newData, lets you manipulate Subscription data before return
				onSubscriptionMsg={(prevData, { onUpdateRawGateways: rawGatewayRecord }) => {
					if (rawGatewayRecord.status === 'COMPLETE') {
						this.props.switchOffLoadIndicator();
					}

					console.log(`prevData: ${JSON.stringify(prevData)}`);
					console.log(`rawGateway: ${JSON.stringify(rawGatewayRecord)}`);

					return rawGatewayRecord;
				}}
			>
				{({ data, loading, errors }) => {
					if (errors.length) {
						this.props.onStatusChange(data);
					}
					if (data.status) {
						console.log({ data });
						this.props.onStatusChange(data);
					}

					// else if (this.props.clicked) {
					// 	return <LoadingIndicator />;
					// }
					else {
						return <></>;
					}
				}}
			</Connect>
		);
	}
}

export default CreateGatewayDisplay;
