/*
Note that this query takes a 'dummy variable' as a trigger for forcing the <connect> component to refetch this query
upon rerendering of the parent component.

See our appsync document for more info:

AppSync Gotchas in:
https://sensorflow.atlassian.net/wiki/spaces/AR/pages/279969818/App+Sync+and+Amplify#AppSync-Gotchas:%C2%A0

*/

export const nodesPageQuery = `query nodesPageQuery($location_id: String!) {
    listRoomsByLocationId(location_id: $location_id) {
		room_id
		room_name
	}
    listNodesByLocation(location_id: $location_id) {
        nodes {
            automation_set_id
            create_time
            model
            name
            nodeData {
                details
                update_time
            }
            automation_set {
                automation_set_id
                automation_set_name
                primary_node
            }
            node_id
            room_id
            type
        }
        count
    }
    getLocations(location_id: $location_id) {
        app_id
        gateways {
            gateway_id
            data
            app_id
            last_seen
            deleted
        }
    }
    listRawGateways{
        items {
            gateway_id: device_uuid # alias device_uuid in RawGateways to gateway_id
            description
        }
    }
}
`;
