import React, { Component } from 'react';
import RoomStyles from './Room.module.css';
import { AutomationSet } from '../../../../interface/AutomationSet';
import RoomAutomationSet from './RoomAutomationSet/RoomAutomationSet.component';

interface Props {
	data: AutomationSet[];
	locationId: string;
}

/* Main Component */
class RoomDevice extends Component<Props, {}> {
	state = {
		getConnection: true,
	};

	componentDidMount() {
		// listen for offline events, used 45s timeout to show 'offline' because AppSync will disconnect around that time
		let timer;
		window.addEventListener('offline', e => {
			timer = window.setTimeout(() => {
				this.setState({ getConnection: false });
			}, 45 * 1000);
		});
		window.addEventListener('online', e => {
			window.clearTimeout(timer);
			this.setState({ getConnection: true });
		});
	}

	render() {
		return (
			<div className={RoomStyles.baseLayout}>
				{this.props.data.map(set => {
					return (
						<RoomAutomationSet
							locationId={this.props.locationId}
							autoset={set}
							key={set.automation_set_id}
							getConnection={this.state.getConnection}
						/>
					);
				})}
			</div>
		);
	}
}

interface StatusProps {
	getConnection: boolean;
}

export class ConnectionStatus extends React.Component<StatusProps, {}> {
	render() {
		return (
			<>
				{this.props.getConnection ? (
					<span style={{ backgroundColor: 'green' }}>Online</span>
				) : (
					<span style={{ backgroundColor: 'red' }}>Offline</span>
				)}
			</>
		);
	}
}

/* Generic Temperature Component */
export const Temp = ({ children }): JSX.Element => {
	return <span>{children}&deg;C</span>;
};

export default RoomDevice;
