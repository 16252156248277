import ReactAppEnvValues from './IReactAppEnv';

const glenn: ReactAppEnvValues = {
	aws_project_region: 'ap-southeast-1',
	aws_cognito_identity_pool_id: 'ap-southeast-1:80b63c4e-d505-47cd-a3ea-118f78369bef',
	aws_cognito_region: 'ap-southeast-1',
	aws_user_pools_id: 'ap-southeast-1_nyxQ2Cqbr',
	aws_user_pools_web_client_id: '5mr0lisa8rv4nsa07mmpvnb5vi',
	aws_appsync_graphqlEndpoint:
		'https://yjifn7mhdve4fptjyuwavdmfq4.appsync-api.ap-southeast-1.amazonaws.com/graphql',
	aws_appsync_region: 'ap-southeast-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	APIaddress: 'https://glenn-api.sensorflow.org',
	APIstage: 'dev2',
};

export default glenn;
