import React from 'react';
import { graphqlOperation, API } from 'aws-amplify';
import { UserContext } from '../../../common/Context/UserContext';
import ListViewWrapper from '../../../common/ListViewWrapper/ListViewWrapper.component';
import CustomTd from '../../../common/ListViewWrapper/CustomTd/CustomTd.component';
import {
	listLocationsQuery,
	listGatewaysByAppIdQuery,
	listAllLocationsQuery,
	listRoomsByLocationsQuery,
} from './ListLocationsGraphqlQuery';
import style from './ListLocations.module.css';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-pencil.svg';
import { Link } from 'react-router-dom';
import Utils from './../../../../utils/index';
import PermissionUtils from '../../../../utils/permission';

interface Props {
	history: any;
}

interface State {
	itemsPerPage: number;
	tableData: any[];
	filterTableDataToUserAssignedLocations: boolean;
	dropdownOpen: boolean;
	loading: boolean;
	error: boolean;
}

const LOCAL_STORAGE_KEY_FOR_FILTER_PREFERENCE =
	'sf-locationsList-filterTableDataToUserAssignedLocations';

function getPreferenceFromLocalStorage(LOCAL_STORAGE_KEY_FOR_FILTER_PREFERENCE): boolean {
	const localStorageItem = localStorage.getItem(LOCAL_STORAGE_KEY_FOR_FILTER_PREFERENCE);
	if (localStorageItem === 'false') {
		return false;
	}
	// if not exist or if is true, default to true
	return true;
}

class ListLocations extends React.Component<Props, State> {
	state = {
		itemsPerPage: 25,
		tableData: [],
		filterTableDataToUserAssignedLocations: getPreferenceFromLocalStorage(
			LOCAL_STORAGE_KEY_FOR_FILTER_PREFERENCE
		),
		dropdownOpen: false,
		loading: false,
		error: false,
	};

	fetchData = async () => {
		try {
			const roles = this.context.cognitoGroups;
			let getTableData;
			if (PermissionUtils.isAdminOrSupport(roles)) {
				const result = await API.graphql(graphqlOperation(listAllLocationsQuery));
				// @ts-ignore
				getTableData = await this.getRoomsByLocation(result.data.listLocations.items);
				// @ts-ignore
			} else {
				const locations = this.context.getLocation;
				const tableData = await this.getTableData(JSON.parse(locations));
				getTableData = tableData.filter(data => data);
			}
			const transformedData = await this.transformData(getTableData);
			this.setState({ tableData: transformedData, loading: false, error: false });
		} catch (e) {
			console.error(e);
			this.setState({ loading: false, error: true });
		}
	};

	async componentDidMount() {
		this.setState({ loading: true });
		return this.fetchData();
	}

	toggleRefreshData = async () => {
		this.setState({ loading: true });
		return this.fetchData();
	};

	getRoomsByLocation = async locations => {
		return Promise.all(
			locations.map(async location => {
				const input = {
					location_id: location.location_id,
				};
				const result = await API.graphql(graphqlOperation(listRoomsByLocationsQuery, input));
				// @ts-ignore
				result.data.getLocations = location;
				return result;
			})
		);
	};

	private async getTableData(locations: any[]) {
		return Promise.all(
			locations.map(async location => {
				const input = {
					location_id: location.location_id,
				};
				const result = await API.graphql(graphqlOperation(listLocationsQuery, input));
				// @ts-ignore
				if (!result.data.getLocations || !result.data.listRoomsByLocationId.length) {
					return null;
				}
				return result;
			})
		);
	}



	//TODO: check that nodes are mapped 1 to 1

	//return fraction of gateways online
	private getGatewayStatus(gatewayList: any) {
		const totalGateways = gatewayList.length;
		let online = 0;
		gatewayList.forEach(gw => {
			if (Utils.getStatus(gw.last_seen) === 'online') {
				online++;
			}
		});
		return `${online}/${totalGateways}`;
	}

	private getTotalRooms(roomsList: any[]) {
		return roomsList.length;
	}

	private async transformData(data) {
		const items = data.map(async item => {
			item.location_id = Utils.getSafe(() => item.data.getLocations.location_id);
			item.location_name = Utils.getSafe(() => item.data.getLocations.location_name);
			item.organisation = Utils.getSafe(() => item.data.getLocations.customer_name); //TODO: confirm?
			item.model = Utils.getSafe(() => item.data.getLocations.model);
			item.description = Utils.getSafe(() => item.data.getLocations.description);
			item.room_count = Utils.getSafe(() => this.getTotalRooms(item.data.listRoomsByLocationId));
			item.app_id = Utils.getSafe(() => item.data.getLocations.app_id);
			item.default_mode = Utils.getSafe(
				() => JSON.parse(item.data.getLocations.settings).automation_mode
			);
			item.time_zone =
				Utils.getSafe(() => item.data.getLocations.time_zone) || 'Default (Asia/Singapore)';

			const listGatewaysByAppIdInput = {
				filter: { app_id: { eq: item.app_id } },
			};

			//gateways for this location
			const gatewaysList: any = await API.graphql(
				graphqlOperation(listGatewaysByAppIdQuery, listGatewaysByAppIdInput)
			);
			const gateways = gatewaysList.data.listGateways.items;
			// TODO: Handle this on the backend
			const filterDeletedGateways = gateways.filter(gateway => !gateway.deleted);
			item.gateway_status = this.getGatewayStatus(filterDeletedGateways);
			return item;
		});

		const result = await Promise.all(items);
		const resultSortedAlphabetically = result.sort((a: any, b: any) =>
			a.location_name.localeCompare(b.location_name)
		);
		return resultSortedAlphabetically;
	}

	toggleItemsDisplayedPerPage = value => {
		this.setState({ itemsPerPage: value });
	};

	isHealthyPercentage(percentage) {
		switch (percentage) {
			case 100:
				return style['healthy'];

			case percentage > 95:
				return style['warning'];

			default:
				return style['critical'];
		}
	}

	isHealthyFraction(string: string) {
		const numeratorAndDenominator = string.split('/');
		const percentage =
			(parseInt(numeratorAndDenominator[0]) / parseInt(numeratorAndDenominator[1])) * 100;
		return this.isHealthyPercentage(percentage);
	}

	toggle = () => {
		this.setState({
			dropdownOpen: !this.state.dropdownOpen,
		});
	};

	filterToUserAssignedLocations = () => {
		if (!this.state.filterTableDataToUserAssignedLocations) {
			return this.state.tableData;
		}
		const locationsData = this.state.tableData;
		const userAssignedLocations = this.context.getLocation;
		const filteredLocationsData = locationsData.filter((location: any) => {
			return userAssignedLocations.includes(location.location_id);
		});
		return filteredLocationsData;
	};

	togglefilterTableDataToUserAssignedLocations = () => {
		localStorage.setItem(
			LOCAL_STORAGE_KEY_FOR_FILTER_PREFERENCE,
			(!this.state.filterTableDataToUserAssignedLocations).toString()
		);
		this.setState({
			filterTableDataToUserAssignedLocations: !this.state.filterTableDataToUserAssignedLocations,
		});
	};

	render() {
		const tableHeader = [
			{ headerName: 'NAME', key: 'location_name' },
			{ headerName: 'ORGANISATION', key: 'organisation' },
			{ headerName: 'ROOMS', key: 'room_count' },
			{ headerName: 'GATEWAY STATUS', key: 'gateway_status' },
			{ headerName: 'BASE AC MODEL', key: 'model' },
			{ headerName: 'TIME ZONE', key: 'time_zone' },
			{ headerName: 'APP ID', key: 'app_id' },
			{ headerName: 'DEFAULT MODE', key: 'default_mode' },
		];
		const isAdminOrSupport = PermissionUtils.isAdminOrSupport(this.context.cognitoGroups);
		return (
			<>
				<ListViewWrapper
					pageName={'Locations'}
					booleanFilterLabel='Show only locations assigned to me'
					booleanFilterToggle={this.togglefilterTableDataToUserAssignedLocations}
					booleanFilterValue={this.state.filterTableDataToUserAssignedLocations}
					title={true}
					tableHeader={tableHeader}
					listData={this.filterToUserAssignedLocations()}
					loading={this.state.loading}
					addButton={true}
					addButtonLink={'/manage-location/new'}
					addButtonText='Add Location'
					refreshData={this.toggleRefreshData}
					error={this.state.error}
					isAdminOrSupport={isAdminOrSupport}
					dataRow={(locations, columnIdxs) => {
						return locations.map(loc => (
							<tr key={loc.location_id}>
								{Object.entries(loc).map(([k, v], idx) => {
									if (columnIdxs[idx]) {
										if (columnIdxs[idx] === 'location_name') {
											return (
												<CustomTd cypress={loc.location_id} key={k} index={k}>
													<Link className={'custom-link'} to={`/locations/${loc.location_id}`}>
														{loc.location_name}
													</Link>
													<EditIcon
														data-cy='edit-location'
														className={`svg-spacing`}
														style={{ cursor: 'pointer' }}
														onClick={() =>
															this.props.history.push(`/manage-location/${loc.location_id}`)
														}
													/>
												</CustomTd>
											);
										}

										if (columnIdxs[idx] === 'gateway_status') {
											return (
												<CustomTd key={k} index={k}>
													<span
														className={`${this.isHealthyFraction(loc.gateway_status)} ${
															style['gateway-cell']
														}`}
														onClick={() =>
															this.props.history.push(`/locations/${loc.location_id}`, {
																activeTab: '3',
																appId: loc.app_id,
															})
														}
													>
														{loc.gateway_status}
													</span>
												</CustomTd>
											);
										}

										return (
											<CustomTd key={k} index={k}>
												{loc[columnIdxs[idx]]}
											</CustomTd>
										);
									}
									return null;
								})}
							</tr>
						));
					}}
				/>
			</>
		);
	}
}

export default ListLocations;
ListLocations.contextType = UserContext;
