export const listLocationsQuery = `query listLocationsQuery($location_id: String!){
	listRoomsByLocationId(location_id: $location_id) {
		location_id
		room_id
		room_name
	}
	getLocations(location_id: $location_id) {
		customer_name
		location_id
		location_name
        model
        app_id
        settings
	}
}`;

export const listAllLocationsQuery = `query listAllLocationsQuery {
    listLocations {
        items {
            customer_name
            location_id
            location_name
            model
            app_id
            ac_model_id
						settings
						time_zone
        }
	}
}`;

export const listRoomsByLocationsQuery = `query listRoomsByLocationsQuery($location_id: String!){
	listRoomsByLocationId(location_id: $location_id) {
		location_id
		room_id
		room_name
	}
}`;

export const listNodesQuery = `query listNodesQuery($filter: TableNodesFilterInput){
	listNodes(filter: $filter){
		items {
            location_id
            node_id
						last_update_time
						nodeData{
							update_time
						}
		  }
	}
}
`;

export const listGatewaysByAppIdQuery = `query ListGateways(
	$filter: TableGatewaysFilterInput
  ) {
	listGateways(filter: $filter) {
	  items {
        gateway_id
		app_id
		last_seen
		deleted
	  }
	}
  }`;

export const listNodesByLocation = `query listNodesByLocation($location_id: String!){
    listNodesByLocation(location_id: $location_id) {
        nodes {
           last_update_time
           nodeData {
             update_time
           }
        }
        count
    }
}`;
