import ReactAppEnvValues from './IReactAppEnv';

const test1: ReactAppEnvValues = {
	aws_project_region: 'ap-southeast-1',
	aws_cognito_identity_pool_id: 'ap-southeast-1:79fdff6a-4521-4c7f-9f24-c40b19650f8d',
	aws_cognito_region: 'ap-southeast-1',
	aws_user_pools_id: 'ap-southeast-1_eFyG6Rq2p',
	aws_user_pools_web_client_id: '45r1buc273700ajv89q437f3eb',
	aws_appsync_graphqlEndpoint:
		'https://kc6fgwewmzg3vj2huaekwnhhgm.appsync-api.ap-southeast-1.amazonaws.com/graphql',
	aws_appsync_region: 'ap-southeast-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	APIaddress: 'https://test-api.sensorflow.org',
	APIstage: 'test',
};

export default test1;
