import React from 'react';
import { graphqlOperation, API } from 'aws-amplify';
import ListViewWrapper from '../../../common/ListViewWrapper/ListViewWrapper.component';
import Utils from '../../../../utils';
import CustomTd from '../../../common/ListViewWrapper/CustomTd/CustomTd.component';
import styles from './User.module.css';
import { Link } from 'react-router-dom';
import { ReactComponent as SettingSymbol } from '../../../../assets/icons/dots-options-button.svg';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
	UncontrolledTooltip,
} from 'reactstrap';
import { deleteUsers } from '../../../../graphql/mutations';
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal.component';
import { WithTranslation, withTranslation } from 'react-i18next';

interface RouteComponentProps {
	// TODO: check none?
}

interface State {
	clientGroup: string;
	userList: any;
	loading: boolean;
	error: boolean;
	modalStatus: boolean;
	modal: boolean;
	cognitoId: string;
}

interface Props extends WithTranslation {}

const listUsersQuery = `query listUser {
	listUsers{
		items{
			cognito_id
		  	customer_name
		  	email
		  	client_group
		  	locations
		  	status
		}
	}
 }`;

class UserIndex extends React.Component<Props, State> {
	state: State = {
		clientGroup: '',
		userList: [],
		loading: false,
		error: false,
		modalStatus: false,
		modal: false,
		cognitoId: '',
	};

	fetchData = async () => {
		try {
			const getData = await API.graphql(graphqlOperation(listUsersQuery));

			// @ts-ignore
			let users = getData.data.listUsers.items;
			users = users.filter(user => user.status !== 'disable');
			this.transformData(users);
			this.setState({ userList: users, loading: false, error: false });
		} catch (e) {
			this.setState({ loading: false, error: true });
			console.error(e);
		}
	};

	async componentDidMount() {
		this.setState({ loading: true });
		return this.fetchData();
	}

	toggleRefreshData = () => {
		this.setState({ loading: true });
		return this.fetchData();
	};

	transformData = users => {
		users.forEach(user => {
			user.locations = Utils.getSafe(() => user.locations)
				? JSON.parse(user.locations)
				: 'not found';
			user.roles = Utils.getSafe(() => user.roles) ? JSON.parse(user.roles) : 'not found';
		});
	};

	onDeleteUser = async cognitoId => {
		this.toggle(cognitoId);
	};

	openModal = () => {
		this.setState(prevState => ({ modalStatus: !prevState.modalStatus }));
	};

	confirmDeleteUser = async e => {
		try {
			await API.graphql(
				graphqlOperation(deleteUsers, {
					input: {
						cognito_id: this.state.cognitoId,
					},
				})
			);

			const users = this.state.userList.filter(user => {
				return user.cognito_id !== this.state.cognitoId;
			});

			this.setState({ userList: users, loading: false, error: false });
			this.toggle();
		} catch (e) {
			this.setState({ loading: false, error: true });
			this.toggle();
			console.error(e);
		}
	};

	cancelDeleteUser = () => {
		this.toggle();
	};

	toggle = (cognitoId: string = '') => {
		this.setState(prevState => ({
			modal: !prevState.modal,
			cognitoId: cognitoId,
		}));
	};

	render() {
		const tableHeader = [
			{ headerName: 'NAME', key: 'customer_name' },
			// { headerName: 'LAST NAME', key: '' },
			{ headerName: 'EMAIL', key: 'email' },
			{ headerName: 'USER ROLE', key: 'client_group' },
			{ headerName: 'LOCATION ACCESS', key: '' },
		];

		return (
			<>
				<ListViewWrapper
					pageName={'Users'}
					title={true}
					tableHeader={tableHeader}
					listData={this.state.userList}
					error={this.state.error}
					loading={this.state.loading}
					refreshData={this.toggleRefreshData}
					addButton={true}
					addButtonLink={'/users/new'}
					addButtonText='Add User'
					manageColumns={true}
					dataRow={(filteredData, columnIdxs) =>
						filteredData.map(user => {
							let locationNamesTooltipMessage = '';
							user.locations.forEach((location, index, locations) => {
								const name = location.location_name ? location.location_name : 'unknown location';
								switch (true) {
									case index === 0:
										locationNamesTooltipMessage += name;
										break;
									case index < 5:
										locationNamesTooltipMessage += `, ${name}`;
										break;
									case index === 5:
										locationNamesTooltipMessage += `, and ${locations.length - 5} more...`;
										break;
									case index > 5:
										// do nothing
										break;
									default:
								}
							});
							return (
								<tr key={user.cognito_id}>
									{Object.entries(user).map(([k, v], idx) => {
										if (columnIdxs[idx]) {
											return (
												<CustomTd cypress={columnIdxs[idx]} key={k} index={k}>
													{user[columnIdxs[idx]]}
												</CustomTd>
											);
										}
										return null;
									})}
									<CustomTd index={3} cypress={'customer-locations'}>
										<span id={`tooltip-target-${user.cognito_id}`} style={{ cursor: 'pointer' }}>
											{user.locations.length} {user.locations.length > 1 ? 'locations' : 'location'}{' '}
										</span>
										<UncontrolledTooltip
											placement='top'
											target={`tooltip-target-${user.cognito_id}`}
										>
											{locationNamesTooltipMessage}
										</UncontrolledTooltip>
									</CustomTd>
									<CustomTd index={8}>
										<UncontrolledDropdown>
											<DropdownToggle color=''>
												<SettingSymbol />
											</DropdownToggle>
											<DropdownMenu className={styles['dropdownmenu']}>
												<Link
													className={styles['dropdown-link']}
													to={{ pathname: `/users/${user.cognito_id}` }}
												>
													<DropdownItem className={styles['dropdownmenu-item']}>
														{this.props.t('EDIT')}
													</DropdownItem>
												</Link>
												<DropdownItem
													className={styles['dropdownmenu-item']}
													onClick={() => this.onDeleteUser(user.cognito_id)}
												>
													{this.props.t('DELETE')}
												</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>
									</CustomTd>
								</tr>
							);
						})
					}
				/>
				<ConfirmationModal
					modal={this.state.modal}
					openModal={this.toggle}
					onConfirm={this.confirmDeleteUser}
					onCancel={this.cancelDeleteUser}
					title={'DELETE USER'}
					message={'This action cannot be undone. Are you sure you want to delete this user?'}
				/>
			</>
		);
	}
}

export default withTranslation()(UserIndex);
