import React from 'react';
import { AutomationSet } from '../../../../../interface/AutomationSet';
import RoomStyles from '../Room.module.css';
import AutmationSetDevice from './AutomationSetDevice/AutomationSetDevice.component';

export interface AirconDataFromAutomationSet {
	temperature: number;
	mode: number;
	speed: number;
	power: boolean;
	timestamp: number;
}

const RoomAutomationSet = ({
	locationId,
	autoset,
	getConnection,
}: {
	locationId: string;
	autoset: AutomationSet;
	getConnection: boolean;
}): JSX.Element => {
	return (
		<div className={RoomStyles.boxLayout}>
			<p>Autoset name: {autoset.automation_set_name}</p>
			{autoset.aircon_stream.map(data => {
				const airconStreamData: AirconDataFromAutomationSet = JSON.parse(data.data);
				return (
					<AutmationSetDevice
						key={data.stream_id}
						locationId={locationId}
						autoset={autoset}
						automationStream={data}
						airconStreamData={airconStreamData}
						getConnection={getConnection}
					/>
				);
			})}
		</div>
	);
};

export default RoomAutomationSet;
