import ReactAppEnvValues from './IReactAppEnv';

const dev3: ReactAppEnvValues = {
	aws_project_region: 'ap-southeast-1',
	aws_cognito_identity_pool_id: 'ap-southeast-1:4ac104de-420c-463a-92d4-f3287467b8fb',
	aws_cognito_region: 'ap-southeast-1',
	aws_user_pools_id: 'ap-southeast-1_mEdFdc5ak',
	aws_user_pools_web_client_id: '6rm809iss9v4a5j0gv81qgj2k3',
	aws_appsync_graphqlEndpoint:
		'https://rniyuvlr2rhg3c6locorlun6py.appsync-api.ap-southeast-1.amazonaws.com/graphql',
	aws_appsync_region: 'ap-southeast-1',
	aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
	APIaddress: 'https://dev3-api.sensorflow.org',
	APIstage: 'dev3',
};

export default dev3;
