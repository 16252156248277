import React, { useContext } from 'react';
import { ColumnContext } from '../ListViewWrapper.component';

const CustomTd = ({ index, cypress = '', children, style = {} }) => {
	const column: any = useContext(ColumnContext);

	return (
		<>
			{column.includes(index) ? null : (
				<td data-cy={cypress} style={style}>
					{children}
				</td>
			)}
		</>
	);
};

export default CustomTd;
